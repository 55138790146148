import { ActionTypes } from "../constants/action-types";

const initialState = {
  certificates: [],
  rewards: [],
  loading: false,
  error: null,
};

// console.log('in main red', initialState.certificatesList)
export const rewardListReducer = (
  state = initialState,
  { type, payload, error }
) => {
  switch (type) {
    case ActionTypes.REWARD_DATA_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ActionTypes.REWARD_DATA_CERTIFICATE:
      return {
        ...state,
        loading: false,
        certificates: payload,
      };

    case ActionTypes.REWARD_DATA_REWARD:
      return {
        ...state,
        loading: false,
        rewards: payload,
      };

    case ActionTypes.REWARD_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: error,
        certificates: [],
        rewards: [],
      };

    default:
      return state;
  }
};
