import React, { useEffect, useState } from "react";
import { Layout, TabText } from "../../components/Layout/Layout";
import "./MyProgress.scss";
import ProfileTable from "../../components/ProfileTable/ProfileTable";
import ProfileLayout from "../../components/Profilelayout/ProfileLayout";
import { EducationIcon, closeIcon, searchIcon } from "../../utils/images";
import { useForm } from "react-hook-form";
import FormInput from "../../components/input-form/FormInput";
import Button from "../../components/button/Button";
import { SubTitele } from "../../components/subTitele/SubTitele";
import { useDispatch, useSelector } from "react-redux";
import {
  TimeSheetList,
  TimeSheetListCreate,
  TimeSheetListDelete,
  TimeSheetListUpdate
} from "../../redux/actions/TimeSheetAction";
import { courseList } from "../../redux/actions/courseAction";
import { ProductiveRateList } from "../../redux/actions/ProductiveRateAction";
import Popup from "../../components/popup/Popup";

export default function MyProgress() {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profile.profileData);
  const courseStart = profileData.course_assigned_date
  const availability = profileData.availability
  const [activeTab, setActiveTab] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [editValue, setEditValue] = useState({});
  const [activityHoursMsg, setActivityHoursMsg] = useState("");
  const [radioValues, setRadioValues] = useState(editValue.type);
  console.log("values", editValue.type, radioValues);
  //   const courseJourneyDays ={
  //   1:0,
  //   2:45,
  //   3:90,
  //   4:30
  // }
  
  // const [courseJourneyDate, setCourseJourneyDate] = useState([]);
  // useEffect(() => {
  //   if (profileData && profileData.course_assigned_date) {
  //     //------ Convert course_assigned_date to Date object ----------
  //     const initialAssignedDate = new Date(profileData.course_assigned_date);

  //     //-------- Initialize endDate as a copy of initialAssignedDate -------
  //     let endDate = new Date(initialAssignedDate);

  //     //-------- Map courseJourneyDays and calculate end dates ------- 
  //     const mappedDays = Object.entries(courseJourneyDays).map(([key, value]) => {
  //       endDate.setDate(endDate.getDate() + value);

  //       const formattedDate = endDate.toLocaleDateString("en-GB", {
  //         day: "numeric",
  //         month: "short",
  //         year: "numeric"
  //       });
  //       return {
  //         formattedDate
  //       };
  //     });
  //     setCourseJourneyDate(mappedDays); 
  //   }
  // }, [profileData]);
  // console.log(courseJourneyDate,'courseJourneyDate')
  const JourneyTimeline ={
    1:'Start Date',
    2:'Web Development Completion',
    3:'Advance Technology Completion',
    4:'Job Ready',
  }
  const ActiveTab = (data) => {
    // console.log(data);
    setActiveTab(data);
  };

  useEffect(() => {
    dispatch(TimeSheetList());
    dispatch(courseList());
  }, []);

  useEffect(() => {
    dispatch(ProductiveRateList());
  }, []);

  const timeSheetList = useSelector(
    ({ timeSheetList }) => timeSheetList?.timeSheetList
  );

  const productiveRate = useSelector(
    ({ productiveRate }) => productiveRate?.productiveRateList
  );
console.log(productiveRate,'productiveRate');
  const technologyOption = useSelector(({ courseList }) => courseList.courses);
  console.log("pr", timeSheetList);

  const productiveValue =
    productiveRate.find((item) => item.type === "Productivity")?.value || 0;

    const journeyTimeline = productiveRate.filter(item => 
      item.advanceTechStart && item.jobHired && item.jobready && item.startDate
    );
    const timeLine = journeyTimeline.map(item => [
      item.startDate,
      item.advanceTechStart,
      item.jobready,
      item.jobHired,
    ]);
  console.log(journeyTimeline,'jobDataArray');
  console.log(timeLine,'jobDataArray');


  const [timeSheetData, setTimeSheetData] = useState(timeSheetList);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    mode: "all",
  });

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    reset: reset2,
  } = useForm({
    mode: "all",
  });

  const {
    register: register3,
    formState: { errors: errors3 },
    handleSubmit: handleSubmit3,
    setValue: setValue3,
  } = useForm({
    defaultValues: editValue,
    mode: "all",
  });

  useEffect(() => {
    Object.keys(editValue).forEach((key) => {
      setValue3(key, editValue[key]);
    });
    // setRadioValues(editValue.type)
  }, [editValue, setValue3]);

  const getBorderColor = () => {
    if (productiveValue < 25) {
      return {
        borderColor: "#FF6636",
        quote: "Stay focused and keep pushing forward!",
        className:"red",
      };
    } else if (productiveValue >= 25 && productiveValue < 60) {
      return {
        borderColor: "#3282C4",
        quote: "Progress comes from taking one step at a time.",
        className:"blue",
      };
    } else if (productiveValue >= 60 && productiveValue < 90) {
      return {
        borderColor: "#FFAA00",
        quote: "You're doing well; keep up the good work!",
        className:"yellow",
      };
    } else {
      return {
        borderColor: "#6AAA43",
        quote: "Your dedication is truly inspiring.",
        className:"green",
      };
    }
  };

  const deleteTimeSheet = (item) => {
    dispatch(TimeSheetListDelete(item));
    dispatch(ProductiveRateList());
  };

  // const handleReset = () => {
  //   reset();
  //   reset2();
  //   setTimeSheetData(timeSheetList);
  // };
  // let filteredData;
  // let filteredDatas;
  // const handleFilterByDate = (data) => {
  //   const fromDate = new Date(data.fromDate);
  //   const toDate = new Date(data.toDate);
  //   filteredData = timeSheetList.filter((item) => {
  //     const currentDate = new Date(item.date);
  //     return currentDate >= fromDate && currentDate <= toDate;
  //   });
  //   // setTimeSheetData(filteredData);
  // };

 
  let filterData;
  let filterDatas;

  const handleReset = () => {
    reset();
    reset2();
    setTimeSheetData(timeSheetList);
  };

  const handleFilterByDate = (data) => {
    const fromDate = new Date(data.fromDate);
    const toDate = new Date(data.toDate);
    const filteredData = timeSheetList.filter((item) => {
      const currentDate = new Date(item.date);
      return currentDate >= fromDate && currentDate <= toDate;
    });
    setTimeSheetData(filteredData);
  };

   const handleFilterByMultiple = (data) => {
     const month = new Date(data.month).getMonth() + 1;
     const hours = parseInt(data.hours);
     const category = data.category;
     
     const filteredDatas = timeSheetList.filter((item) => {
       const dataDate = new Date(item.date).getMonth() + 1;
       const monthMatches = month === dataDate;
       const hoursDifference = parseInt(item.hours);
       const hourMatch = hours === hoursDifference;
       const categoryMatches = category === item.type;
       console.log(monthMatches, hourMatch, categoryMatches, category, item.type);

       return monthMatches && hourMatch && categoryMatches;
     });
     setTimeSheetData(filteredDatas);
   };
  // const handleFilterByMultiple = (data) => {
  //   const month = new Date(data.month).getMonth() + 1;
  //   const hours = parseInt(data.hours);
  //   const category = data.category;

  //   const filteringData = timeSheetList.filter((item) => {
  //     const dataDate = new Date(item.date).getMonth() + 1;
  //     const monthMatches = month === dataDate;

  //     const startTime = new Date(`1970-01-01T${item.start}`);
  //     const endTime = new Date(`1970-01-01T${item.end}`);
  //     const hoursDifference = Math.abs(
  //       (startTime - endTime) / (1000 * 60 * 60)
  //     );

  //     const hourMatch = hours === hoursDifference;

  //     const categoryMatches = category === item.type;

  //     return monthMatches && hourMatch && categoryMatches;
  //   });
  //   setTimeSheetData(filteringData);
  //   console.log(data, filteringData);
  // };

  useEffect(() => {
    setTimeSheetData(timeSheetList);
  }, [timeSheetList]);

  // useEffect(() => {
  //   // setTimeSheetData(filteredDatas);
  // }, [handleFilterByMultiple]);

  // useEffect(() => {
  //   // setTimeSheetData(filteredData);
  // }, [handleFilterByDate]);

  const addTimeSheet = (data) => {
    console.log("in progress before dispatching", data);
    createNew
      ? dispatch(TimeSheetListCreate(data))
      : console.log("data on edir", data);
    setIsModalOpen(false);
    setCreateNew(false);
    dispatch(ProductiveRateList());
    // console.log('data of pop-up', data)
  };

  const updateTimeSheet = (data) => {
    console.log("in progress before dispatching", data);
    dispatch(TimeSheetListUpdate(data))
    console.log("data on edit", data);
    setIsEditModalOpen(false);
    setIsModalOpen(false);
    setCreateNew(false);
    dispatch(ProductiveRateList());
    // console.log('data of pop-up', data)
  };

  const newTimeSheet = () => {
    setIsModalOpen(true);
    setEditValue({
      date: "",
      hours:"",
      type: "",
      links: "",
      description: "",
      technology_id: "",
    });
    setCreateNew(true);
  };

  function closePopup() {
    setIsModalOpen(false);
    setIsEditModalOpen(false);
    setCreateNew(false);
    setActivityHoursMsg("");
  }

  function editFunction(item) {
    console.log("item of edit", item);
    setEditValue(item);
    console.log("edit values", editValue);
    setIsEditModalOpen(true);
  }

  const tabelHeaders = [
    {
      id: 1,
      tabelHeader: "#",
      datavalue: "#",
    },
    {
      id: 2,
      tabelHeader: "Date",
      datavalue: "date",
    },
    {
      id: 3,
      tabelHeader: "Activity",
      datavalue: "type",
    },
    {
      id: 4,
      tabelHeader: "Description",
      datavalue: "description",
    },
    {
      id: 5,
      tabelHeader: "Hours",
      datavalue: "hours",
    },
    {
      id: 5,
      tabelHeader: "Link",
      datavalue: "links",
    },
    {
      id: 9,
      tabelHeader: "Actions",
      datavalue: "actions",
    },
  ];

  const optionsList = [
    {
      id: 1,
      value: "Productive Effort",
      name: "Productive Effort",
    },
    {
      id: 2,
      value: "System/Power issue",
      name: "System/Power Issue",
    },
    {
      id: 3,
      value: "Leave",
      name: "Leave",
    },
  ];

  const productiveTableHeaders = [
    {
      id: 1,
      tabelHeader: "Activity Category",
      datavalue: "type",
    },
    {
      id: 2,
      tabelHeader: "Hours",
      datavalue: "hourData",
    },
    {
      id: 3,
      tabelHeader: "Days",
      datavalue: "days",
    },
  ];


    const [time, setTime] = useState('');
    const [error, setError] = useState('');
  
    const handleChange = (e) => {
      if (e && e.target){
        const { value } = e.target;
  
        // Allow only digits and ':'
        const validChars = /^[0-9:]*$/;
    
        // Limit input length to 5 characters
        if (value.length > 5) {
          return;
        }
    
        // Allow only valid characters and specific positions for ':'
        if (validChars.test(value)) {
          if (value.length === 2 && !value.includes(':')) {
            setTime(value + ' : ');
          } else if (value.length < 2 || value.length > 3 || value.includes(':')) {
            setTime(value);
          }
        }
      };
      };
     
  
    const handleBlur = () => {
      const timePattern = /^([01]\d|2[0-3]) : ([0-5]\d)$/;
      if (!timePattern.test(time)) {
        setError('Please enter time in HH : MM format');
      } else {
        setError('');
      }
    };
  
  

  const type = [
    { value: "1", label: "Productive Effort" },
    { value: "2", label: "System/Power Issue" },
    { value: "3", label: "Leave" }
  ];
  
  const onClickActivity = (id) =>{
    if(id ==3){
      const result = '0' + availability * 4;
      setActivityHoursMsg("If you are taking leave then you have to mention the hours: half-day (" + (availability * 4) / 2 + " hours) or full-day (" + availability * 4 + " hours).");
    }else{
      setActivityHoursMsg("")
    }
    console.log(id,'profileData');
  }

  return (
    <>
      <Layout header={"My Progress"}>
        <div className="tad_header">
          <TabText
            classActive={false}
            states={activeTab}
            text={"Timesheet"}
            activeFunction={() => ActiveTab(false)}
          />
          <TabText
            classActive={true}
            states={activeTab}
            text={"Productive rate"}
            activeFunction={() => ActiveTab(true)}
          />
        </div>
        <>
          {activeTab ? (
            <>
              <div className="quote_box">
                <div className={`border_left ${getBorderColor().className}`} style={getBorderColor()}>

                <p>{getBorderColor().quote}</p>
                </div>
              </div>
              <div className="profile-section">
                <SubTitele>Journey Timeline</SubTitele>
                <div className="time">
                  {[1, 2, 3, 4].map((index) => {
                     const formattedDate = timeLine && timeLine.length > 0 && timeLine[0][index -1]? timeLine[0][index -1] : 'Not Yet Assigned'; // Default to empty string if undefined
                    return (
                      <div className="time_stage" key={index}>
                        
                        <span className={`daysCount ${productiveRate[4]?.[index-1] ||'hideDays'} daysCount_${index}`}>{productiveRate[5][index-1] ||'--'} Days</span>
                        
                        <p className={`time_stage_no stage_${index}_color`}>Stage 0{index}</p>
                        <h5 className={`time_stage_date stage_${index}_color`}>
                          <span>{formattedDate}</span>
                        </h5>
                          <span className={`exceptCount ${productiveRate[4]?.[index-1] ||'hideDays'} daysCount_${index}`}>{productiveRate[4][index-1] ||'-'} Days</span>
                        <p className="time_stage_start">{JourneyTimeline[index]} </p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="profile-section time_table">
                {productiveRate[3] && productiveRate[3].length !==0 ? (

                <ProfileTable
                  tabelHeaders={productiveTableHeaders}
                  values={productiveRate[3] ||[]}
                  subTitele={"Productive rate"}
                />
                ):(
                  <>
                  <ProfileTable
                    subTitele={"Productive rate"}
                  />
                  <div className='emty-Layout'>
                  <img src={EducationIcon} alt="bg-image" />
                  <p className='emty-Layout-content'>Progress hasn't been updated yet.</p>
                  </div>
                  </>

                )}
              </div>
            </>
          ) : (
            <>
              {timeSheetList && timeSheetList.length !== 0 ? (
                <div className="profile-section">
                  <div className="form_section">
                    <form
                      key={1}
                      className="form_field"
                      onSubmit={handleSubmit(handleFilterByDate)}>
                      <FormInput
                        label={"From"}
                        // placeholder={"Enter College Name"}
                        type={"date"}
                        errorMSG={errors.fromDate?.message}
                        required={{
                          ...register("fromDate", {
                            required: "From Date is required",
                          }),
                        }}
                        // value={watch("college_name")}
                      />
                      <FormInput
                        label={"To"}
                        type={"date"}
                        // placeholder={"Enter College Name"}
                        errorMSG={errors.toDate?.message}
                        required={{
                          ...register("toDate", {
                            required: "To Date is required",
                          }),
                        }}
                        // value={watch("college_name")}
                      />
                      <Button type={"submit"}>
                        <img src={searchIcon} alt="" />
                      </Button>
                    </form>

                    <form
                      key={2}
                      className="form_field"
                      onSubmit={handleSubmit2(handleFilterByMultiple)}>
                      <FormInput
                        label={"Month"}
                        type={"date"}
                        // placeholder={"Enter College Name"}
                        // select={true}
                        errorMSG={errors2.month?.message}
                        required={{
                          ...register2("month", {
                            required: "Month is required",
                          }),
                        }}
                        // value={watch("college_name")}
                      />
                      <FormInput
                        label={"Hours"}
                        type={"number"}
                        className={"filter_hours"}
                        timeIcon={true}
                        placeholder={"HH"}
                        errorMSG={errors2.hours?.message}
                        required={{
                          ...register2("hours", {
                            required: "Hours is required",
                            validate: (value) => {
                              // Regex to validate two digits and restrict to 00-12
                              const regex = /^(?:0[0-9]|10)$/;
                              return regex.test(value) || "Enter a valid hour (00 to 10)";
                            }
                          }),
                        }}
                        // value={watch("college_name")}
                      />
                      <FormInput
                        label={"Category"}
                        select={true}
                        options={optionsList}
                        // placeholder={"Enter College Name"}
                        errorMSG={errors2.category?.message}
                        required={{
                          ...register2("category", {
                            required: "Category is required",
                          }),
                        }}
                        // value={watch("college_name")}
                      />
                      <Button type={"submit"}>
                        <img src={searchIcon} alt="" />
                      </Button>
                      <Button functions={handleReset}> Reset</Button>
                    </form>
                  </div>
                  <ProfileTable
                    tabelHeaders={tabelHeaders}
                    values={timeSheetData}
                    subTitele={"Timesheet"}
                    openPopup={newTimeSheet}
                    openEditPopup={editFunction}
                    deleteItem={deleteTimeSheet}
                  />
                </div>
              ) : (
                <ProfileLayout
                  content={"No Timesheet records found"}
                  img={EducationIcon}
                  buttonContent={"+ Add Time sheet"}
                  openPopup={newTimeSheet}
                />
              )}
            </>
          )}
          <Popup
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}>
            

            <div className="popup_header">
              <h3>Add TimeSheet</h3>
              <img onClick={closePopup} src={closeIcon} alt="" />
            </div>

            <form onSubmit={handleSubmit3(addTimeSheet)}>
              <p className="leave_apply">{activityHoursMsg}</p>
              <div className="form_section add">
                <FormInput
                  label={"Date"}
                  placeholder={"DDD"}
                  type={"date"}
                  className ={'date_icon'}
                  dateIcon={true}
                  errorMSG={errors3.date?.message}
                  required={{
                    ...register3("date", {
                      required: "Date  is required",
                    }),
                  }}
                  max={new Date().toISOString().split("T")[0]} 
                  // value={watch("college_name")}
                />
                {/* <FormInput
                  label={"Start Time"}
                  // placeholder={"Enter Degree"}
                  type={"time"}
                  errorMSG={errors3.start?.message}
                  required={{
                    ...register3("start", {
                      required: "Satrt Time is required",
                    }),
                  }}
                />
                <FormInput
                  label={"End Time"}
                  // placeholder={"Enter Degree"}
                  type={"time"}
                  errorMSG={errors3.end?.message}
                  required={{
                    ...register3("end", {
                      required: "End Time is required",
                    }),
                  }}
                /> */}
                  
                <FormInput
                  label={"Hours"}
                  placeholder={"HH"}
                  type={"number"}
                  onChange={handleChange}
                  className={"hours"}
                  timeIcon={true}
                  errorMSG={errors3.hours?.message}
                  required={{
                    ...register3("hours", {
                      required: "Hours is required",
                      validate: (value) => {
                        // Regex to validate two digits and restrict to 00-10
                        const regex = /^(?:0[0-9]|10)$/;
                        return regex.test(value) || "Enter a valid hour (00 to 10)";
                      }
                    }),
                  }}
                />
                <FormInput
                  radioLabel={"Activity"}
                  type={"radio"}
                  onClick={onClickActivity}
                  options={type}
                  errorMSG={errors3.type?.message}
                  required={{
                    ...register3("type"),
                    required: "Activity is required",
                  }}
                />

                <FormInput
                  label={"Link (if any) :"}
                  placeholder={"Link of Practice part"}
                  errorMSG={errors3.links?.message}
                  className={"link"}
                  clipboard={true}
                />
                <FormInput
                  label={"Technology"}
                  select={true}
                  options={technologyOption}
                  className={'technology'}
                  errorMSG={errors3.technology_id?.message}
                  required={{
                    ...register3("technology_id", {
                      required: "Technology is required",
                    }),
                  }}
                />
              </div>

              <FormInput
                label={"Description"}
                // placeholder={"Enter College Name"}
                // type={"text"}
                textarea={true}
                errorMSG={errors3.description?.message}
                required={{
                  ...register3("description", {
                    required: "Description  is required",
                  }),
                }}
                // value={watch("college_name")}
              />

              <div className=" button_group">
              <Button type="button" functions={closePopup}>
                  Cancel
                </Button>
                <Button type="submit" functions={() => setIsModalOpen(true)}>
                  Add TimeSheet
                </Button>
              </div>
            </form>
          </Popup>

          {/* -------------- Edit pop up ---------- */}
          <Popup
            isOpen={isEditModalOpen}
            onRequestClose={() => setIsEditModalOpen(false)}>
            <div className="popup_header">
              <h3>Edit TimeSheet</h3>
              <img onClick={closePopup} src={closeIcon} alt="" />
            </div>
            <form onSubmit={handleSubmit3(updateTimeSheet)}>
            <p className="leave_apply">{activityHoursMsg}</p>
              <div className="form_section add">
                <FormInput
                  label={"Date"}
                  placeholder={"DDD"}
                  type={"date"}
                  className ={'date_icon'}
                  dateIcon={true}
                  errorMSG={errors3.date?.message}
                  required={{
                    ...register3("date", {
                      required: "Date  is required",
                    }),
                  }}
                  // value={watch("college_name")}
                />
                  
                <FormInput
                  label={"Hours"}
                  placeholder={"HH"}
                  type={"number"}
                  onChange={handleChange}
                  className={"hours"}
                  timeIcon={true}
                  errorMSG={errors3.hours?.message}
                  required={{
                    ...register3("hours", {
                      required: "Hours is required",
                      validate: (value) => {
                        // Regex to validate two digits and restrict to 00-12
                        const regex = /^(?:0[0-9]|1[0-2])$/;
                        return regex.test(value) || "Enter a valid hour (00 to 12)";
                      }
                    }),
                  }}
                />

                <FormInput
                  radioLabel={"Activity"}
                  type={"radio"}
                  options={type}
                  onClick={onClickActivity}
                  errorMSG={errors3.type?.message}
                  required={{
                    ...register3("type"),
                    required: "Activity is required",
                  }}
                />

                <FormInput
                  label={"Link (if any) :"}
                  placeholder={"Link of Practice part"}
                  errorMSG={errors3.links?.message}
                  className={"link"}
                  clipboard={true}
                  required={{
                    ...register3("links"),
                  }}
                />
                <FormInput
                  label={"Technology"}
                  select={true}
                  options={technologyOption}
                  className={'technology'}
                  errorMSG={errors3.technology_id?.message}
                  required={{
                    ...register3("technology_id", {
                      required: "Technology is required",
                    }),
                  }}
                />
              </div>

              <FormInput
                label={"Description"}
                // placeholder={"Enter College Name"}
                // type={"text"}
                textarea={true}
                errorMSG={errors3.description?.message}
                required={{
                  ...register3("description", {
                    required: "Description  is required",
                  }),
                }}
                // value={watch("college_name")}
              />

              <div className=" button_group">
              <Button type="button" functions={closePopup}>
                  Cancel
                </Button>
                <Button type="submit" functions={() => setIsModalOpen(true)}>
                  Update TimeSheet
                </Button>
              </div>
            </form>
          </Popup>
        </>
      </Layout>
    </>
  );
}
