import { ActionTypes } from "../constants/action-types";

const initialState = {
  projects: [],
  loading: false,
  error: null,
};

export const projectsReducer = (
  state = initialState,
  { type, payload, error }
) => {
  switch (type) {
    case ActionTypes.PROJECTS_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ActionTypes.PROJECTS_DATA:
      return {
        ...state,
        loading: false,
        projects: payload,
      };

    case ActionTypes.PROJECTS_ERROR:
      return {
        ...state,
        loading: false,
        error: error,
        projects: [],
      };

    default:
      return state;
  }
};
