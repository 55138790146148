import apiURL from "../../apis/apiURL";
import { notify } from "../../components/Layout/Layout";
import { ActionTypes } from "../constants/action-types";
import { loader } from "./loginAuthActions";

export const TimeSheetList = () => async (dispatch) => {
    dispatch(loader(true))
  try {
    const response = await apiURL.get("/timesheet");
    const responseData = response.data;
    if (responseData.status) {
      dispatch({
        type: ActionTypes.TIMESHEET_DATA,
        payload: responseData.data,
      });
    //   notify("successful", "Education has been deleted");
    } else {
      // Handle unsuccessful profile fetch
    }
      dispatch(loader(false))
  } catch (error) {
    console.error("Error in projectdetails:", error);
      dispatch(loader(false))
  }
};

export const TimeSheetListCreate = (data) => async (dispatch) => {
  dispatch(loader(true));
  console.log(data, "data");
  try {
    const response = await apiURL.post(`/timesheet`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const responseData = response.data;
    if (responseData.status) {
      console.log('in action',responseData);
      dispatch(TimeSheetList());
      notify("success", "Timesheet has been added successfully");
    } else {
    //   notify("error", "Unsuccessful");
      console.log(responseData, "Unsuccessful");
    }
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
    if (error.response?.data?.errors?.links) {
      notify("error", "The link field format is invalid.");
    } else {
      notify("error", error.response?.data?.message || "An error occurred");
    }
    console.log(error, "error");
  }
};

export const TimeSheetListUpdate = (data) => async (dispatch) => {
  dispatch(loader(true));
  console.log(data.id, "dataaaaaaaaaa");
  try {
    const response = await apiURL.post(`/timesheet/${data.id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const responseData = response.data;
    if (responseData.status) {
      console.log('in action',responseData);
      dispatch(TimeSheetList());
      notify("success", "Timesheet has been updated successfully");
    }  else {
      // Display server-side validation errors if available
      const errorMessage = responseData.message || "An unexpected error occurred";
      notify("error", errorMessage);
      console.log(responseData, "Unsuccessful");
    }
  } catch (error) {
    // Handle network or unexpected errors
    const errorMessage = error.response?.data?.message || error.message || "An unexpected error occurred";
    notify("error", errorMessage);
    console.log(error, "error");
  } finally {
    dispatch(loader(false));
  }
};



export const TimeSheetListDelete = (data) => async (dispatch) => {
    dispatch(loader(true))

  try {
    const response = await apiURL.delete(`/timesheet/${data}`);
    const responseData = response.data;
    if (responseData.status) {
      // dispatch({
      //   type: ActionTypes.TIMESHEET_DATA,
      //   payload: responseData.data,
      // });
      dispatch(TimeSheetList());
      notify("error", "Timesheet has been deleted");
    } else {
      //   notify("error", "Unsuccessful");
    }
      dispatch(loader(false))
  } catch (error) {
    // notify("error", "Unsuccessful");
      dispatch(loader(false))
  }
};

