import React, { useEffect, useState } from "react";
import SideBar from "../../components/side-bar/SideBar";
import { Layout, TabText } from "../../components/Layout/Layout";
import "./Interviews.scss";
import { useDispatch, useSelector } from "react-redux";
import { InterviewCreate, InterviewList, interviewCancel } from "../../redux/actions/InterviewsActions";
import { useForm } from "react-hook-form";
import { SubTitele } from "../../components/subTitele/SubTitele";
import ProfileTable from "../../components/ProfileTable/ProfileTable";
import FormInput from "../../components/input-form/FormInput";
import Button from "../../components/button/Button";
import ProfileLayout from "../../components/Profilelayout/ProfileLayout";
import Popup from "../../components/popup/Popup";
import { courseList } from "../../redux/actions/courseAction";
import { ProfileIcon } from '../../utils/images'
import {
  EducationIcon,
  EmptyStar,
  StarIcon,
  closeIcon,
  halfStarIcon,
  levelIcon,
  modeIcon,
  searchIcon,
  techStack_icon,
  userIcon
} from "../../utils/images";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

export default function Interviews() {
  const [activeTab, setActiveTab] = useState(false);

  const technologyOption = useSelector((state) => state.courseList);
  // console.log('tt', technologyOption)

  const ActiveTab = (data) => {
    console.log(data);
    setActiveTab(data);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [editValue, setEditValue] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [questionsAskedPop, setQuestionsAskedPop] = useState(false);
  const [questionsAsked, setQuestionsAsked] = useState('');
  // console.log(editValue);
  const dispatch = useDispatch();
  function call() {
    console.log("Feedback:");
    // Additional logic can go here if needed
  };
  
  const interviewRequest = useSelector(
    ({ interViewList }) => interViewList.interviewRequestList
  );
  const interviewAchieved = useSelector(
    ({ interViewList }) => interViewList.interviewAchievedList
  );

  console.log('interview request', interviewAchieved)

  useEffect(() => {
    dispatch(courseList());
    dispatch(InterviewList());
  }, []);


  function editFunction(item) {
    setEditValue(item);
    setIsModalOpen(true);
  }

  const deleteItem = (itemId) => {
    console.log("delete", itemId);
    dispatch(interviewCancel(itemId));
  };

  const form = useForm({
    defaultValues: editValue,
    mode: "all",
  });

  useEffect(() => {
    form.reset(editValue);
  }, [editValue, form]);
  const { register, control, handleSubmit, formState, setValue, watch, reset } = form;
  const { errors } = formState;

  const onSubmit = async (data) => {
    console.log('data of tech', data)
    dispatch(InterviewCreate(data))
    reset({
      date: '',
      mode: '',
      technology_id: '',
      level: '',
    });
    setIsModalOpen(false);
    setCreateNew(false);
  };

  const newUser = () => {
    setIsModalOpen(true);
    setEditValue({
      date: "",
      mode: "",
      technology_id: "",
      level: "",
    });
    setCreateNew(true);
  };

  function closePopup() {
    setIsModalOpen(false);
    setCreateNew(false);
    setQuestionsAskedPop(false);
    setShowPopup(false);
  }

  const handleMouseEnter = () => {
    setShowPopup(true);
  };
  const handleMouseLeave = () => {
    setShowPopup(false);
  };


  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    for (let i = 0; i < fullStars; i++) {
      stars.push(<img key={`star-${i}`} src={StarIcon} alt="Full Star" />);
    }

    if (hasHalfStar) {
      stars.push(<img key="half-star" src={halfStarIcon} alt="Half Star" />);
    }

    const remainingStars = 5 - stars.length;
    for (let i = 0; i < remainingStars; i++) {
      stars.push(<img key={`empty-${i}`} src={EmptyStar} alt="Empty Star" />);
    }

    return stars;
  };

  const formatTime = (time) => {
    if (time) {
      const parsedTime = new Date(`1970-01-01T${time}`);
      return parsedTime.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      });
    }
    return "";
  };
  const mode = [
    { value: "1", label: "Remote" },
    { value: "2", label: "Onsite" },
  ];
  const level = [
    { value: "1", label: "Beginner" },
    { value: "2", label: "Intermediate" },
    { value: "3", label: "Advanced" },
  ]
  return (
    <>
      <Layout header={"Interviews"}>
        <div className="tad_header">
          <TabText
            classActive={false}
            states={activeTab}
            text={"Achieved"}
            activeFunction={() => ActiveTab(false)}
          />
          <TabText
            classActive={true}
            states={activeTab}
            text={"Request Interview"}
            activeFunction={() => ActiveTab(true)}
          />
        </div>
        <>
          {activeTab ? (
            <>
              {interviewRequest.length != 0 ? (
                <>
                  <div className="profile-section">
                    <div className="scheduled_interview_header">
                      {" "}
                      <SubTitele>Scheduled interviews</SubTitele>
                      <Button functions={() => setIsModalOpen(true)}>
                        Schedule Interview
                      </Button>
                    </div>

                    <div className="scheduled_interview_card">
                      {interviewRequest &&
                        interviewRequest?.map((item) => (
                          <Tippy
                          key={item.id}
                          content={item.status === "Student cancelled" ? "Contact Admin for Re-Schedule Interview, Currently Interview has been Cancelled" : item.status}
                        >
                          <div className="scheduled_interview_card_section" 
                          >
                            <h3>
                              {item.technology}
                              <span>{item.level}</span>
                            </h3>
                            <ul>
                              <li>
                                Scheduled On {item.date}{" "}
                                {item.time ? ` / ${formatTime(item.time)}` : ""}
                              </li>
                              <li>{item.mode}</li>
                              <li>{item.status}</li>
                            </ul>
                            <div className="button_section">
                              <Button fill disabledButton={item.status === "Student cancelled"} functions={() => deleteItem(item.id)}>Cancel</Button>
                              <Button
                                fill
                                disabledButton={item.joiningLink === ""}
                                functions={() => {
                                  if (item.joiningLink !== "") {
                                    window.open(item.joiningLink, "_blank");
                                  }
                                }}>
                                Join
                              </Button>
                            </div>
                          </div>
                        </Tippy>
                        ))}

                      {/* <div className="scheduled_interview_card_section">
                        <h3>
                          Asp Dot Net<span>Intermediate</span>
                        </h3>
                        <ul>
                          <li>Scheduled On 23/03/23</li>
                          <li>Remote</li>
                        </ul>
                        <div className="button_section">
                          <Button fill>Cancel</Button>
                          <Button fill>Join</Button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </>
              ) : (
                <div className="profile-section time_table">
                  <ProfileLayout
                    content={"No Interviews scheduled"}
                    img={EducationIcon}
                    buttonContent={"+ Add Interview Request"}
                    openPopup={newUser}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              {interviewAchieved && interviewAchieved.length > 0  ? (
                <>
                  <h4>Mock Interviews</h4>
                  {interviewAchieved &&
                    interviewAchieved?.map((item,index) => (
                      <div className="profile-section" key={item.id || index}>
                        <div className="interviews_section">
                          <div className="interviews">
                            <div className="interviews_status">
                              <div className="interviews_status_img">
                                <img src={techStack_icon} alt="" />
                              </div>
                              <div className="interviews_status_text">
                                <h5>{item.technology}</h5>
                                <p>Tech Stack</p>
                              </div>
                            </div>
                            <div className="interviews_status">
                              <div className="interviews_status_img">
                                <img src={levelIcon} alt="" />
                              </div>
                              <div className="interviews_status_text">
                                <h5>{item.level}</h5>
                                <p>Level</p>
                              </div>
                            </div>
                            <div className="interviews_status">
                              <div className="interviews_status_img">
                                <img src={modeIcon} alt="" />
                              </div>
                              <div className="interviews_status_text">
                                <h5>{item.mode}</h5>
                                <p>Mode</p>
                              </div>
                            </div>
                          </div>
                          <div className="interviews_status profile_part">
                            <div className="interviews_status_img">
                              {/* <img src={item.userProfile?item.userProfile:ProfileIcon} alt=""/> */}
                              <img src={userIcon} alt="" />
                            </div>
                            <div className="interviews_status_text">
                              <h5>{item.interviewer_name}</h5>
                              <p> {renderStars(item.rateing)}</p>
                            </div>
                          </div>
                        </div>
                        <div className="Interview_section">
                          <h5>Date of Interview :</h5>
                          <p>{item.date}</p>
                          <h5>Feedbacks :</h5>
                          <p>
                            {item.feedback? item.feedback :'' }
                          </p>

                          <Button functions={() => {
                            setQuestionsAskedPop(true);
                            setQuestionsAsked(item.interviewquestion);
                            }}>View questions</Button>
                        </div>
                      </div>
                    ))}
                </>
              ) : (
                <div className="profile-section time_table">
                  <ProfileLayout
                    content={"No interviews achieved"}
                    img={EducationIcon}
                    buttonContent={"+ Add Interview Request"}
                    openPopup={newUser}
                  />
                </div>
              )}
            </>
          )}

          <Popup isOpen={questionsAskedPop} >
              <div className="popup_header">
                <h3>Questions Asked</h3>
                <img onClick={closePopup} src={closeIcon} alt="Close" style={{ cursor: 'pointer' }} />
              </div>
              <div>
                <p className="questionsAskedPop">{questionsAsked ? (
                <div dangerouslySetInnerHTML={{ __html: questionsAsked }} />
                ) : (
                  <p>No questions available.</p>
                )}</p>
              </div>
          </Popup>

          <Popup
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}>


            <div className="popup_header">
              <h3>Schedule Interview</h3>
              <img onClick={closePopup} src={closeIcon} alt="" />
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form_section add">
                <FormInput
                  label={"Date"}
                  // placeholder={"Enter College Name"}
                  type={"date"}
                  errorMSG={errors.date?.message}
                  required={{
                    ...register("date", {
                      required: "date  is required",
                    }),
                  }}
                // value={watch("college_name")}
                />
                {/* 
                <div className="form_field">
                  <FormInput
                    radioLabel={"Mode"}
                    label={"Remote"}
                    value={1}
                    // placeholder={"Enter Degree"}
                    type={"radio"}
                    errorMSG={errors.mode?.message}
                    required={{
                      ...register("mode", {
                        required: "mode is required",
                      }),
                    }}
                  />
                  <FormInput
                    label={"Onsite"}
                    value={2}
                    // placeholder={"Enter Degree"}
                    type={"radio"}
                    errorMSG={errors.mode?.message}
                    required={{
                      ...register("mode", {
                        required: "mode is required",
                      }),
                    }}
                  />
                </div> */}


                <FormInput
                  radioLabel={"Mode"}
                  type={"radio"}
                  options={mode}
                  required={{
                    ...register("mode"),
                  }}
                />
                <FormInput
                  label={"Tech Stack"}
                  select={true}
                  options={technologyOption.courses}
                  errorMSG={errors.technology_id?.message}
                  clipboard={true}
                  required={{
                    ...register("technology_id", {
                      required: "Technology is required", 
                    }),
                  }}
                />

                {/* <div className="form_field">
                  <FormInput
                    radioLabel={"Level"}
                    label={"Beginner"}
                    value={1}
                    // placeholder={"Enter Degree"}
                    type={"radio"}
                    errorMSG={errors.level?.message}
                    required={{
                      ...register("level", {
                        required: "level is required",
                      }),
                    }}
                  />
                  <FormInput
                    label={"Intermediate"}
                    value={2}
                    // placeholder={"Enter Degree"}
                    type={"radio"}
                    errorMSG={errors.level?.message}
                    required={{
                      ...register("level", {
                        required: "level is required",
                      }),
                    }}
                  />
                  <FormInput
                    label={"Advanced"}
                    value={3}
                    // placeholder={"Enter Degree"}
                    type={"radio"}
                    errorMSG={errors.level?.message}
                    required={{
                      ...register("level", {
                        required: "level is required",
                      }),
                    }}
                  />
                </div> */}

                <FormInput
                  radioLabel={"Level"}
                  type={"radio"}
                  options={level}
                  required={{
                    ...register("level"),
                  }}
                />
              </div>

              <div className=" button_group">
                <Button type="button" functions={closePopup}>
                  Cancel
                </Button>
                <Button type="submit" functions={() => setIsModalOpen(true)}>
                  Schedule
                </Button>
              </div>
            </form>
          </Popup>
          <Popup isOpen={showPopup}
            onRequestClose={() => setShowPopup(false)}>
              <div className="popup_header">
              <p style={{ textAlign: 'center' }}>Contact Admin for Re-Schedule Interview, Currently Interview has been Cancelled</p>
              <img onClick={closePopup} src={closeIcon} alt="" />
            </div>
          </Popup>
        </>
      </Layout>
    </>
  );
}
