import apiURL from "../../apis/apiURL";
import { notify } from "../../components/Layout/Layout";
import { ActionTypes } from '../constants/action-types';
import { loader } from "./loginAuthActions";

export const certificatesList = () => async (dispatch) => {
  dispatch(loader(true))

    try {
      const response = await apiURL.get("/certificates");
      const responseData = response.data;
      if (responseData.status) {
        dispatch({ type: ActionTypes.CERTIFICATES_DATA, payload: responseData.data });
      } else {
        // Handle unsuccessful profile fetch
      }
        dispatch(loader(false))

    } catch (error) {
      console.error('Error in certificates:', error);
        dispatch(loader(false))

    }
  };


  export const certificatesListEdit = (data) => async (dispatch) => {
    const id = data.id;
    const editData = {
      title: data.title,
      credential_id: data.credential_id,
      link: data.link,
      issue_date: data.issue_date,
      expiry_date: data.expiry_date,
      // end_year: data.end_year,
      ...(data.certificates.name && { certificates: data.certificates })
     
    };
  
      dispatch(loader(true))
    try {
      const response = await apiURL.post(`/certificates/${id}`, editData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
       const responseData = response.data;
      if (responseData.status) {
        console.log(responseData.status);
        dispatch(certificatesList());
        notify("success", "Education has been Updated Successfully");
      } else {
        notify("error", "Unsuccessful");
        dispatch({ type: ActionTypes.EDUCATION_ERROR });
      }
        dispatch(loader(false))
    } catch (error) {
      console.error("Error in educations:", error);
        dispatch(loader(false))
      notify("error", "Unsuccessful");
    }
  };
  
  export const certificatesListDelete = (data) => async (dispatch) => {
      dispatch(loader(true))
  
    try {
      const response = await apiURL.delete(`/certificates/${data}`);
      const responseData = response.data;
      if (responseData.status) {
        dispatch(certificatesList());
        notify("error", "Education has been deleted");
      } else {
        notify("error", "Unsuccessful");
      }
        dispatch(loader(false))
    } catch (error) {
      notify("error", "Unsuccessful");
        dispatch(loader(false))
    }
  };
  
  export const certificatesListCreate = (data) => async (dispatch) => {
      dispatch(loader(true))
  
    console.log(data, "data");
  
    try {
      const response = await apiURL.post(`/certificates`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept": "application/json"
        },
      });
       const responseData = response.data;
      if (responseData.status) {
        console.log(responseData.status);
        dispatch(certificatesList());
        notify("success", "Certificate has been Created Successfully");
      } else {
        notify("error", "Unsuccessful");
        console.log(responseData , "Unsuccessful");
      }
        dispatch(loader(false))
    } catch (error) {
      if (error.response && error.response.status === 422) {
        notify("error", "Please check your field values");
      }else{
        notify("error", "Unsuccessful");
        console.log(error, "error");
      }
      dispatch(loader(false))
    }
  };