import React, { useEffect, useState } from "react";
import { Layout } from "../../components/Layout/Layout";
import ProfileLayout from "../../components/Profilelayout/ProfileLayout";
import { EducationIcon, closeIcon } from "../../utils/images";
import ProfileTable from "../../components/ProfileTable/ProfileTable";
import { useDispatch, useSelector } from "react-redux";
import {
  educationsList,
  educationsListCreate,
  educationsListDelete,
  educationsListEdit,
} from "../../redux/actions/educationsActions";
import Popup from "../../components/popup/Popup";
import FormInput from "../../components/input-form/FormInput";
import { Controller, useForm } from "react-hook-form";
import { SubTitele } from "../../components/subTitele/SubTitele";
import Button from "../../components/button/Button";
import FileUploads from "../../components/input-form/FileUpload";

export default function Education() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [editValue, setEditValue] = useState({});
  console.log(editValue);
  const dispatch = useDispatch();

  const educationsLis = useSelector((state) => state.educationList);

  useEffect(() => {
    dispatch(educationsList());
  }, []);

  function editFunction(item) {
    setEditValue(item);
    setIsModalOpen(true);
  }

  function deleteItem(item) {
    dispatch(educationsListDelete(item));
  }

  const tabelHeaders = [
    {
      id: 1,
      tabelHeader: "#",
      datavalue: "#",
    },
    {
      id: 2,
      tabelHeader: "College/Institute",
      datavalue: "college_name",
    },
    {
      id: 3,
      tabelHeader: "Degree",
      datavalue: "degree",
    },
    {
      id: 4,
      tabelHeader: "Major",
      datavalue: "major",
    },
    {
      id: 5,
      tabelHeader: "Grade / %",
      datavalue: "grade_or_percentage",
    },
    {
      id: 5,
      tabelHeader: "Start Year",
      datavalue: "start_year",
    },
    {
      id: 7,
      tabelHeader: "End Year",
      datavalue: "end_year",
    },
    {
      id: 8,
      tabelHeader: "Certificates",
      datavalue: "certificates_url",
    },
    {
      id: 9,
      tabelHeader: "Actions",
      datavalue: "actions",
    },
  ];
  const form = useForm({
    defaultValues: editValue,
    mode: "all",
  });

  useEffect(() => {
    form.reset(editValue);
  }, [editValue, form]);
  const { register, control, handleSubmit, formState, setValue, watch } = form;
  const { errors } = formState;

  const onSubmit = (data) => {
    createNew
      ? dispatch(educationsListCreate(data))
      : dispatch(educationsListEdit(data));
    setIsModalOpen(false);
    setCreateNew(false);
  };

  const newUser = () => {
    setIsModalOpen(true);
    setEditValue({
      college_name: "",
      degree: "",
      major: "",
      grade_or_percentage: "",
      start_year: "",
      end_year: "",
      certificates: null,
    });
    setCreateNew(true);
  };
  function closePopup() {
    setIsModalOpen(false);
    setCreateNew(false);
  }

  return (
    <>
      <Layout header={"My Profile"}>
        <div className="profile-section">
          {!educationsLis.educations.length == 0 ? (
            <ProfileTable
              tabelHeaders={tabelHeaders}
              values={educationsLis.educations}
              subTitele={"Education"}
              openPopup={newUser}
              openEditPopup={editFunction}
              deleteItem={deleteItem}
            />
          ) : (
            <ProfileLayout
              content={"No Educational records found"}
              img={EducationIcon}
              buttonContent={"+ Add Education"}
              openPopup={newUser}
            />
          )}

          <Popup
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}>
            <div className="popup_header">
              <h3>{createNew ? "Add Education" : "Edit Educatio"}</h3>
              <img onClick={closePopup} src={closeIcon} alt="" />
            </div>

            <form className="form_field" onSubmit={handleSubmit(onSubmit)}>
              <div className="form_section">
                <FormInput
                  label={"College Name"}
                  placeholder={"Enter College Name"}
                  errorMSG={errors.college_name?.message}
                  required={{
                    ...register("college_name", {
                      required: "College Name is required",
                    }),
                  }}
                  // value={watch("college_name")}
                />
              </div>
              <div className="form_section">
                <FormInput
                  label={"Degree"}
                  placeholder={"Enter Degree"}
                  errorMSG={errors.degree?.message}
                  required={{
                    ...register("degree", {
                      required: "Degree is required",
                    }),
                  }}
                />
                <FormInput
                  label={"Major"}
                  placeholder={"Enter major subject"}
                  errorMSG={errors.major?.message}
                  required={{
                    ...register("major", {
                      required: "Major is required",
                    }),
                  }}
                />
                <FormInput
                  label={"Grade or Percentage"}
                  placeholder={"Enter overall percentage or grade"}
                  errorMSG={errors.grade_or_percentage?.message}
                  required={{
                    ...register("grade_or_percentage", {
                      required: "Grade or Percentage is required",
                    }),
                  }}
                />
                <div className="inner_section">
                  <FormInput
                    label={"Start year"}
                    type={"number"}
                    placeholder={"Start year"}
                    errorMSG={errors.start_year?.message}
                    required={{
                      ...register("start_year", {
                        required: "Start year is required",
                        validate: {
                          matchPattern: (v) =>
                            /(?:(?:19|20)[0-9]{2})/.test(v) ||
                            "please enter valid start year",
                        },
                      }),
                    }}
                  />
                  <FormInput
                    label={"End year"}
                    placeholder={"End year"}
                    type={"number"}
                    errorMSG={errors.end_year?.message}
                    required={{
                      ...register("end_year", {
                        required: "End year is required",
                        validate: {
                          matchPattern: (v) =>
                            /(?:(?:19|20)[0-9]{2})/.test(v) ||
                            "please enter valid End year",
                        },
                      }),
                    }}
                  />
                </div>
                {/* <FormInput
                label={"Certificates (optional)"}
                placeholder={"Email"}
                // value={editValue.certificates}
                errorMSG={errors.certificates?.message}
                required={{
                  ...register("certificates", {
                    required: "Email is required",
                  }),
                }}
/> */}
                {/* 
<input
        type="file"
        accept=".pdf"
        {...register("certificates", { required: true })}
      /> */}

                {/* <Controller
                name="file"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <input
                    className="file-input"
                    type="file"
                    // ref={fileInputRef}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      setValue("certificates", file);
                    }}
                  />
                )}
              /> */}
                {/* <FormInput
  label={"Certificates"}
  placeholder={"Upload Certificates (PDF, DOC, DOCX)"}
  errorMSG={errors.certificates && errors.certificates.message}
  required={{
    ...register("certificates", {
      // required: "Certificates file is required",
      validate: {
        validFileType: (files) => {
          if (!files[0]) return true; // No file selected, handled by required validation
          const allowedTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
          const fileType = files[0].type;
          const isFileTypeValid = allowedTypes.includes(fileType);
          return isFileTypeValid || "The certificates field must be a file of type: PDF, DOC, DOCX.";
        },
      },
    }),
  }}
  type="file"
/> */}

                <FileUploads label={"Certificates (optional)"} name="certificates" control={control} setValue={setValue} />

              </div>
              <div className=" button_group">
                <Button type="button" functions={closePopup} outline={true}>
                  Close
                </Button>
                <Button type="submit" functions={() => setIsModalOpen(true)}>
                  {createNew ? "Add Education" : "Save"}
                </Button>
              </div>
            </form>
          </Popup>
        </div>
      </Layout>
    </>
  );
}
