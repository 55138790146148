import React, { useEffect, useState } from "react";
import { Layout } from "../../components/Layout/Layout";
import ProfileLayout from "../../components/Profilelayout/ProfileLayout";
import { EducationIcon, closeIcon } from "../../utils/images";
import ProfileTable from "../../components/ProfileTable/ProfileTable";
import { useDispatch, useSelector } from "react-redux";
import {
  experiencesList,
  experiencesListCreate,
  experiencesListDelete,
  experiencesListEdit,
} from "../../redux/actions/experiences";
import Popup from "../../components/popup/Popup";
import FormInput from "../../components/input-form/FormInput";
import { Controller, useForm } from "react-hook-form";
// import { SubTitele } from "../../components/subTitele/SubTitele";
import Button from "../../components/button/Button";
import {
  languagesList,
  languagesListCreate,
  languagesListDelete,
  languagesListEdit,
} from "../../redux/actions/languagesAction";

export default function Languages() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [editValue, setEditValue] = useState({});

  const dispatch = useDispatch();

  const language = useSelector((state) => state.language);

  useEffect(() => {
    dispatch(languagesList());
  }, []);

  function editFunction(item) {
    setEditValue(item);
    setIsModalOpen(true);
    // setViewValue(false);
  }

  function deleteItem(item) {
    dispatch(languagesListDelete(item));
    setIsModalOpen(false);
  }

  const tabelHeaders = [
    { id: 1, tabelHeader: "#", datavalue: "#" },
    { id: 2, tabelHeader: "Language", datavalue: "language" },
    { id: 3, tabelHeader: "Proficiency level", datavalue: "proficiency_level" },
    { id: 4, tabelHeader: "Written", datavalue: "written" },
    { id: 5, tabelHeader: "Spoken", datavalue: "spoken" },
    { id: 5, tabelHeader: "Read", datavalue: "read" },
    { id: 9, tabelHeader: "Actions", datavalue: "actions" },
  ];

  const form = useForm({
    defaultValues: editValue,
    mode: "onSubmit",
  });

  useEffect(() => {
    form.reset(editValue);
  }, [editValue, form]);

  const { register, handleSubmit, formState, setValue } = form;
  const { errors } = formState;

  const onSubmit = (data) => {
    console.log(data, "submit data");
    const changeFormate={
      language:data.language,
      proficiency_level:data.proficiency_level,
      written:data.written?"1":"0",
      spoken:data.spoken?"1":"0",
      read:data.read?"1":"0",
      native:data.native?"1":"0",
      id:data.id,
    }
    console.log(changeFormate, "submit datasssq");

    createNew
      ? dispatch(languagesListCreate(changeFormate))
      : dispatch(languagesListEdit(changeFormate));
    setIsModalOpen(false);
    setCreateNew(false);
  };

  const newUser = () => {
    // setViewValue(false);
    setIsModalOpen(true);
    setEditValue({});
    setCreateNew(true);
  };

  function closePopup() {
    setIsModalOpen(false);
    setCreateNew(false);
  }

  const proficiencyLevels = ["Beginner", "Intermediate", "Advanced"];
  const languages = [
    "English",
    "Spanish",
    "Mandarin",
    "French",
    "Tamil",
    "Arabic",
    "Russian",
    "Hindi",
    "Bengali",
    "Camalic",
  ];

  return (
    <>
      <Layout header={"My Profile"}>
        <div className="profile-section">
          {!language?.languages?.length == 0 ? (
            <ProfileTable
              tabelHeaders={tabelHeaders}
              values={language.languages}
              subTitele={"Languages"}
              openPopup={newUser}
              openEditPopup={editFunction}
              deleteItem={deleteItem}
            />
          ) : (
            <ProfileLayout
              content={"No Languages records found"}
              img={EducationIcon}
              buttonContent={"+ Add Languages"}
              openPopup={newUser}
            />
          )}

          <Popup isOpen={isModalOpen} onRequestClose={closePopup}>
            <div className={`popup_header `}>
              <h3>{createNew ? "Add Languages" : "Edit Languages"}</h3>
              <img onClick={closePopup} src={closeIcon} alt="" />
            </div>

            <form className={`form_field `} onSubmit={handleSubmit(onSubmit)}>
              <div className="form_section">
                <FormInput
                  label={"Language "}
                  select={true}
                  options={languages}
                  errorMSG={errors.language?.message}
                  required={{
                    ...register("language", {
                      required: "Language is required",
                    }),
                  }}
                />
                <FormInput
                  label={"Native"}
                  type="checkbox"
                  value={editValue?.native}
                  required={{
                    ...register("native"),
                  }}
                />
                <FormInput
                  label={"Proficiency level  "}
                  select={true}
                  options={proficiencyLevels}
                  errorMSG={errors.proficiency_level?.message}
                  required={{
                    ...register("proficiency_level", {
                      required: "Proficiency level is required",
                    }),
                  }}
                />

                <div className="inner_section">
                  <FormInput
                    label={"Spoken"}
                    value={editValue?.spoken}
                    type="checkbox"
                    required={{
                      ...register("spoken"),
                    }}
                  />

                  <FormInput
                    label={"Written"}
                    type="checkbox"
                    value={editValue?.written}
                    required={{
                      ...register("written"),
                    }}
                  />
                  <FormInput
                    label={"Read"}
                    type="checkbox"
                    value={editValue?.read}
                    required={{
                      ...register("read"),
                    }}
                  />
                </div>
              </div>

              <div className="button_group">
                <Button type="button" functions={closePopup} outline={true}>
                  Close
                </Button>
                <Button type="submit">
                  {createNew ? "Add Experience" : "Save"}
                </Button>
              </div>
            </form>
          </Popup>
        </div>
      </Layout>
    </>
  );
}
