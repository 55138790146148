import React from 'react'
import "./Footer.scss"

export default function Footer() {
  return (
    <footer className='footer'>
        <p className='footer-text'>© 2023 AspiraSys. All Rights Reserved.</p>
        <p className='footer-version'>v.05</p>
    </footer>
  )
}
