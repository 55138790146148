import { ActionTypes } from "../constants/action-types";

const initialState = {
  projectDetailList: [],
  loading: false,
  error: null,
};

export const projectsListReducer = (
  state = initialState,
  { type, payload, error }
) => {
  switch (type) {
    case ActionTypes.PROJECT_DETAIL_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ActionTypes.PROJECT_DETAIL_DATA:
      return {
        ...state,
        loading: false,
        projectDetailList: payload,
      };

    case ActionTypes.PROJECT_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: error,
        projectDetailList: [],
      };

    default:
      return state;
  }
};
