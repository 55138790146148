import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const SimpleSlider = (props) => {
  const {variableWidth, className} = props
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: variableWidth,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          

        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          

        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          

        },
      },
    ],
  };

  return (
    <Slider {...settings} className={className}>
      {props.children}
    </Slider>
  );
};

export default SimpleSlider;
