import { ActionTypes } from "../constants/action-types";

const initialState = {
  timeSheetList: [],
  loading: false,
  error: null,
};

console.log('in reducer', initialState.timeSheetList)
export const timeSheetListReducer = (
  state = initialState,
  { type, payload, error }
) => {
  switch (type) {
    case ActionTypes.TIMESHEET_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ActionTypes.TIMESHEET_DATA:
      return {
        ...state,
        loading: false,
        timeSheetList: payload,
      };

    case ActionTypes.TIMESHEET_ERROR:
      return {
        ...state,
        loading: false,
        error: error,
        timeSheetList: [],
      };

    case ActionTypes.PRODUCTIVE_CHART:
      console.log(123444444444444,payload);
      
    return {
      ...state,
      loading: false,
      timeSheetListChart: payload,
    };

    case ActionTypes.PRODUCTIVE_PIE_CHART:   
    console.log('------Google-------',payload)   
    return {
      ...state,
      loading: false,
      timeSheetListChartPie: payload,
    };

    default:
      return state;
  }
};
