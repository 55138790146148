import React, { useEffect ,useState } from 'react'
import "./Header.scss"
import { HambergerIcon, NotificationIcon, ProfileIcon } from '../../utils/images'
import { ReactSVG } from 'react-svg'
import {  NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { notificationList ,notificationClear,notificationClearAll } from "../../redux/actions/notificationAction";

export default function Header(props) {
    const{header,active} = props
    const dispatch = useDispatch();
    const [notificationBell, setNotificationBell] = useState(false);
    const [notifications, setNotifications] = useState();
    const profileData = useSelector((state)  => state.profile.profileData )

    useEffect(() => {
        dispatch(notificationList());
    }, []);
    const notification = useSelector((state) => state.notificationList);
    useEffect(() => {
        if (notification && notification.notificationList) {
            setNotifications(notification.notificationList);
        }
    }, [notification]);

    console.log('Notification',notifications);

    const notificationPop = () =>{
        setNotificationBell(prev => !prev);
    }

    const handleClearNotification = (id,type) => {
        // Dispatch an action to clear the notification by id
        console.log('Clear notification with id:', id);
        dispatch(notificationClear(id,type));
        const filterNotifications = notifications.filter(item => item.id !==id);
        setNotifications(filterNotifications);
        setNotificationBell(false);
    };

    const handleClearAll = () => {
        // Dispatch an action to clear all notifications
        console.log('Clear all notifications');
        dispatch(notificationClearAll());
        setNotifications();
        setNotificationBell(false);
    };


  return (
    <div className='header'>
        <div className="header-left">
        <ReactSVG className='HambergerIcon' src={HambergerIcon} onClick={active}/>
        <h1 className='header-text'>{header&&header}</h1>
        </div>
       
        <div className="header-right">
            <div className='notifaction cursor' onClick={notificationPop}><img src={NotificationIcon} alt="" /></div>
            {notifications && notifications.length > 0 && (
            <div class="top-icon"></div>
            )}

            <NavLink to={"/profile/general"}>
            <div className="header-profile">
                <div className="header-profile-img"><img src={profileData.image ||ProfileIcon} alt="" /></div>
                <div className="header-profile-section">
                    <p className='header-profile-id'>{profileData.aspirant_id}</p>
                    <h6 className='header-profile-name'>{profileData.first_name}</h6>
                </div>
            </div>    
            </NavLink>
            <div className={`mainNotificationPop ${notificationBell ? 'notification_display':'notification_none'}`}>
            <div className="popup_header pop_notification_header">
                <div className='notificationPop'>
                    <h3>Notifications</h3>
                    {notifications && notifications.length > 0 && (
                            <a className='clearAll cursor' onClick={handleClearAll}>Clear all</a>
                        )}
                    </div>
                </div>
                <div className="notifications">
                    <ul>
                        {notifications && notifications.length > 0 ? (
                            notifications.map((notification) => (
                                <div key={notification.id} className='notification_list'>
                                    <li className='li_notification'>{notification.description}</li>
                                    <button 
                                        type='button' 
                                        className='notification_clear cursor' 
                                        onClick={() => handleClearNotification(notification.id,notification.type)}
                                    >
                                        Clear
                                    </button>
                                </div>
                            ))
                        ) : (
                            <div className='notification_list_none'>
                            <li className=''>No notifications available</li>
                            </div>
                        )}
                    </ul>
                </div>
            </div>          

        </div>

        {/* <div className="header-right">
            <div className='notifaction' onClick={notificationPop}><img src={NotificationIcon} alt="" /></div>
            {notifications.notificationList && notifications.notificationList.length > 0 && (
            <div class="top-icon"></div>
            )}

            <NavLink to={"/profile/general"}>
            <div className="header-profile">
                <div className="header-profile-img"><img src={profileData.image ||ProfileIcon} alt="" /></div>
                <div className="header-profile-section">
                    <p className='header-profile-id'>{profileData.aspirant_id}</p>
                    <h6 className='header-profile-name'>{profileData.first_name}</h6>
                </div>
            </div>    
            </NavLink>
            <div className={`mainNotificationPop ${notificationBell ? 'notification_display':'notification_none'}`}>
            <div className="popup_header pop_notification_header">
                <div className='notificationPop'>
                    <h3>Notifications</h3>
                    {notifications.notificationList && notifications.notificationList.length > 0 && (
                            <a className='clearAll cursor' onClick={handleClearAll}>Clear all</a>
                        )}
                    </div>
                </div>
                <div className="notifications">
                    <ul>
                        {notifications.notificationList && notifications.notificationList.length > 0 ? (
                            notifications.notificationList.map((notification) => (
                                <div key={notification.id} className='notification_list'>
                                    <li className='li_notification'>{notification.description}</li>
                                    <button 
                                        type='button' 
                                        className='notification_clear cursor' 
                                        onClick={() => handleClearNotification(notification.id)}
                                    >
                                        Clear
                                    </button>
                                </div>
                            ))
                        ) : (
                            <div className='notification_list_none'>
                            <li className=''>No notifications available</li>
                            </div>
                        )}
                    </ul>
                </div>
            </div>          

        </div> */}
    </div>
  )
}
