import apiURL from "../../apis/apiURL";
import { ActionTypes } from "../constants/action-types";
import { notify } from "../../components/Layout/Layout";
import { loader } from "./loginAuthActions";

export const notificationList = () => async (dispatch) => {
    const response = await apiURL.get("/notification");
    console.log('NOTIFICATION_LIST', response)
    const responseData = response.data;
    if (responseData.status) {
      dispatch({
        type: ActionTypes.NOTIFICATION_LIST,
        payload: responseData.data,
      });
    }
};

export const notificationClear =(id,type) => async (dispatch) => {
    dispatch(loader(true))
    try {
      const response = await apiURL.post(`/notification/${id}/${type}`);
      const responseData = response.data;
      // if (responseData.status) {
      //   notify("success","Notification cleared successfully!!")
      // }
      dispatch(loader(false))
    } catch (error) {
      dispatch(loader(false))
    }
}

export const notificationClearAll =() => async (dispatch) => {
    dispatch(loader(true))
    try {
      const response = await apiURL.post(`/notification`);
      const responseData = response.data;
      // if (responseData.status) {
      //   notify("success","Notifications cleared successfully!!")
      // }
      dispatch(loader(false))
    } catch (error) {
      dispatch(loader(false))
    }
}



