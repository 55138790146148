import apiURL from "../../apis/apiURL";
import { ActionTypes } from "../constants/action-types";
import { loader } from "./loginAuthActions";
import {stageStatusUpdate} from './courseAction'
import { notify } from "../../components/Layout/Layout";

export const projectsDetailList = () => async (dispatch) => {
    dispatch(loader(true))
  try {
    const response = await apiURL.get("/submited-project");
    const responseData = response.data;
    if (responseData.status) {
      // console.log("in ac", responseData.data);

      dispatch({
        type: ActionTypes.PROJECT_DETAIL_DATA,
        payload: responseData.data,
      });
    } else {
      // Handle unsuccessful profile fetch
    }
      dispatch(loader(false))
  } catch (error) {
    console.error("Error in projectdetails:", error);
      dispatch(loader(false))
  }
};

export const ProjectListCreate = (data, projectStatus) => async (dispatch) => {
  dispatch(loader(true));
  console.log(data, "data", 'project-status', projectStatus);
  try {
    const response = await apiURL.post(`/submited-project`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const responseData = response.data;
    if (responseData.status) {
      console.log("in action", responseData);
  
      dispatch(stageStatusUpdate(projectStatus))
      // dispatch(projectsDetailList());
        notify("success", "Product has been Updated Successfully");
    } else {
      //   notify("error", "Unsuccessful");
      console.log(responseData, "Unsuccessful");
    }
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
    notify("error", "Validation error");
    console.log(error, "error");
  }
};
