import apiURL from "../../apis/apiURL";
import { ActionTypes } from "../constants/action-types";
import { loader } from "./loginAuthActions";

export const timesheetPieChartAction = () => async (dispatch) => {
  dispatch(loader(true))
  try {
      const response = await apiURL.get("/timesheet-chart-data");     
      
      console.log('------Yahoooo-------',response.data.data)

      dispatch({
        type: ActionTypes.PRODUCTIVE_PIE_CHART,
        payload: response.data.data,
      });
      dispatch(loader(false))
    } catch (error) {
      dispatch(loader(false))
    }
  };