import apiURL from "../../apis/apiURL";
import { ActionTypes } from "../constants/action-types";
import { loader } from "./loginAuthActions";

export const timesheetChartAction = () => async (dispatch) => {
  dispatch(loader(true))
    try {
      const response = await apiURL.get("/timesheet-chart");
      console.log(response.data.data,'timesheet-chart');
      
      dispatch({
        type: ActionTypes.PRODUCTIVE_CHART,
        payload: response.data.data,
      });
      dispatch(loader(false))
      
    } catch (error) {
      dispatch(loader(false))
    }
  };