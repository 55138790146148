import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FormInput from "../../components/input-form/FormInput";
import "./AuthenticationLayout/LoginPage.scss";
import Button from "../../components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string'; 
import { Register, TechnologiesList, loader } from "../../redux/actions/loginAuthActions";
import FileUploads from "../../components/input-form/FileUpload";

function Registration() {
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const [technologiesDropDownLists,setechnologiesDropDownLists] = useState([])

    const base64Decode = (str) => {
        try {
            return decodeURIComponent(escape(window.atob(str)));
        } catch (e) {
            console.error('Invalid Base64 string', e);
            return '';
        }
    };

    const encryptedEmail = queryParams.email;
    const email = encryptedEmail ? base64Decode(encryptedEmail) : '';
    console.log("------email--------", email);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const TechnologiesLists = useSelector(
        ({ loginAuth }) => loginAuth?.TechnologiesList?.data
    );

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const form = useForm({
        defaultValues: "",
        mode: "all",
    });

    useEffect(() => {
        if (TechnologiesLists) {
            setechnologiesDropDownLists(TechnologiesLists);
        }
    }, [TechnologiesLists]);

    useEffect(() => {
        dispatch(TechnologiesList()); // Fetch technologies list on component mount
    }, [dispatch]);

    useEffect(() => {
        form.reset(); // Reset form whenever the form state changes
    }, [form]);

    const { register, control, handleSubmit, formState, setValue } = form;
    const { errors } = formState;

    const handleSuccessfulRegistration = () => {
        form.reset();
        setTimeout(() => {
            navigate("/login");
        }, 5000);
    };

    const cancel = () => {
        form.reset();
    };

    const Submit = (data) => {
        dispatch(Register(data, handleSuccessfulRegistration));
        setIsFormSubmitted(true);
    };

    return (
        <div className="registration">
            <form className="registrationForm" onSubmit={handleSubmit(Submit)}>
                <h2>Registration Form</h2>
                <div className="registrationForm_inner">
                    <FormInput
                        label={"First Name"}
                        placeholder={"Enter First Name"}
                        errorMSG={errors.firstName?.message}
                        required={{
                            ...register("firstName", {
                                required: "First Name is required",
                            }),
                        }}
                    />
                    <FormInput
                        label={"Last Name"}
                        placeholder={"Enter Last Name"}
                        errorMSG={errors.lastName?.message}
                        required={{
                            ...register("lastName", {
                                required: "Last Name is required",
                            }),
                        }}
                    />
                    <FormInput
                        label={"Date Of Birth"}
                        placeholder={"Enter Date of Birth"}
                        type={"date"}
                        errorMSG={errors.dob?.message}
                        required={{
                            ...register("dob", {
                                required: "Date of Birth is required",
                            }),
                        }}
                    />
                    <FormInput
                        label={"Email"}
                        placeholder={"Email"}
                        errorMSG={errors.email?.message}
                        required={{
                            ...register("email", {
                                required: "Email is required",
                                validate: {
                                    matchPattern: (v) =>
                                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                        "Email address must be a valid address",
                                },
                            }),
                        }}
                        value={email || undefined} // Set value only if email is not empty
                    />
                    <FormInput
                        type="number"
                        label={"Mobile Number"}
                        placeholder={"Mobile Number"}
                        errorMSG={errors.mobile_no?.message}
                        required={{
                            ...register("mobile_no", {
                                required: "Mobile Number is required",
                                validate: {
                                    matchPattern: (v) =>
                                        /^([+]\d{2}[ ])?\d{10}$/.test(v) ||
                                        "Please enter a valid mobile number",
                                },
                            }),
                        }}
                    />
                    <FormInput
                        radioLabel={"Gender"}
                        type={"radio"}
                        options={[
                            { value: "male", label: "Male" },
                            { value: "female", label: "Female" },
                        ]}
                        required={{
                            ...register("gender", {
                                required: "Gender is required",
                            }),
                        }}
                        errorMSG={errors.gender?.message}
                    />
                    <FormInput
                        label={"Join Date"}
                        placeholder={"Enter Join Date"}
                        type={"date"}
                        errorMSG={errors.join_date?.message}
                        required={{
                            ...register("join_date", {
                                required: "Join Date is required",
                            }),
                        }}
                    />
                    <FormInput
                        label={"Father’s Name"}
                        placeholder={"Enter Father’s name"}
                        type={"text"}
                        errorMSG={errors.parent_name?.message}
                        required={{
                            ...register("parent_name", {
                                required: "Father’s Name is required",
                            }),
                        }}
                    />
                    <FormInput
                        label={"Father’s Mobile Number"}
                        placeholder={"Enter Father’s Mobile number"}
                        type={"number"}
                        errorMSG={errors.parent_mobile_no?.message}
                        required={{
                            ...register("parent_mobile_no", {
                                required: "Father’s Mobile Number is required",
                                validate: {
                                    matchPattern: (v) =>
                                        /^([+]\d{2}[ ])?\d{10}$/.test(v) ||
                                        "Please enter a valid mobile number",
                                },
                            }),
                        }}
                    />
                    <FormInput
                        label={"Last Graduation"}
                        placeholder={"Enter Last graduation"}
                        type={"text"}
                        errorMSG={errors.graduation_details?.message}
                        required={{
                            ...register("graduation_details", {
                                required: "Last graduation is required",
                            }),
                        }}
                    />
                    <FormInput
                        label={"Technology"}
                        placeholder={"Enter Technology"}
                        select={true}
                        errorMSG={errors.preferd_techId?.message}
                        options={technologiesDropDownLists}
                        required={{
                            ...register("preferd_techId", {
                                required: "Technology is required",
                            }),
                        }}
                    />
                    <FormInput
                        label={"LinkedIn"}
                        placeholder={"Enter LinkedIn"}
                        type={"text"}
                        errorMSG={errors.linkedIn_id?.message}
                        required={{
                            ...register("linkedIn_id", {
                                required: "LinkedIn is required",
                            }),
                        }}
                    />
                    <FileUploads
                        key="resume"
                        label={"Resume"}
                        name="resume"
                        control={control}
                        setValue={setValue}
                        register={{ required: "Resume is required" }}
                        errorMSG={errors.resume?.message}
                    />
                    <FormInput
                        radioLabel={"Mode"}
                        type={"radio"}
                        options={[
                            { value: "1", label: "On-site" },
                            { value: "2", label: "Remote" },
                        ]}
                        required={{
                            ...register("mode", {
                                required: "Mode is required",
                            }),
                        }}
                        errorMSG={errors.mode?.message}
                    />
                    <FormInput
                        radioLabel={"Session"}
                        type={"radio"}
                        options={[
                            { value: "1", label: "Full time" },
                            { value: "2", label: "Part time" },
                        ]}
                        required={{
                            ...register("availability", {
                                required: "Session is required",
                            }),
                        }}
                        errorMSG={errors.availability?.message}
                    />
                    <FormInput
                        radioLabel={"Currently Working"}
                        type={"radio"}
                        options={[
                            { value: "1", label: "Yes" },
                            { value: "0", label: "No" },
                        ]}
                        required={{
                            ...register("working_status", {
                                required: "Currently Working is required",
                            }),
                        }}
                        errorMSG={errors.working_status?.message}
                    />
                    <FormInput
                        radioLabel={"System Facility"}
                        type={"radio"}
                        options={[
                            { value: "1", label: "Yes" },
                            { value: "0", label: "No" },
                        ]}
                        required={{
                            ...register("laptop_status", {
                                required: "System Facility is required",
                            }),
                        }}
                        errorMSG={errors.laptop_status?.message}
                    />
                </div>
                <div className="radio_button">
                    <Button functions={cancel}>Cancel</Button>
                    <Button type="submit">Submit</Button>
                </div>
            </form>
        </div>
    );
}

export default Registration;
