import React, { useState, useEffect } from 'react';
import { TimeBasedGreetingimg } from '../../utils/images';
import "./TimeBasedGreeting.scss"

const greetings = {
  morning: [
    "Good morning, future coders! Every sunrise is a fresh start to unlock new opportunities. Let's dive into code and create something amazing today!",
    "Rise and shine, programmers! The morning is perfect for fresh ideas and new challenges. Let's code with energy and enthusiasm!",
    "Top of the morning to you! Harness the power of the early hours to learn and innovate. Your journey in programming starts now!",
    "Morning is the best time to focus and build. Embrace the quiet and let your creativity flow through every line of code you write.",
    "Good morning, tech enthusiasts! Today is a blank slate. Fill it with code, curiosity, and determination. Let's make it a productive session!",
  ],
  afternoon: [
    "Good afternoon, aspiring developers! Use this time to fuel your passion for programming. Keep pushing boundaries and learning new skills.",
    "Afternoon greetings, coders! Let the momentum of the day carry you forward. Dive into your projects with confidence and creativity!",
    "Hello, afternoon learners! This is your time to shine. Focus, code, and watch your skills grow with every keystroke.",
    "Good afternoon, tech trailblazers! Embrace the challenge of learning something new today. Each effort brings you closer to mastery.",
    "Afternoon coding warriors, the day is still young. Take advantage of these hours to advance your knowledge and achieve your goals.",
  ],
  evening: [
    "Good evening, night owls! The evening is perfect for quiet reflection and focused coding. Let’s turn today’s learning into tomorrow’s success.",
    "Hello, part-time programmers! Your dedication to learning in the evening is inspiring. Keep up the great work and make every minute count.",
    "Evening greetings, future developers! The night is your canvas. Paint it with code and creativity. Your hard work will pay off.",
    "Good evening, coding enthusiasts! The stars shine bright, and so does your potential. Keep coding and let your skills light up the night.",
    "Nighttime warriors, welcome! Balancing work and learning is no small feat. Your perseverance is commendable. Keep pushing and coding!",
  ],
};

const TimeBasedGreeting = () => {
  const [timeOfDay, setTimeOfDay] = useState('');
  const [greeting, setGreeting] = useState('');

  useEffect(() => {
    
    const hour = new Date().getHours();
    const date = new Date().getDate();
    let session;
    let index;
    if (hour >= 8 && hour < 12) {
      session = 'morning';
      index = date % greetings.morning.length; 
    } else if (hour >= 12 && hour < 18) {
      session = 'afternoon';
      index = date % greetings.afternoon.length; 
    } else {
      session = 'evening';
      index = date % greetings.evening.length; 
    }
    setGreeting(greetings[session][index]);
    setTimeOfDay(session);
  }, []);


  return (
    <div className="TimeBased">
        <div className='TimeBased-content'>
            <h2 className='TimeBasedGreeting'>{timeOfDay}</h2>
            <p>{greeting}</p>
        </div>

      <div className="TimeBased-img">
        <img src={TimeBasedGreetingimg} alt="" />
      </div>
    </div>
  );
};

export default TimeBasedGreeting;