import React, { useEffect } from "react";
import { Layout } from "../../components/Layout/Layout";
import { useState, useRef } from "react";
import FormInput from "../../components/input-form/FormInput";
import { useForm, Controller } from "react-hook-form";
import "./MyProfile.scss";
import { EditIcon, ProfileIcon } from "../../utils/images";
import Button from "../../components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { profileUpdate } from "../../redux/actions/profileAction";

export default function General() {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profile.profileData);
  const [edit, setEdit] = useState(true);
  // const [data, setData] = useState(
  //   {first_name:profileData.first_name,
  //   designation:profileData.designation,
  //   description:profileData.description,}

  //   );

  // console.log(data,"aaaaaaaaaaaaaaaaaaaaaaaaaa");

  // useEffect(() =>{
  //   dispatch(profile())
  //   console.log(product,"profile");
  // },[])

  // const handleImageChange = (event) => {
  //     const file = event.target.files[0];

  //     if (file) {
  //       const reader = new FileReader();

  //       reader.onloadend = () => {
  //         setProfileImage(reader.result);

  //       };

  //       reader.readAsDataURL(file);
  //     }
  //   };
  //   const handleRemoveImage = () => {
  //     console.log(profileImage)
  //     setProfileImage(null);
  //   };

  const form = useForm({
    mode: "all",
  });
  const { register, control, handleSubmit, formState, setValue, watch } = form;
  const { errors } = formState;

  useEffect(() => {
    // Set form values when editValue changes
    form.reset({
      first_name: profileData?.first_name,
      designation: profileData?.designation,
      description: profileData?.description,
      last_name: profileData?.last_name,
    });
  }, [profileData, form]);

  const fileInputRef = useRef(null);
  const file = watch("image");

  const onSubmit = (data) => {
    // Handle form submission logic
    setEdit(true);
    console.log(data);
    dispatch(profileUpdate(data));
  };

  const onEditClick = () => {
    // Handle edit button click
    // Open file input dialog
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onRemoveClick = () => {
    // Handle remove button click
    // Reset the form value for the file input
    setValue("image", null);
  };
  return (
    <>
      <Layout header={"My Profile"}>
        <div className="profile-section">
          <form className="formsection" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Controller
                name="file"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <input
                    className="file-input"
                    type="file"
                    ref={fileInputRef}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      setValue("image", file);
                    }}
                  />
                )}
              />

              <div className="profile-header">
                <div className="header-profile-img">
                  <img
                    src={
                      file
                        ? URL.createObjectURL(file)
                        : profileData?.image || ProfileIcon
                    }
                    alt=""
                  />
                </div>
                <div className="header-profile-section">
                  {edit && (
                    <p className="header-profile-id">
                      {profileData.aspirant_id}
                    </p>
                  )}

                  <h6 className="header-profile-name">
                    {profileData.first_name}
                  </h6>
                  {!edit && (
                    <div className="profile-header">
                      <button type="button" onClick={onEditClick}>
                        Edit
                      </button>
                      {/* <button type="button" onClick={onRemoveClick}>
                        Remove
                      </button> */}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="formsection-inner">
              <FormInput
                disabledInput={edit}
                label={"First Name"}
                placeholder={"Email"}
                errorMSG={errors.first_name?.message}
                required={{
                  ...register("first_name", {
                    required: "Name is required",
                  }),
                }}
              />
              <FormInput
                disabledInput={edit}
                label={"last Name"}
                placeholder={"last Name"}
                errorMSG={errors.last_name?.message}
                required={{
                  ...register("last_name", {
                    required: "Last Name is required",
                  }),
                }}
              />

              <FormInput
                disabledInput={edit}
                label={"Designation"}
                placeholder={"Designation"}
                errorMSG={errors.designation?.message}
                required={{
                  ...register("designation", {
                    required: "Designation is required",
                  }),
                }}
              />
            </div>
            <FormInput
              disabledInput={edit}
              label={"Description"}
              placeholder={"Description"}
              errorMSG={errors.description?.message}
              textarea={true}
              required={{
                ...register("description", {
                  required: "Description is required",
                }),
              }}
            />
            <div className="button-section">
              {edit && (
                <Button functions={() => setEdit(false)}>
                  <img src={EditIcon} alt="EditIcon" /> Edit
                </Button>
              )}
              {!edit && (
                <>
                  <Button functions={() => setEdit(true)}> Cancel</Button>
                  <Button type={"submit"}> save</Button>
                </>
              )}
            </div>
          </form>
        </div>
      </Layout>
    </>
  );
}
