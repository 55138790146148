import apiURL from "../../apis/apiURL";
import { notify } from "../../components/Layout/Layout";
import { ActionTypes } from "../constants/action-types";
import { loader } from "./loginAuthActions";

export const educationsList = () => async (dispatch) => {
    dispatch(loader(true))

  try {
    const response = await apiURL.get("/educations");
    const responseData = response.data;
    if (responseData.status) {
      // notify("success", "successful");
      dispatch({
        type: ActionTypes.EDUCATION_DATA,
        payload: responseData.data,
      });
    } else {
      // Handle unsuccessful profile fetch
      dispatch({ type: ActionTypes.EDUCATION_ERROR }); // Dispatch error action
      notify("error", "Unsuccessful");
    }
      dispatch(loader(false))
  } catch (error) {
    console.error("Error in educations:", error);
    dispatch({ type: ActionTypes.EDUCATION_ERROR });
      dispatch(loader(false))
    // notify("error", "Unsuccessful");
  }
};

export const educationsListEdit = (data) => async (dispatch) => {
  const id = data.id;
  const editData = {
    college_name: data.college_name,
    degree: data.degree,
    major: data.major,
    grade_or_percentage: data.grade_or_percentage,
    start_year: data.start_year,
    end_year: data.end_year,
    ...(data.certificates && { certificates: data.certificates })
   
  };

    dispatch(loader(true))
    console.log("editvimal",editData);
  try {
    const response = await apiURL.post(`/educations/${id}`, editData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
     const responseData = response.data;
    if (responseData.status) {
      console.log(responseData.status);
      dispatch(educationsList());
      notify("success", "Education has been Updated Successfully");
    } else {
      notify("error", "Unsuccessful");
      dispatch({ type: ActionTypes.EDUCATION_ERROR });
    }
      dispatch(loader(false))
  } catch (error) {
    console.error("Error in educations:", error);
      dispatch(loader(false))
    notify("error", "Unsuccessful");
  }
};

export const educationsListDelete = (data) => async (dispatch) => {
    dispatch(loader(true))

  try {
    const response = await apiURL.delete(`/educations/${data}`);
    const responseData = response.data;
    if (responseData.status) {
      dispatch(educationsList());
      notify("error", "Education has been deleted");
    } else {
      notify("error", "Unsuccessful");
    }
      dispatch(loader(false))
  } catch (error) {
    notify("error", "Unsuccessful");
      dispatch(loader(false))
  }
};

export const educationsListCreate = (data) => async (dispatch) => {
    dispatch(loader(true))


  try {
    const response = await apiURL.post(`/educations`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Accept": "application/json"
      },
    });
    console.log("education status",response);
     const responseData = response.data;
    if (responseData.status) {
      console.log(responseData.status);
      dispatch(educationsList());
      notify("success", "Education has been Created Successfully");
    } else {
      // notify("error", "Unsuccessful");
      console.log(responseData , "Unsuccessful");
    }
      dispatch(loader(false))
  } catch (error) {
      dispatch(loader(false))
    notify("error", "Unsuccessful");
    console.log(error, "error");
  }
};
