import React from "react";
import Button from "../button/Button";
import "./SCard.scss";
import {
  clockIcon,
  codeIcon,
  shareIcon,
  downloadIcon,
} from "../../utils/images";
import { LinkedinShareButton } from "react-share";

const SCard = (props) => {
  const {
    unique,
    image,
    description,
    name,
    duration,
    tech,
    type,
    issuedDate,
    handleLeftBtn,
    handleRightBtn,
    className,
    imageCont,
  } = props;

  return (
    <>
      <div className={`card-cont ${className}`} key={unique}>
        <div className={`img-cont ${imageCont}`}>
          <img src={image} alt="" />
        </div>
        <div className="content-cont">
          <h6>{name}</h6>
          <p>{type === "project" ? description : `issued On ${issuedDate}`}</p>
        </div>
        {type === "project" && (
          <div className="dur-box">
            <div className="time">
              <img src={clockIcon} alt="duration" />
              <p>{duration} days</p>
            </div>
            <div className="time">
              <img src={codeIcon} alt="technology" />
              <p title={tech}>{tech}</p>
            </div>
          </div>
        )}
        <div className="btn-box">
          {type !== "project" ? (
            <LinkedinShareButton
              url={image}
              summary="The certication of completion"
              source="aspirasys">
              <Button functions={handleLeftBtn} className="shareBtn">
                <img src={shareIcon} alt="share" />
                <p>Share</p>
              </Button>
            </LinkedinShareButton>
          ) : (
            <Button fill functions={handleLeftBtn}>
              GitHub Link
            </Button>
          )}
          <Button  functions={handleRightBtn}>
            {type !== "project" ? (
              <>
                <img src={downloadIcon} alt="download" />
                <p>Download</p>
              </>
            ) : (
              <>View Project</>
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SCard;
