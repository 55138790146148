// import React from 'react'

// export default function Popup() {
//   return (
//     <div>Popup</div>
//   )
// }
import React, { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Set the root element for accessibility

const Popup = ({ isOpen, onRequestClose ,children ,className }) => {
 
  const customStyles = {
    content: {
      maxWidth: '890px', // Set your desired maximum width here
      margin: 'auto',
      height: "fit-content",
      maxHeight: "95vh",     // Center the modal horizontally
    },
  };


  return (
    <>
   
   <Modal
      className={className?className:''}
      isOpen={isOpen}
      // onRequestClose={onRequestClose}
      contentLabel="Example Modal"
      style={customStyles}
    >
       {children}
      </Modal>
    </>
  );
};

export default Popup;
