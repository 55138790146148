import React, { useEffect } from "react";
import FormInput from "../../components/input-form/FormInput";
import { Logo } from "../../utils/images";
import Button from "../../components/button/Button";
import AuthenticationLayout from "./AuthenticationLayout/AuthenticationLayout";
import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  setProducts,
  selectedProducts,
  UseLogin,
} from "../../redux/actions/loginAuthActions.jsx";

export default function LoginPage() {
  const navigate = useNavigate();

  // redux

  const product = useSelector((state) => state.loginAuth);
  const dispatch = useDispatch();

  const handleSuccessfulLogin = () => {
    navigate("/");
  };
  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "all",
  });
  const { register, control, handleSubmit, formState } = form;
  const { errors } = formState;
  const onSubmit = (data) => {
    // console.log("form in submite", data, errors.username?.message)
    dispatch(UseLogin(data, handleSuccessfulLogin));
    // console.log(product,"wdwdwdwdwddw")
  };

  return (
    <AuthenticationLayout>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="logo">
          <img src={Logo} alt="" />
        </div>
        <h2>Login Your Account</h2>
        <FormInput
          label={"Email"}
          placeholder={"Email"}
          errorMSG={errors.email?.message}
          required={{
            ...register("email", {
              required: "Email is required",
              validate: {
                matchPattern: (v) =>
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                  "Email address must be a valid address",
              },
            }),
          }}
        />
        <FormInput
          type="password"
          label={"Password"}
          placeholder={"Password"}
          errorMSG={errors.password?.message}
          required={{
            ...register("password", {
              required: "password is required",
              validate: {
                minLength: (v) =>
                  v.length >= 4 ||
                  "The password should have at most 7 characters",
                maxLength: (v) =>
                  v.length <= 12 ||
                  "The password should have at most 12 characters",
              },
            }),
          }}
        />

        <Link className="forgrt" to="/forget-password">
          Forget Password?
        </Link>

        <Button type={"submit"} fill={true}>
          {" "}
          Login
        </Button>
      </form>
    </AuthenticationLayout>
  );
}
