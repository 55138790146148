import React, { useEffect, useState } from "react";
import { Layout } from "../../components/Layout/Layout";
import ProfileLayout from "../../components/Profilelayout/ProfileLayout";
import { EducationIcon, closeIcon } from "../../utils/images";
import ProfileTable from "../../components/ProfileTable/ProfileTable";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../../components/popup/Popup";
import FormInput from "../../components/input-form/FormInput";
import { Controller, useForm } from "react-hook-form";
import Button from "../../components/button/Button";
import { projectsList, projectsListCreate, projectsListDelete, projectsListEdit, extprojectsList } from "../../redux/actions/projectAction";

export default function Certificate() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [editValue, setEditValue] = useState({});
  console.log(editValue);
  const dispatch = useDispatch();

  const projectsLis = useSelector((state) => state.projectsList);

  useEffect(() => {
    dispatch(extprojectsList());
  }, []);
  const tabelHeaders = [
    {
      id: 1,
      tabelHeader: "#",
      datavalue: "#",
    },
    {
      id: 2,
      tabelHeader: "Title",
      datavalue: "title",
    },
    {
      id: 3,
      tabelHeader: "Company name",
      datavalue: "company_name",
    },
    {
      id: 4,
      tabelHeader: "Hosted Link",
      datavalue: "link",
    },
    {
      id: 5,
      tabelHeader: "GitHub Link",
      datavalue: "github_link",
    },
    {
      id: 5,
      tabelHeader: "Duration",
      datavalue: "duration",
    },
    {
      id: 9,
      tabelHeader: "Actions",
      datavalue: "actions",
    },
  ];

  function editFunction(item) {
    setEditValue(item);
    setIsModalOpen(true);
  }

  function deleteItem(item) {
    dispatch(projectsListDelete(item));
  }


  const form = useForm({
    defaultValues: editValue,
    mode: "all",
  });

  useEffect(() => {
    form.reset(editValue);
  }, [editValue, form]);
  const { register, control, handleSubmit, formState, setValue, watch } = form;
  const { errors } = formState;

  const onSubmit = (data) => {
    createNew
      ? dispatch(projectsListCreate(data))
      : dispatch(projectsListEdit(data));
    setIsModalOpen(false);
    setCreateNew(false);
  };

  const newUser = () => {
    setIsModalOpen(true);
    setEditValue({
      certificates: null,
    });
    setCreateNew(true);
  };
  function closePopup() {
    setIsModalOpen(false);
    setCreateNew(false);
  }
  const durationOptions = [
    {id:1,name:"1 Months"},
    {id:2,name:"2 Months"},
    {id:3,name:"3 Months"},
    {id:4,name:"4 Months"},
    {id:5,name:"5 Months"},
    {id:6,name:"6 Months"},
    {id:7,name:"7 Months"},
    {id:8,name:"8 Months"},
    {id:9,name:"9 Months"},
    {id:10,name:"10 Months"},
    {id:11,name:"11 Months"},
    {id:12,name:"12 Months"},
  ]

  return (
    <>
      <Layout header={"My Profile"}>
        <div className="profile-section">
          {!projectsLis.projects.length == 0 ? (
            <ProfileTable
            tabelHeaders={tabelHeaders}
              values={projectsLis.projects}
              subTitele={"Project"}
              openPopup={newUser}
              openEditPopup={editFunction}
              deleteItem={deleteItem}
            />
          ) : (
            <ProfileLayout
              content={"No Projects records found"}
              img={EducationIcon}
              buttonContent={"+ Add Project"}
              openPopup={newUser}
            />
          )}

          <Popup
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
          >
            <div className="popup_header">
              <h3>{createNew ? "Add Project" : "Edit Project"}</h3>
              <img onClick={closePopup} src={closeIcon} alt="" />
            </div>

            <form className="form_field" onSubmit={handleSubmit(onSubmit)}>
              <div className="form_section">
                <FormInput
                  label={"Project Title "}
                  placeholder={"Enter Project Title "}
                  errorMSG={errors.title?.message}
                  required={{
                    ...register("title", {
                      required: "Title is required",
                    }),
                  }}
                  // value={watch("college_name")}
                />

                <FormInput
                  label={"Company name "}
                  placeholder={"Enter Company name "}
                  errorMSG={errors.company_name?.message}
                  required={{
                    ...register("company_name", {
                      required: "Company name  is required",
                    }),
                  }}
                />
                <FormInput
                clipboard={true}
                  label={"Hosted Link "}
                  placeholder={"Enter Hosted Link"}
                  errorMSG={errors.link?.message}
                  required={{
                    ...register("link", {
                      required: "Hosted Link  is required",
                    }),
                  }}
                />
                 <FormInput
                   clipboard={true}
                  label={"GitHub Link"}
                  placeholder={"Enter GitHub Link"}
                  errorMSG={errors.github_link?.message}
                  required={{ 
                    ...register("github_link", {
                      required: "GitHub Link is required",
                    }),
                  }}
                />
 <FormInput
            
              label={"Description"}
              placeholder={"Description"}
              errorMSG={errors.description?.message}
              textarea={true}
              required={{
                ...register("description", {
                  required: "Description is required",
                }),
              }}
            />
                <div className="inner_section">
                 
                  <FormInput
                    label={"Duration"}
                    select={true}
                    options={durationOptions}
                    type={"select"}
                    errorMSG={errors.duration?.message}
                    required={{
                      ...register("duration", {
                        required: "Date of Relieving is required",
                        
                      }),
                    }}
                  />
                </div>
               
                
              </div>
              <div className=" button_group">
                <Button type="button" functions={closePopup} outline={true}>
                  Close
                </Button>
                <Button type="submit" functions={() => setIsModalOpen(true)}>
                  {createNew ? "Add Project" : "Save"}
                </Button>
              </div>
            </form>
          </Popup>
        </div>
      </Layout>
    </>
  );
}
