import React, { useEffect } from "react";
import { Layout } from "../../components/Layout/Layout";
import { useState, useRef } from "react";
import FormInput from "../../components/input-form/FormInput";
import { useForm, Controller } from "react-hook-form";
import "./MyProfile.scss";
import { EditIcon, ProfileIcon } from "../../utils/images";
import Button from "../../components/button/Button";
import { SubTitele } from "../../components/subTitele/SubTitele";
import FileUpload from "../../components/FileUpload";
import { profileUpdate } from "../../redux/actions/profileAction";
import { useDispatch, useSelector } from "react-redux";

export default function SocialAccount() {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profile.profileData);
  console.log(profileData);

  const [edit, setedit] = useState(true);
  // const handleImageChange = (event) => {
  //     const file = event.target.files[0];

  //     if (file) {
  //       const reader = new FileReader();

  //       reader.onloadend = () => {
  //         setProfileImage(reader.result);

  //       };

  //       reader.readAsDataURL(file);
  //     }
  //   };
  //   const handleRemoveImage = () => {
  //     console.log(profileImage)
  //     setProfileImage(null);
  //   };

  const form = useForm({
    // defaultValues:profileData,
    mode: "all",
  });

  useEffect(() => {
    form.reset({
      mobile_no: profileData?.mobile_no,
      instagram_id: profileData?.instagram_id,
      gitHub_id: profileData?.gitHub_id,
      linkedIn_id: profileData?.linkedIn_id,
      first_name: profileData?.first_name,
      designation: profileData?.designation,
      description: profileData?.description,
      last_name: profileData?.last_name,
      personal_email: profileData?.personal_email,
      
    });
  }, [profileData, form]);
  const { register, control, handleSubmit, formState, setValue, watch } = form;
  const { errors } = formState;

  const fileInputRef = useRef(null);
  const file = watch("file");
  // const onSubmit = data => {
  //     console.log("form in submite", data, errors.username?.message)

  // }

  const onSubmit = (data) => {
    // Handle form submission logic
    setedit(true);
    console.log(data , "onSubmit");
    dispatch(profileUpdate(data));
  };

  const onEditClick = () => {
    // Handle edit button click
    // Open file input dialog
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onRemoveClick = () => {
    // Handle remove button click
    // Reset the form value for the file input
    setValue("file", null);
  };
  return (
    <>
      <Layout header={"My Profile"}>
        <div className="profile-section">
          <SubTitele>Social Accounts</SubTitele>
          <form className="formsection" onSubmit={handleSubmit(onSubmit)}>
            <div className="formsection-inner">
              <FormInput
                disabledInput={edit}
                label={"LinkedIn"}
                placeholder={"Enter LinkedIn link"}
                errorMSG={errors?.linkedIn_id?.message}
                clipboard={!edit}
                required={{
                    ...register("linkedIn_id", {
                        required: "Name is required",
                    })
                }}
              />
              <FormInput
                disabledInput={edit}
                label={"GitHub"}
                placeholder={"Enter GitHub link"}
                errorMSG={errors?.gitHub_id?.message}
                clipboard={!edit}
                required={{
                    ...register("gitHub_id", {
                        required: "Last Name is required",
                    })
                }}
              />

              <FormInput
                disabledInput={edit}
                label={"Instagram link"}
                placeholder={"Enter Instagram link"}
                errorMSG={errors?.instagram_id?.message}
                clipboard={!edit}
                required={{
                    ...register("instagram_id", {
                        required: "Designation is required",
                    })
                }}
              />
               <FormInput
                disabledInput={edit}
                label={"Email ID"}
                placeholder={"Enter Email ID"}
                errorMSG={errors?.personal_email?.message}
                // clipboard={!edit}
                required={{
                    ...register("personal_email", {
                      required: "Email is required",
                      validate: {
                        matchPattern: (v) =>
                          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                          "Email address must be a valid address",
                      },
                    })
                }}
              /> 
              <FormInput
                disabledInput={edit}
                label={"Contact number"}
                placeholder={"Enter Contact number"}
                errorMSG={errors?.mobile_no?.message}
                required={{
                    ...register("mobile_no", {
                        required: "Designation is required",
                    })
                }}
              />
            </div>

            <div className="button-section">
              {edit && (
                <Button functions={() => setedit(false)}>
                  <img src={EditIcon} alt="EditIcon" /> Edit
                </Button>
              )}
              {!edit && (
                <>
                  <Button functions={() => setedit(true)}> Cancel</Button>
                  <Button type={"submit"}> save</Button>
                </>
              )}
            </div>
          </form>
        </div>
      </Layout>
    </>
  );
}
