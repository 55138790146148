import React, { useEffect, useState } from "react";
import { Layout } from "../../components/Layout/Layout";
import ProfileLayout from "../../components/Profilelayout/ProfileLayout";
import { EducationIcon, closeIcon } from "../../utils/images";
import ProfileTable from "../../components/ProfileTable/ProfileTable";
import { useDispatch, useSelector } from "react-redux";
import {
  experiencesList,
  experiencesListCreate,
  experiencesListDelete,
  experiencesListEdit,
} from "../../redux/actions/experiences";
import Popup from "../../components/popup/Popup";
import FormInput from "../../components/input-form/FormInput";
import { Controller, useForm } from "react-hook-form";
import { SubTitele } from "../../components/subTitele/SubTitele";
import Button from "../../components/button/Button";
import FileUploads from "../../components/input-form/FileUpload";

export default function Experience() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [editValue, setEditValue] = useState({present: "0"});
  const [viewValue, setViewValue] = useState(false);
  const options = ["fulltime", "internship", "hybrid", "remote"];
  const dispatch = useDispatch();

  const experiencesLis = useSelector((state) => state.experiencesList);

  useEffect(() => {
    dispatch(experiencesList());
  }, []);

  function editFunction(item) {
    setEditValue(item);
    setIsModalOpen(true);
    setViewValue(false);
  }

  function deleteItem(item) {
    dispatch(experiencesListDelete(item));
    setViewValue(false);
    setIsModalOpen(false);
  }

  function viewItem(item) {
    editFunction(item);
    setViewValue(true);
  }

  const tabelHeaders = [
    { id: 1, tabelHeader: "#", datavalue: "#" },
    { id: 2, tabelHeader: "Designation", datavalue: "designation" },
    { id: 3, tabelHeader: "Job type", datavalue: "job_type" },
    { id: 4, tabelHeader: "Company Name", datavalue: "company_name" },
    { id: 5, tabelHeader: "Location", datavalue: "location" },
    { id: 5, tabelHeader: "Date of Join", datavalue: "join_date" },
    { id: 7, tabelHeader: "Date of Relive", datavalue: "reliving_date" },
    { id: 8, tabelHeader: "Certificates", datavalue: "certificates_url" },
    { id: 9, tabelHeader: "Actions", datavalue: "actions" },
  ];

  const form = useForm({
    defaultValues: editValue,
    mode: "onSubmit",
  });

  useEffect(() => {
    form.reset(editValue);
  }, [editValue, form]);

  const { register, handleSubmit, formState, setValue ,control} = form;
  const { errors } = formState;

  const onSubmit = (data) => {
    console.log(data, "submit data");
    if (!viewValue) {
      createNew
        ? dispatch(experiencesListCreate(data))
        : dispatch(experiencesListEdit(data));
      setIsModalOpen(false);
      setCreateNew(false);
    }
  };

  const newUser = () => {
    setViewValue(false);
    setIsModalOpen(true);
    setEditValue({
      // designation: "",
      // job_type: "",
      // company_name: "",
      // location: "",
      // join_date: "",
      // reliving_date: "",
      // description: "",
      certificates: null,
      present: 0, // Set default value for present checkbox
    });
    setCreateNew(true);
  };

  function closePopup() {
    setIsModalOpen(false);
    setCreateNew(false);
  }

  useEffect(() => {
    setValue("present", editValue.present);
  }, [editValue, setValue]);
  
  const handleCheckboxChange = (e) => {
    setEditValue({
      ...editValue,
      present: e.target.checked ? "1" : "0",
    });
  };
  return (
    <>
      <Layout header={"My Profile"}>
        <div className="profile-section">
          {!experiencesLis.experiences.length == 0 ? (
            <ProfileTable
              tabelHeaders={tabelHeaders}
              values={experiencesLis.experiences}
              subTitele={"Experience"}
              openPopup={newUser}
              openEditPopup={editFunction}
              deleteItem={deleteItem}
              viewItem={viewItem}
            />
          ) : (
            <ProfileLayout
              content={"No Experience records found"}
              img={EducationIcon}
              buttonContent={"+ Add Experience"}
              openPopup={newUser}
            />
          )}

          <Popup isOpen={isModalOpen} onRequestClose={closePopup}>
            <div className={`popup_header `}>
              <h3>{createNew ? "Add Experience" : "Edit Experience"}</h3>
              <img onClick={closePopup} src={closeIcon} alt="" />
            </div>
{
  !viewValue ? 
  <form
  className={`form_field `}
  onSubmit={handleSubmit(onSubmit)}
>
  <div className="form_section">
    <FormInput
      label={"Your Designation"}
      placeholder={"Enter Your Designation"}
      errorMSG={errors.designation?.message}
      required={{
        ...register("designation", {
          required: "Designation is required",
        }),
      }}
    />

    <FormInput
      label={"Job Type"}
      select={true}
      options={options}
      placeholder={"Enter Job Type"}
      errorMSG={errors.job_type?.message}
      required={{
        ...register("job_type", {
          required: "Job Type is required",
        }),
      }}
    />
    <FormInput
      label={"Name of the Company"}
      placeholder={"Enter Name of the Company"}
      errorMSG={errors.company_name?.message}
      required={{
        ...register("company_name", {
          required: "Company is required",
        }),
      }}
    />
    <FormInput
      label={"Company Location"}
      placeholder={"Enter Company Location"}
      errorMSG={errors.location?.message}
      required={{
        ...register("location", {
          required: "Company Location is required",
        }),
      }}
    />
    <div className="inner_section">
      <FormInput
        label={"Date of Joining"}
        type={"date"}
        placeholder={"Date of Joining"}
        errorMSG={errors.join_date?.message}
        required={{
          ...register("join_date", {
            required: "Date of Joining is required",
            validate: {
              matchPattern: (v) =>
                /(?:(?:19|20)[0-9]{2})/.test(v) ||
                "please enter valid start year",
            },
          }),
        }}
      />
      <FormInput
        label={"Date of Relieving"}
        placeholder={"Date of Relieving"}
        type={"date"}
        errorMSG={errors.reliving_date?.message}
        required={
          editValue.present !== "1"
            ? {
                ...register("reliving_date", {
                  required: "Date of Relieving is required",
                  validate: {
                    matchPattern: (v) =>
                      /(?:(?:19|20)[0-9]{2})/.test(v) ||
                      "please enter valid End year",
                  },
                }),
              }
            : {} // If `editValue.present` is "1", no validation is applied
        }
        disabledInput={editValue.present === "1"}
      />
      <div className="checkboxSection">  <input
        type="checkbox"
        checked={editValue.present === "1" }
        onChange={handleCheckboxChange}
      />
      <label>Present</label></div>
    
    </div>
 
    <FileUploads label={"Certificates (optional)"} name="certificates" control={control} setValue={setValue} />
  </div>
  <FormInput
    label={"Description"}
    placeholder={"Description"}
    textarea={true}
    errorMSG={errors.description?.message}
    required={{
      ...register("description", {
        required: "Description is required",
      }),
    }}
  />
  <div className="button_group">
  <Button type="button" functions={closePopup} outline={true}>
          Close
        </Button>
        <Button type="submit">
          {createNew ? "Add Experience" : "Save"}
        </Button>
  </div>
</form> 
   :
   <div className="form_field ">
   <div className="form_section">
     <div className="form-section">
       <label>Your Designation</label>
       <p>{editValue.designation}</p>
     </div>
     <div className="form-section">
       <label>Job Type</label>
       <p>{editValue.job_type}</p>
     </div>
     <div className="form-section">
       <label>Name of the Company</label>
       <p>{editValue.company_name}</p>
     </div>
     <div className="form-section">
       <label>Company Location</label>
       <p>{editValue.location}</p>
     </div>
 
       <div className="form-section">
         <label>Date of Joining</label>
         <p>{editValue.join_date}</p>
       </div>
       <div className="form-section">
         <label>Date of Relieving</label>
         <p>{editValue.reliving_date}</p>
       </div>
       <div className="form-section">
         <label>Present</label>
         <p>{editValue.present?"Yes":"No"}</p>
       </div>
     
     <div className="form-section">
       <label>Certificates</label>
       <p>{editValue.certificates}</p>
     </div>
     <div className="form-section">
       <label>Description</label>
       <p>{editValue.description}</p>
     </div>

   </div>
   <div className="form_section">
     <div className="button_group">
       <Button
         functions={() => {
           deleteItem(editValue.id);
         }}
         outline={true}
       >
         Delete
       </Button>
       <Button functions={() => setViewValue(false)}>Edit</Button>
       </div>
     </div>
 </div>
}
           
         
          </Popup>
        </div>
      </Layout>
    </>
  );
}
