import { ActionTypes } from "../constants/action-types";

const initialState = {
  timeSheetList: [],
  loading: false,
  error: null,
};

console.log('in reducer', initialState.timeSheetList)
export const notificationListReducer = (
  state = initialState,
  { type, payload, error }
) => {
  switch (type) {
    case ActionTypes.NOTIFICATION_LIST:
      return{
        ...state,
        loading: true,
        notificationList: payload
      } 

    default:
      return state;
  }
};
