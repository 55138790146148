import React, { useEffect, useState } from "react";
import { Layout } from "../../components/Layout/Layout";
import "./MyProjects.scss";
import FormInput from "../../components/input-form/FormInput";
import Button from "../../components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { projectsDetailList } from "../../redux/actions/projectDetailAction";
import { searchProjectIcon ,EducationIcon} from "../../utils/images";
import SCard from "../../components/smallCard/SCard";
import { useForm, Controller } from "react-hook-form";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

export default function MyProjects() {
  const dispatch = useDispatch();
  let filteredData;

  useEffect(() => {
    dispatch(projectsDetailList());
  }, []);

  const projectList = useSelector(
    ({ projectDetailList }) => projectDetailList.projectDetailList
  );

  const [search, setSearch] = useState("");
  const [projectData, setProjectData] = useState(projectList);
// console.log('projectData',projectData);
  const handleGitHub = (gitHubLink) => {
    window.open(gitHubLink.repository_url, "_blank");
  };

  const handleView = (viewLink) => {
    window.open(viewLink.project_url, "_blank");
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    if (e.target.value === "") {
      setProjectData(projectList);
    }
  };

  const onFilter = () => {
    const inputValue = search.toLowerCase();

    if (projectList) {
      filteredData = projectList.filter((item) => {
        const projTitle = (
          item.project_name ||
          item.project_technology ||
          ""
        ).toLowerCase();

        return projTitle.includes(inputValue);
      });
      setProjectData(filteredData);
    }
  };

  useEffect(() => {
    if (search === "") {
      setProjectData(projectList);
    } else {
      onFilter();
    }
  }, [search, projectList]);

  const truncateDescription = (text, wordLimit) => {
    if (text.length > wordLimit) {
      return text.slice(0, wordLimit)+ '...';
    }
    return text;
  };
  
  return (
    <>
      <Layout header={"My Projects"}>
        <div className="searchCont MyProjectsSearch form-section">
          <input
            name="search"
            value={search}
            placeholder="Search by name"
            onChange={handleChange}
            className="textBox"
          />
          <Button className="btnSearch" function={onFilter}>
            <img src={searchProjectIcon} alt="searchIcon" />
          </Button>
        </div>
        <div className={`grid_column ${projectData.length !== 0 ? 'my_project_lists':''}`}>
        {projectData.length === 0 ? (
                <div className='emty-Layout rewards'>
                <img src={EducationIcon} alt="bg-image" />
                <p className='emty-Layout-content'>There is no projects has been done</p>
                </div>
              ) : (
                projectData.map((item) => (
                  <SCard
                  className ={'my_project'}
                    type="project"
                    description={
                      <Tippy content={item.description} maxWidth="none">
                        <span>{truncateDescription(item.description, 20)}</span>
                      </Tippy>
                    }
                    image={item.image}
                    name={item.project_title}
                    duration={item.project_completed_duration}
                    tech={item.project_technology}
                    unique={item.project_id}
                    handleLeftBtn={() => handleGitHub(item)}
                    handleRightBtn={() => handleView(item)}
                  />
                ))
              )}
        </div>
      </Layout>
    </>
  );
}
