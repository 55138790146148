import { ActionTypes } from "../constants/action-types";

const initialState = {
  productiveRateList: [],
  loading: false,
  error: null,
};

export const productiveRateListReducer = (
  state = initialState,
  { type, payload, error }
) => {
  switch (type) {
    case ActionTypes.PRODUCTIVE_RATE_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ActionTypes.PRODUCTIVE_RATE_DATA:
      return {
        ...state,
        loading: false,
        productiveRateList: payload,
      };

    case ActionTypes.PRODUCTIVE_RATE_ERROR:
      return {
        ...state,
        loading: false,
        error: error,
        productiveRateList: [],
      };

    default:
      return state;
  }
};
