import * as React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { PieChart } from '@mui/x-charts/PieChart';

const data1 = [
  { label: 'Productive Effort', value: 0, color: '#3282C4' }, // Blue
  { label: 'Technical Issue', value: 0, color: '#6AAA43' }, // Green
  { label: 'Leave', value: 0, color: '#FF6636' }, // Orange
];

// const series = [
//   {
//     innerRadius: 90, 
//     outerRadius: 120, 
//     id: 'series-1',
//     data: data1,
//   },
// ];

export default function PieClickNoSnap( dataVal ) {
console.log('-----data 1 ----', data1);

console.log('-----data 2 ----', dataVal.data);
    const series = [
        {
          innerRadius: 90, 
          outerRadius: 120, 
          id: 'series-1',
          data: dataVal?.data?.[0] || data1,
        },
      ];

  const [itemData, setItemData] = React.useState();

  return (
    <Stack
      className={'productivity-chart'}
      direction={{ xs: 'column', md: 'row' }}
      spacing={{ xs: 0, md: 4 }}
      sx={{ width: '100%' }}
    >
      <Box className={'rate-chart'} sx={{ flexGrow: 1, position: 'relative', textAlign: 'center' }}>
        <PieChart
        className='pie_chart'
          series={series}
          width={400}
          height={300}
          slotProps={{
            legend: { hidden: true },
          }}
          onItemClick={(event, d) => setItemData(d)}
        />

        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
          }}
        >
          <Typography className='rate_percentage' variant="h4" component="div" sx={{ fontWeight: 'bold' }}>
          {dataVal?.data?.[1]?.value ?? 0}%
          </Typography>
          <Typography className='rate_percentage' variant="body1" component="div">
            Productivity rate
          </Typography>
        </Box>
      </Box>
      <div className='productivity_rate_chart'>
        <li className='rate_li'>Productivity rate </li>
        <li className='issue_li'>Technical issue</li>
        <li className='leave_li'>Leave</li>
      </div>
    </Stack>
  );
}
