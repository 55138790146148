import apiURL from "../../apis/apiURL";
import { notify } from "../../components/Layout/Layout";
import { ActionTypes } from '../constants/action-types';
import { loader } from "./loginAuthActions";

export const experiencesList = () => async (dispatch) => {
 dispatch(loader(true))
  // dispatch({ type: ActionTypes.EXPERIENCES_PENDING, payload: responseData.data });
    try {
      const response = await apiURL.get("/experiences");
      const responseData = response.data;
      console.log(responseData, "EXPERIENCES");
      if (responseData.status) {
        dispatch({ type: ActionTypes.EXPERIENCES_DATA, payload: responseData.data });
      } else {
        // Handle unsuccessful profile fetch
        dispatch({ type: ActionTypes.EXPERIENCES_ERROR, payload: responseData.data });
      }
     dispatch(loader(false))
    } catch (error) {
      console.error('Error in EXPERIENCES:', error);
      // dispatch({ type: ActionTypes.EXPERIENCES_ERROR, payload: responseData.data });
      dispatch(loader(false))

    }
  };

  export const experiencesListEdit = (data) => async (dispatch) => {
    const id = data.id;

   
    const editData = {
      company_name: data.company_name,
      description: data.description,
      designation: data.designation,
      job_type: data.job_type,
      join_date: data.join_date,
      location: data.location,
      present: data.present,
      reliving_date: data.reliving_date,

      ...(data.certificates.name && { certificates: data.certificates })
     
    };
    console.log(editData ,"dispatch data");
  
      dispatch(loader(true))
    try {
      const response = await apiURL.post(`/experiences/${id}`, editData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
       const responseData = response.data;
      if (responseData.status) {
        console.log(responseData.status);
        dispatch(experiencesList());
        notify("success", "Education has been Updated Successfully");
      } else {
        notify("error", "Unsuccessful");
        dispatch({ type: ActionTypes.EXPERIENCES_ERROR, payload: responseData.data });
      }
        dispatch(loader(false))
    } catch (error) {
      console.error("Error in educations:", error);
      // dispatch({ type: ActionTypes.EXPERIENCES_ERROR, payload: responseData.data });
        dispatch(loader(false))
      notify("error", "Unsuccessful");
    }
  };
  
  export const experiencesListDelete = (data) => async (dispatch) => {
    
      dispatch(loader(true))
  
    try {
      const response = await apiURL.delete(`/experiences/${data}`);
      const responseData = response.data;
      if (responseData.status) {
        dispatch(experiencesList());
        notify("error", "Education has been deleted");
      } else {
        notify("error", "Unsuccessful");
      }
        dispatch(loader(false))
    } catch (error) {
      notify("error", "Unsuccessful");
        dispatch(loader(false))
    }
  };
  
  export const experiencesListCreate = (data) => async (dispatch) => {
      dispatch(loader(true))
  
    const editData = {
      company_name: data.company_name,
      description: data.description,
      designation: data.designation,
      job_type: data.job_type,
      join_date: data.join_date,
      location: data.location,
      present: data.present,
      reliving_date: data.reliving_date,
      ...(data.certificates && { certificates: data.certificates })
     
    };
    console.log(editData , "create data");
  
    try {
      const response = await apiURL.post(`/experiences`, editData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept": "application/json"
        },
      });
       const responseData = response.data;
      if (responseData.status) {
        console.log(responseData.status);
        dispatch(experiencesList());
        notify("success", "Education has been Updated Successfully");
      } else {
        notify("error", "Unsuccessful");
        console.log(responseData , "Unsuccessful");
      }
        dispatch(loader(false))
    } catch (error) {
        dispatch(loader(false))
      notify("error", "Unsuccessful");
      console.log(error, "error");
    }
    dispatch(loader(false))

    }
  