import React, { useEffect, useRef } from "react";
import { Layout } from "../../components/Layout/Layout";
import "./MyLearnings.scss";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { SubTitele } from "../../components/subTitele/SubTitele";
import FormInput from "../../components/input-form/FormInput";
import { Controller, useForm } from "react-hook-form";
import { ProfileIcon, uploadImg } from "../../utils/images";
import Button from "../../components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { stageStatusUpdate } from "../../redux/actions/courseAction";
import { ProjectListCreate } from "../../redux/actions/projectDetailAction";
import { projectsList } from "../../redux/actions/projectAction";
import { useNavigate } from "react-router-dom";

export default function ProjectChallenge() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(projectsList());
  }, []);
  const projects = useSelector((state) => state.projectsList?.projects);
  console.log("projects", projects);

  const projectChallenge = useSelector(
    ({ courseList }) => courseList.projectChallenges
  );
  console.log("pj", projectChallenge);

  const filteredProjects = projects['studentProjectDetails']
  ? projects['studentProjectDetails'].filter(project => 
      project.project_id === projectChallenge.id && project.user_id === projectChallenge.user_id
    )
  : [];
  const filteredProject = filteredProjects.length > 0 ? filteredProjects[0] : [];
  console.log("filtereProject", filteredProject);

  const form = useForm({
    mode: "all",
  });
  const { register, control, handleSubmit, formState, setValue, watch } = form;
  const { errors } = formState;
  const file = watch("image");
  const fileInputRef = useRef(null);
  const onEditClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    dispatch(projectsList);
    setValue("project_technology", projectChallenge.technology);
    setValue("project_name", projectChallenge.name);
    // setValue("project_duration", projectChallenge.duration);
    setValue("project_id", projectChallenge.id);
  }, []);

  const onSubmit = async (data) => {
    dispatch(ProjectListCreate(data, projectChallenge));
    // dispatch(post(data, projectChallenge));
    // dispatch(stageStatusUpdate(projectChallenge));
    console.log("proj_formdata", data);
    navigate(-1);
  };
  
  const back = ()=>{
    navigate(-1);
  }
  return (
    <>
      <Layout header={"My Learnings"}>
        <Breadcrumbs
          paths={[
            { label: "My Courses", url: "/my-learnings" },
            { label: "Web Development", url: "/my-learnings/course-detail" },
            {
              label: "Project Challenge",
              url: "/my-learnings/project-challenge",
            },
          ]}
        />

        <div className="profile-section">
          <SubTitele>Project : {projectChallenge.name}</SubTitele>
          <h4>Requirements :</h4>
          <p>{projectChallenge.description}</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formsections">
              <FormInput
                label={"Project Duration (in Days):"}
                type="number"
                // type={number}
                // placeholder={"Enter how many days spend on project in days"}
                // errorMSG={errors.project_duration?.message}
                // required={{
                //   ...register("project_duration", {
                //     required: "Please Enter Project Duration",
                //   }),
                // }}
                value={projectChallenge.duration}
                disabledInput={true}
              />
              <FormInput
                label={"Project Title"}
                type="text"
                placeholder={"Enter Project Title"}
                errorMSG={errors.title?.message}
                value={filteredProject ? filteredProject.title:''}
                required={{
                  ...register("title", {
                    required: "Please Enter Project Title",
                  }),
                }}
              />
              <FormInput
                label={"Completed Duration (in Days):"}
                type="number"
                placeholder={"Enter how many days spend on project in days"}
                errorMSG={errors.duration?.message}
                value={filteredProject ? filteredProject.duration:''}
                required={{
                  ...register("duration", {
                    required: "Please Enter Project Duration",
                  }),
                }}
              />
              <FormInput
                label={"Hosted Link :"}
                placeholder={"Enter Host link"}
                errorMSG={errors.project_url?.message}
                value={filteredProject ? filteredProject.project_url : ''}
                required={{
                  ...register("project_url", {
                    required: "Please Enter Host link",
                  }),
                }}
              />

              <FormInput
                label={"GitHub Link :"}
                placeholder={"Enter GitHub link"}
                errorMSG={errors.repository_url?.message}
                value={filteredProject ? filteredProject.repository_url :''}
                required={{
                  ...register("repository_url", {
                    required: "Please Enter GitHub link",
                  }),
                }}
              />

              <FormInput
                label={"Description :"}
                placeholder={"Description"}
                textarea={true}
                errorMSG={errors.description?.message}
                value={filteredProject ? filteredProject.description :''}
                required={{
                  ...register("description", {
                    required: "Please Enter Description",
                  }),
                }}
              />
        {filteredProject.image  ? (
          <div className="projectThumbnail">
            <img src={`${projects['img_path']}/${filteredProject.image}`} alt="Project Thumbnail" />
          </div>
        ):(
          <div className="form-section">
          <label>Thumbnail :</label>
          <div className="thumbnail-img " onClick={onEditClick}>
            <Controller
              name="image"
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <input
                  className="thumbnail-input"
                  type="file"
                  ref={fileInputRef}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setValue("image", file);
                  }}
                />
              )}
            />
            {file ? (
              <img
                className="thum"
                src={URL.createObjectURL(file)}
                alt=""
              />
            ) : (
              <div className="uploadSection">
                  <img src={uploadImg} alt="" />
                  <p>Upload image</p>
              </div>
            )}
          </div>
        </div>
        )}
             
            </div>
            {
              filteredProject.length <= 0?
            <Button type={"submit"}>Submit Project</Button>:<button className="button" onClick={back} type={"button"}>Back</button>
            }
          </form>
        </div>
      </Layout>
    </>
  );
}
