export const ActionTypes = {
  LOGIN_DATA: "LOGIN_DATA",
  LOGIN_ERROR: "LOGIN_ERROR",
  LOGIN_PENDING: "LOGIN_PENDING",


  LOADER: "LOADER",

  TECHNOLOGIES_LIST: "TECHNOLOGIES_LIST",

  PROFILE_DATA: "PROFILE_DATA",
  PROFILE_ERROR: "PROFILE_ERROR",
  PROFILE_PENDING: "PROFILE_PENDING",

  EDUCATION_DATA: "EDUCATION_DATA",
  EDUCATION_ERROR: "EDUCATION_ERROR",
  EDUCATION_PENDING: "EDUCATION_PENDING",

  EXPERIENCES_DATA: "EXPERIENCES_DATA",
  EXPERIENCES_ERROR: "EXPERIENCES_ERROR",
  EXPERIENCES_PENDING: "EXPERIENCES_PENDING",

  CERTIFICATES_DATA: "CERTIFICATES_DATA",
  CERTIFICATES_ERROR: "CERTIFICATES_ERROR",
  CERTIFICATES_PENDING: "CERTIFICATES_PENDING",

  PROJECTS_DATA: "PROJECTS_DATA",
  PROJECTS_ERROR: "PROJECTS_ERROR",
  PROJECTS_PENDING: "PROJECTS_PENDING",

  SKILLS_DATA: "SKILLS_DATA",
  SKILLS_ERROR: "SKILLS_ERROR",
  SKILLS_PENDING: "SKILLS_PENDING",

  COURSE_DATA: "COURSE_DATA",
  COURSE_ERROR: "COURSE_ERROR",
  COURSE_PENDING: "COURSE_PENDING",

  COURSE_STAGES_DATA: "COURSE_STAGES_DATA",
  COURSE_STAGES_ERROR: "COURSE_STAGES_ERROR",
  COURSE_STAGES_PENDING: "COURSE_STAGES_PENDING",

  COURSE_STAGES_ID: "COURSE_STAGES_ID",

  LEARNING_STATUS_SUCCESS: "LEARNING_STATUS_SUCCESS",

  LEARNING_STATUS_UPDATE: "LEARNING_STATUS_UPDATE",

  PROJECT_DETAIL_DATA: "PROJECT_DETAIL_DATA",
  PROJECT_DETAIL_ERROR: "PROJECT_DETAIL_ERROR",
  PROJECT_DETAIL_PENDING: " PROJECT_DETAIL_PENDING",

  REWARD_DATA_CERTIFICATE: "REWARD_DATA_CERTIFICATE",
  REWARD_DATA_REWARD: "REWARD_DATA_REWARD",
  REWARD_DATA_ERROR: "REWARD_DATA_ERROR",
  REWARD_DATA_PENDING: "REWARD_DATA_PENDING",

  TIMESHEET_DATA: "TIMESHEET_DATA",
  TIMESHEET_ERROR: "TIMESHEET_ERROR",
  TIMESHEET_PENDING: "TIMESHEET_PENDING",

  PRODUCTIVE_RATE_DATA: "PRODUCTIVE_RATE_DATA",
  PRODUCTIVE_RATE_ERROR: "PRODUCTIVE_RATE_ERROR",
  PRODUCTIVE_RATE_PENDING: "PRODUCTIVE_RATE_PENDING",

  PROJECT_CHALLENGES: "PROJECT_CHALLENGES",


  LANGUAGES_PENDING: "LANGUAGES_PENDING",
  LANGUAGES_DATA: "LANGUAGES_DATA",

  LANGUAGES_ERROR: "LANGUAGES_ERROR",

  REQUESTED_INTERVIEW_DATA: "REQUESTED_INTERVIEWS_DATA",
  REQUESTED_INTERVIEW_ERROR: "REQUESTED_INTERVIEWS_ERROR",
  REQUESTED_INTERVIEW_PENDING: "REQUESTED_INTERVIEWS_PENDING",


  ACHIEVED_INTERVIEW_DATA: "ACHIEVED_INTERVIEWS_DATA",
  ACHIEVED_INTERVIEW_ERROR: "ACHIEVED_INTERVIEWS_ERROR",
  ACHIEVED_INTERVIEW_PENDING: "ACHIEVED_INTERVIEWS_PENDING",

  PRODUCTIVE_CHART: "PRODUCTIVE_CHART",
  PRODUCTIVE_PIE_CHART: "PRODUCTIVE_PIE_CHART",

  
  NOTIFICATION_LIST: "NOTIFICATION_LIST",
  NOTIFICATION_CLEAR: "NOTIFICATION_CLEAR",

};
