import { ActionTypes } from "../constants/action-types";

const initialState = {
  interviewRequestList: [],
  interviewAchievedList: [],
  loading: false,
  error: null,
};

console.log(
  "in reducer",
  initialState.interviewRequestList,
  initialState.interviewAchievedList
);

export const interviewListReducer = (
  state = initialState,
  { type, payload, error }
) => {
  switch (type) {
    case ActionTypes.REQUESTED_INTERVIEW_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ActionTypes.REQUESTED_INTERVIEW_DATA:
      return {
        ...state,
        loading: false,
        interviewRequestList: payload,
      };

    case ActionTypes.REQUESTED_INTERVIEW_ERROR:
      return {
        ...state,
        loading: false,
        error: error,
        interviewRequestList: [],
      };

    case ActionTypes.ACHIEVED_INTERVIEW_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ActionTypes.ACHIEVED_INTERVIEW_DATA:
      return {
        ...state,
        loading: false,
        interviewAchievedList: payload,
      };

    case ActionTypes.ACHIEVED_INTERVIEW_ERROR:
      return {
        ...state,
        loading: false,
        error: error,
        interviewAchievedList: [],
      };

    default:
      return state;
  }
};
