import React from "react";
import Button from "../button/Button";
import { SubTitele } from "../subTitele/SubTitele";
import "./ProfileTable.scss";
import { DeleteIcon, EditBIcon, OpenEye, pdfIcon } from "../../utils/images";

export default function ProfileTable({
  tabelHeaders,
  values,
  subTitele,
  openPopup,
  openEditPopup,
  deleteItem,
  viewItem,
  msg
}) {
  console.log(values, "in table");
  const dataWithHours = values?.map((item) => {
    // const startTime = new Date(`1970-01-01T${item.start}`);
    // const endTime = new Date(`1970-01-01T${item.end}`);
    // const hoursDifference = (startTime - endTime) / (1000 * 60 * 60);
    return {
      ...item,
      typeText :item.typeText ? item.typeText : item.type,
      hours: item.hours,
      days: item.type === "Productivity" ? item.value +'%' : item.days 
    };
  });
  if (dataWithHours?.length <= 0) {
    return (
      <>
      <div className="tabel-header">
        <SubTitele>{subTitele}</SubTitele>

        <Button functions={openPopup}>+ Add {subTitele}</Button>
      </div>
      <p className="null_message">
      {msg}
      </p>
      </>
    )
  }

  return (
    <>
      <div className="tabel-header">
        <SubTitele>{subTitele}</SubTitele>

        <Button functions={openPopup}>+ Add {subTitele}</Button>
      </div>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              {tabelHeaders?.map((header, index) => {
                return <th key={index}>{header.tabelHeader}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {dataWithHours?.map((item, index) => (
              <tr key={index} className={`${item.typeText === 'Productivity' ? 'productivity': ''}`}>
                {tabelHeaders.map((header, indexs) => (
                  <td key={indexs} className={`${item.typeText === 'Grand Total' ? 'grandTotal': ''}`}>
                    {header.datavalue === "certificates_url" ||
                    header.datavalue === "actions" ||
                    header.datavalue === "#" ? (
                      <>
                        {header.datavalue === "certificates_url" ? (
                          <>
                            {item[header.datavalue] !== "" ? (
                              <a
                                href={item[header.datavalue]}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img src={pdfIcon} alt="" />
                              </a>
                            ) : (
                              "-"
                            )}
                          </>
                        ) : header.datavalue === "actions" ? (
                          <>
                            {subTitele === "Experience" && (
                              <span onClick={() => viewItem(item)}>
                                {" "}
                                <img src={OpenEye} alt="" class="cursor"/>
                              </span>
                            )}{" "}
                            <span onClick={() => openEditPopup(item)}>
                              {" "}
                              <img src={EditBIcon} alt="" class="cursor"/>
                            </span>
                            <span onClick={() => deleteItem(item.id)}>
                              {" "}
                              <img src={DeleteIcon} alt="" class="cursor"/>
                            </span>
                          </>
                        ) : (
                          index + 1
                        )}
                      </>
                    ) : item[header.datavalue] === null ? (
                      "-"
                    ) : header.datavalue === "type" ? (
                      item.typeText
                    ): header.datavalue === "hours" ? (
                      item.hours 
                    ) : header.datavalue === "days" ? (
                      item.days 
                    ) : (
                      item[header.datavalue]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <Pagnation itemsPerPage={4} /> */}
    </>
  );
}
