import React, { useEffect, useState } from "react";
import { Layout } from "../../components/Layout/Layout";
import ProfileLayout from "../../components/Profilelayout/ProfileLayout";
import { EducationIcon, closeIcon } from "../../utils/images";
import ProfileTable from "../../components/ProfileTable/ProfileTable";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../../components/popup/Popup";
import FormInput from "../../components/input-form/FormInput";
import { Controller, useForm } from "react-hook-form";
import Button from "../../components/button/Button";
import {
  certificatesList,
  certificatesListCreate,
  certificatesListDelete,
  certificatesListEdit,
} from "../../redux/actions/certificatesAction";
import FileUploads from "../../components/input-form/FileUpload";

export default function Certificate() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [editValue, setEditValue] = useState({});
  console.log(editValue);
  const dispatch = useDispatch();

  const certificatesLists = useSelector((state) => state.certificatesList);

  useEffect(() => {
    dispatch(certificatesList());
  }, []);

  function editFunction(item) {
    setEditValue(item);
    setIsModalOpen(true);
  }

  function deleteItem(item) {
    dispatch(certificatesListDelete(item));
  }

  const tabelHeaders = [
    {
      id: 1,
      tabelHeader: "#",
      datavalue: "#",
    },
    {
      id: 2,
      tabelHeader: "Title",
      datavalue: "title",
    },
    {
      id: 3,
      tabelHeader: "Credential ID",
      datavalue: "credential_id",
    },
    {
      id: 4,
      tabelHeader: "Certificate Link",
      datavalue: "link",
    },
    {
      id: 5,
      tabelHeader: "Issue Date",
      datavalue: "issue_date",
    },
    {
      id: 5,
      tabelHeader: "Expiry Date",
      datavalue: "expiry_date",
    },
    {
      id: 8,
      tabelHeader: "Certificates",
      datavalue: "certificates_url",
    },
    {
      id: 9,
      tabelHeader: "Actions",
      datavalue: "actions",
    },
  ];
  const form = useForm({
    defaultValues: editValue,
    mode: "all",
  });

  useEffect(() => {
    form.reset(editValue);
  }, [editValue, form]);
  const { register, control, handleSubmit, formState, setValue, watch } = form;
  const { errors } = formState;

  const onSubmit = (data) => {
    createNew
      ? dispatch(certificatesListCreate(data))
      : dispatch(certificatesListEdit(data));
    setIsModalOpen(false);
    setCreateNew(false);
  };

  const newUser = () => {
    setIsModalOpen(true);
    setEditValue({
      certificates: null,
    });
    setCreateNew(true);
  };
  function closePopup() {
    setIsModalOpen(false);
    setCreateNew(false);
  }

  return (
    <>
      <Layout header={"My Profile"}>
        <div className="profile-section">
          {!certificatesLists?.certificates.length == 0 ? (
            <ProfileTable
              tabelHeaders={tabelHeaders}
              values={certificatesLists?.certificates}
              subTitele={"Certificate"}
              openPopup={newUser}
              openEditPopup={editFunction}
              deleteItem={deleteItem}
            />
          ) : (
            <ProfileLayout
              content={"No Educational records found"}
              img={EducationIcon}
              buttonContent={"+ Add Certificate"}
              openPopup={newUser}
            />
          )}

          <Popup
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
          >
            <div className="popup_header">
              <h3>{createNew ? "Add Certificate" : "Edit Certificate"}</h3>
              <img onClick={closePopup} src={closeIcon} alt="" />
            </div>

            <form className="form_field" onSubmit={handleSubmit(onSubmit)}>
              <div className="form_section">
                <FormInput
                  label={"Certificate Title"}
                  placeholder={"Enter Certificate Title"}
                  errorMSG={errors.title?.message}
                  required={{
                    ...register("title", {
                      required: "Title is required",
                    }),
                  }}
                  // value={watch("college_name")}
                />

                <FormInput
                  label={"Credential ID"}
                  placeholder={"Enter Credential ID"}
                  errorMSG={errors.credential_id?.message}
                  required={{
                    ...register("credential_id", {
                      required: "Credential id is required",
                    }),
                  }}
                />
                <FormInput
                  label={"Certification Link"}
                  placeholder={"Enter Certification Link"}
                  errorMSG={errors.link?.message}
                  required={{
                    ...register("link", {
                      required: "Certification Link is required",
                    }),
                  }}
                />

                <div className="inner_section">
                  <FormInput
                    label={"Issue Date"}
                    type={"date"}
                    // placeholder={"Date of Joining"}
                    errorMSG={errors.issue_date?.message}
                    required={{
                      ...register("issue_date", {
                        required: "Date of Joining is required",
                        validate: {
                          matchPattern: (v) =>
                            /(?:(?:19|20)[0-9]{2})/.test(v) ||
                            "please enter valid start year",
                        },
                      }),
                    }}
                  />
                  <FormInput
                    label={"Expiry Date"}
                    // placeholder={"Date of Relieving"}
                    type={"date"}
                    errorMSG={errors.expiry_date?.message}
                    required={{
                      ...register("expiry_date", {
                        required: "Date of Relieving is required",
                        validate: {
                          matchPattern: (v) =>
                            /(?:(?:19|20)[0-9]{2})/.test(v) ||
                            "please enter valid End year",
                        },
                      }),
                    }}
                  />
                </div>
              
                <FileUploads label={"Certificates"} name="certificates" control={control} setValue={setValue} />
              </div>
              <div className=" button_group">
                <Button type="button" functions={closePopup} outline={true}>
                  Close
                </Button>
                <Button type="submit" functions={() => setIsModalOpen(true)}>
                  {createNew ? "Add Certificate" : "Save"}
                </Button>
              </div>
            </form>
          </Popup>
        </div>
      </Layout>
    </>
  );
}
