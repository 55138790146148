import { ActionTypes } from "../constants/action-types";

const initialState = {
  courseStage: [],
  courseId: 1,
  loading: false,
  error: null,
};

export const courseStageReducer = (
  state = initialState,
  { type, payload, error, courseId, learningId, learningStatus }
) => {
  switch (type) {
    case ActionTypes.COURSE_STAGES_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ActionTypes.COURSE_STAGES_DATA:
      console.log("in stages", state.courseStage, payload);
      return {
        ...state,
        loading: false,
        courseStage: payload,
      };

    case ActionTypes.COURSE_STAGES_ERROR:
      return {
        ...state,
        loading: false,
        error: error,
        courseStage: [],
      };

    case ActionTypes.COURSE_STAGES_ID:
      return {
        ...state,
        loading: false,
        courseId: courseId,
      };

    case ActionTypes.LEARNING_STATUS_SUCCESS:
        const updatedData = state.courseStage
        console.log('update in reducer', updatedData)
        // return{

        // }  


    // case ActionTypes.LEARNING_STATUS_UPDATE:
      // Update the status of a specific learning material within a technology stack
    //   const db = state
    //   console.log(db)
      return {
        ...state,
        // console.log(object)
        // courseStage: state.courseStage.map((techStack) => {
        //   if (techStack.technologyId === courseId) {
        //     return {
        //       ...techStack,
        //       stages: techStack.stages.map((stage) => {
        //         return {
        //           ...stage,
        //           learnings: stage.learnings.map((learning) => {
        //             if (learning.id === learningId) {
        //               return {
        //                 ...learning,
        //                 status: learningStatus,
        //               };
        //             }
        //             return learning;
        //           }),
        //         };
        //       }),
        //     };
        //   }
        //   return techStack;
        // }),
      };
      
    default:
      return state;
  }
};
