import React from "react";
import { Controller } from "react-hook-form";

const FileUploads = ({ label, name, control, setValue, disabledInput, errorMSG, register  }) => {
  return (
    <div key={name} className="form-section">
      <label>{label}</label>
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        rules={register}
        render={({ field }) => (
          <input
            className="fileInput"
            type="file"
            accept=".pdf"
            onChange={(e) => {
              const file = e.target.files[0];
              field.onChange(file);
              setValue(name, file);
            }}
            disabled={disabledInput}
            />
            )}
            />
            {errorMSG && <span className="errorMSG">{errorMSG}</span>}
      
    </div>
  );
};

export default FileUploads;
