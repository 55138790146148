import { ActionTypes } from "../constants/action-types";


const initialState={
    skills:[],
    loading:false,
    error:null
    
};


export const    skillsReducer = (state = initialState, {type, payload,error}) =>{
    switch (type) {
        case ActionTypes.SKILLS_PENDING:
            return {
                ...state, 
                loading:true,
                error:null
            };

            case ActionTypes.SKILLS_DATA:
                return {
                    ...state, 
                    loading:false,
                    skills:payload
                };
                
            case ActionTypes.SKILLS_ERROR:
                return {
                    ...state, 
                    loading:false,
                    error:error,
                    skills:[]
                };
        
        default:
            return state;
    }   

}; 



