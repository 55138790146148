import React from "react";
import "./Button.scss";

export default function Button(props) {
  const { fill, type, functions, disabledButton, className, outline ,color } = props;
  return (
    <>
      <button
        type={type ? type : "button"}
        className={`${
          fill ? "full-width" : outline ? "outline" : ""
        } ${className} ${disabledButton ? "buttons" : (color == 'green') ? "buttonss" : "button"}`}
        onClick={functions}
        disabled={disabledButton}
        style={{ cursor : 'pointer' }}>
        {props.children}
      </button>
    </>
  );
}
