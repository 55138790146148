import React from 'react'
import "./Progress.scss";

function Progress(props) {
    const {name, value } = props

  return (
    <div className="Progress">
      <div className="Progress_header">
        <h1>
          {name} <span>{value}%</span>
        </h1>
      </div>
      <div className="Progress_bar">
        <div className="Progress_bar_value" style={{ "--value": `${value}%` }}></div>
      </div>
    </div>
  );
}

export default Progress
