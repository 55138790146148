import React from 'react'
import "./Breadcrumbs.scss"
import { SliderArrow } from '../../utils/images';
import { Link } from 'react-router-dom';

export default function Breadcrumbs({ paths }) {
  return (
    <div className='Breadcrumbs'>
      {paths.map((path, index) => (
        <span key={index}>
          <Link to={path.url}>{path.label}</Link>
          {index < paths.length - 1 && <span className='arrow'><img src={SliderArrow} alt="" /></span>}
        </span>
      ))}
    </div>
  );
}
