import apiURL from "../../apis/apiURL";
import { notify } from "../../components/Layout/Layout";
import { ActionTypes } from '../constants/action-types';
import { loader } from "./loginAuthActions";

export const projectsList = () => async (dispatch) => {
  dispatch(loader(true))
    try {
      const response = await apiURL.get("/projectdetails");
      const responseData = response.data;
      if (responseData.status) {
        dispatch({ type: ActionTypes.PROJECTS_DATA, payload: responseData.data });
      }
       dispatch(loader(false))

    } catch (error) {
      console.error('Error in projectdetails:', error);
       dispatch(loader(false))

    }
  };  

  
  export const projectsListEdit = (data) => async (dispatch) => {
    const id = data.id;
    // const editData = {
    //   title: data.title,
    //   credential_id: data.credential_id,
    //   link: data.link,
    //   issue_date: data.issue_date,
    //   expiry_date: data.expiry_date,
    //   // end_year: data.end_year,
    //   ...(data.certificates.name && { certificates: data.certificates })
     
    // };
  
      dispatch(loader(true))
    try {
      const response = await apiURL.post(`/projectdetails/${id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
       const responseData = response.data;
      if (responseData.status) {
        console.log(responseData.status);
        dispatch(extprojectsList());
        notify("success", "Project has been Updated Successfully");
      } else {
        notify("error", "Unsuccessful");
        dispatch({ type: ActionTypes.EDUCATION_ERROR });
      }
        dispatch(loader(false))
    } catch (error) {
      console.error("Error in project:", error);
        dispatch(loader(false))
      notify("error", "Unsuccessful");
    }
  };
  
  export const projectsListDelete = (data) => async (dispatch) => {
      dispatch(loader(true))
  
    try {
      const response = await apiURL.delete(`/projectdetails/${data}`);
      const responseData = response.data;
      if (responseData.status) {
        dispatch(extprojectsList());
        notify("error", "Project has been deleted");
      } else {
        notify("error", "Unsuccessful");
      }
        dispatch(loader(false))
    } catch (error) {
      notify("error", "Unsuccessful");
        dispatch(loader(false))
    }
  };
  
  export const projectsListCreate = (data) => async (dispatch) => {
      dispatch(loader(true))
  
    console.log(data, "data");
  
    try {
      const response = await apiURL.post(`/projectdetails`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
       const responseData = response.data;
      if (responseData.status) {
        console.log(responseData.status);
        dispatch(extprojectsList());
        notify("success", "Project has been Created Successfully");
      } else {
        notify("error", "Unsuccessful");
        console.log(responseData , "Unsuccessful");
      }
        dispatch(loader(false))
    } catch (error) {
        dispatch(loader(false))
      notify("error", "Unsuccessful");
      console.log(error, "error");
    }
  };

  export const extprojectsList = () => async (dispatch) => {
    dispatch(loader(true))
      try {
        const response = await apiURL.get("/externalprojectdetails");
        const responseData = response.data;
        if (responseData.status) {
          dispatch({ type: ActionTypes.PROJECTS_DATA, payload: responseData.data });
        } else {
          // Handle unsuccessful profile fetch
        }
         dispatch(loader(false))
  
      } catch (error) {
        console.error('Error in projectdetails:', error);
         dispatch(loader(false))
  
      }
    };