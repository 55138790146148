import { combineReducers } from "redux";
import { loginAuth ,profileData} from "./productReducers";
import { educationsReducer } from "./educationsReducers";
import { experiencesReducer } from "./experiencesReducers";
import { certificatesReducer } from "./certificatesReducers";
import { projectsReducer } from "./projectsReducers";
import { skillsReducer } from "./skillsReducers";
import { courseReducer } from "./courseReducer";
import { courseStageReducer } from "./courseStageReducer";
import { projectsListReducer } from "./projectListReducer";
import { rewardListReducer } from "./rewardReducer";
import { timeSheetListReducer } from "./TimeSheetReducer";
import { notificationListReducer } from "./NotificationReducer";
import { productiveRateListReducer } from "./ProductiveRateReducer";
import { languagesReducer } from "./languagesReducers";
import { interviewListReducer } from "./InterviewReducer";

  const reducers = combineReducers({
    
    loginAuth: loginAuth,
    profile: profileData,
    educationList: educationsReducer,
    experiencesList: experiencesReducer,
    certificatesList: certificatesReducer,
    projectsList: projectsReducer,
    skillsList: skillsReducer,
    courseList: courseReducer,
    courseStages: courseStageReducer,
    projectDetailList: projectsListReducer,
    rewardLists: rewardListReducer,
    timeSheetList: timeSheetListReducer,
    productiveRate: productiveRateListReducer,
    language: languagesReducer,
    interViewList:interviewListReducer,
    notificationList:notificationListReducer,
  });

export default reducers;    