import React,{ useState }  from "react";
import "./SideBar.scss";
import { useNavigate } from 'react-router-dom';
import {
  DashboardLogo,
  InterviewsIcon,
  MenuLogo,
  MyLearningsIcon,
  MyProgressIcon,
  MyProjectsIcon,
  RewardsIcon,
  dashboardIcon,
  logoutIcon,
  closeIcon,
  warningIcon
} from "../../utils/images";
import { Link, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useDispatch } from "react-redux";
import { logOut } from "../../redux/actions/loginAuthActions";
import Popup from "../../components/popup/Popup";

export default function SideBar({ active, profile }) {
  const dispatch = useDispatch();
  const [logoutPop, setLogoutPop] = useState(false);
  const pathname = useLocation().pathname;
  const navigate = useNavigate();

  const openLogoutPopup = () => {
    setLogoutPop(true);
  };
  const closePopup = () => {
    setLogoutPop(false);
  };
  const confirmLogout = () => {
    dispatch(logOut());
    setLogoutPop(false);
    navigate("/login"); 
  };
  

  const values = [
    {
      id: 1,
      img: dashboardIcon,
      list: "Dashboard",
      pathname: "/",
    },
    {
      id: 2,
      img: MyLearningsIcon,
      list: "My Learnings",
      pathname: "/my-learnings",
    },
    {
      id: 3,
      img: MyProgressIcon,
      list: "My Progress",
      pathname: "/my-progress",
    },
    {
      id: 4,
      img: MyProjectsIcon,
      list: "My Projects",
      pathname: "/my-projects",
    },
    {
      id: 5,
      img: RewardsIcon,
      list: "Rewards",
      pathname: "/rewards",
    },
    {
      id: 6,
      img: InterviewsIcon,
      list: "Interviews",
      pathname: "/interviews",
    },
  ];

  return (
    <div
      className={`side-bar ${active ? "active" : ""} ${
        profile ? "profile" : ""
      }`}>
      <div className="side-bar-logo">
        <img className="logo" src={DashboardLogo} alt="" />
        <img className="menulogo" src={MenuLogo} alt="" />
      </div>
      <ul className="menulist">
        {values?.map((value, i) => {
          return (
            <li
              key={i}
              className={`${
                pathname === value.pathname ? "active navitem" : "navitem"
              }`}>
              <Link key={value.id} to={value.pathname}>
                <ReactSVG className="menu-icon" src={value.img} />{" "}
                <span>{value.list}</span>
              </Link>
            </li>
          );
        })}
      </ul>

      <ul>
        <li className="navitem" onClick={openLogoutPopup}>
          <Link to="#">
            <ReactSVG className="menu-icon" src={logoutIcon} />{" "}
            <span>Logout</span>
          </Link>
        </li>
      </ul>
      {/* <Popup isOpen={logoutPop} >
 
              Yes, Logout
            </button>
            <button onClick={closePopup} className="cancel_btn">
              Cancel
            </button>
          </div>
        </div>
      </Popup> */}
          <Popup className={'logoutPop'} isOpen={logoutPop}
            >
              <div className="logoutClose">
                <img onClick={closePopup} src={closeIcon} alt="" />
              </div>
              <div className="logout">
                <div className="logoutImg">
                  <img src={warningIcon} alt="" />
                </div>
                
              <p className="logout_text">Are You Sure ?</p>
              <div className="logout_button">
                <button onClick={closePopup} className="logout_btn logout_cancel">No</button>
                <button onClick={confirmLogout} className="logout_btn logout_success">Yes</button>
              </div>
            </div>
          </Popup>
    </div>
    
  );
}
