import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const Provaterouter = () => {
    const product = useSelector((state) => state.loginAuth.products);
    const user = localStorage.getItem("token");
     
  const auth = user&&user ? true :false
  // console.log(auth);
    

  return (
    <div>
        {
        auth ? 
        <Outlet/>
        :
        <Navigate to="/login"/>
      }
    </div>
  )
}

export default Provaterouter




