import { ActionTypes } from "../constants/action-types";


const initialState={
    user:[],
    isLoader:false,
    error:null,
    profileData:[],
    TechnologiesList:[]
    
};

export const    loginAuth = (state = initialState, {type, payload,error}) =>{
    switch (type) {
      

            case ActionTypes.LOGIN_DATA:
                return {
                    ...state, 
                    user:payload
                };
                
            case ActionTypes.LOGIN_PENDING:
                return {
                    ...state, 
                    error:error,
                    user:[]
                };

                case ActionTypes.LOADER:
                    return {
                        isLoader:payload
                    }

                    case ActionTypes.TECHNOLOGIES_LIST:
                    return {
                        TechnologiesList:payload
                    }
        
        default:
            return state;
    }   

}; 






export const    profileData = (state = initialState, {type, payload,error}) =>{
   
    switch (type) {
        case ActionTypes.PROFILE_PENDING:
            return {
                ...state, 
                loading:true,
                error:null
            };

            case ActionTypes.PROFILE_DATA:
                return {
                    ...state, 
                    loading:false,
                    profileData:payload
                };
                
            // case ActionTypes.PROFILE_PENDING:
            case ActionTypes.PROFILE_ERROR:
                return {
                    ...state, 
                    loading:false,
                    error:error,
                    profileData:[]
                };
        
        default:
            return state;
    }   


}



