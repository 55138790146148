import React, { useEffect, useState } from "react";
import SideBar from "../../components/side-bar/SideBar";
import { Layout } from "../../components/Layout/Layout";
import "./MyLearnings.scss";
import SimpleSlider from "../../components/slider/SliderComponent";
import { SubTitele } from "../../components/subTitele/SubTitele";
import Progress from "../../components/progressBar/Progress";
import Button from "../../components/button/Button";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { courseLockIcon, lockFillIcon ,videoIcon,fileImage } from "../../utils/images";
import NoteMSG from "../../components/noteMSG/NoteMSG";
import { useDispatch, useSelector } from "react-redux";
import {
  courseList,
  courseStages,
  stageStatusUpdate,
} from "../../redux/actions/courseAction";
import { useNavigate } from "react-router-dom";
import { ActionTypes } from "../../redux/constants/action-types";
import { useLocation } from "react-router-dom";
import ReactPlayer from 'react-player'
import { notify } from "../../components/Layout/Layout";

export default function CourseDetail() {

  window.onload = function() {
    if (window.performance.navigation.type === 1) {
        window.location.href = "/my-learnings";
    }
}
  const location = useLocation();
  const currentPathname = location.pathname;
  const [activeList, setActiveList] = useState(0);
  const [lastText, setLastText] = useState("Move to next Stage");
  // const card = [
  //   {
  //     title: "Web Development",
  //     value: "20%",
  //     course: false,
  //     img: "",
  //   },
  //   {
  //     title: "PHP Developer",
  //     value: "50%",
  //     course: true,
  //     img: "",
  //   },
  //   {
  //     title: "Java Developer",
  //     value: "50%",
  //     course: false,
  //     img: "",
  //   },
  //   {
  //     title: "Web Development",
  //     value: "0%",
  //     course: false,
  //     img: "",
  //   },
  //   {
  //     title: "Android Developer",
  //     value: "80%",
  //     course: false,
  //     img: "",
  //   },
  //   {
  //     title: "UI Developer",
  //     value: "40%",
  //     course: true,
  //     img: "",
  //   },
  //   {
  //     title: "Dotnet Developer",
  //     value: "50%",
  //     course: true,
  //     img: "",
  //   },
  //   {
  //     title: "Web Development",
  //     value: "20%",
  //     course: false,
  //     img: "",
  //   },
  //   {
  //     title: "Web Development",
  //     value: "50%",
  //     course: false,
  //     img: "",
  //   },
  // ];
  // const list = [
  //   {
  //     title: "Web Development",
  //     value: "50%",
  //   },
  //   {
  //     title: "Web Development",
  //     value: "50%",
  //   },
  // ];

  const navigate = useNavigate();
  const idCourse = useSelector(({ courseStages }) => courseStages.courseId);
  // console.log('ids', idCourse)

  const dispatch = useDispatch();
  // useEffect(() => {
    // dispatch(courseList());
    // dispatch(courseStages(idCourse))

  // }, []);

  const dataStages = useSelector((state) => state.courseStages);
  const courseFirstStageId =  dataStages?.courseStage?.stages[0]?.id;
  const courseStage = dataStages?.courseStage?.stages
  console.log('d', courseStage)
  // const learnings = dataStages?.courseStage?.stages[activeList]?.learnings ;

  const [stageData, setStageData] = useState(
    dataStages?.courseStage?.stages[activeList || 0]?.learnings
  );
  console.log(dataStages.courseStage.stages,"first");
  useEffect(() => {
    setStageData(dataStages?.courseStage?.stages[activeList || 0]?.learnings);
    // console.log(stageData,"inside");
  }, [dataStages, activeList]);

  //  useEffect(() => {
  //    // if(idCourse === 1){
  //    navigate("/my-learnings");
  //    console.log("in");
  //    // }
  //  }, []);

  //  useEffect(() => {
  //    const handleBeforeUnload = (event) => {
  //     event.returnValue = navigate('/my-learnings')
  //    };

  //    window.addEventListener("beforeunload", handleBeforeUnload);
  //  }, []);
  const activeStage = (data) => {
    setActiveList(data);
  };

  const handleComplete = (statusItem) => {
    if (statusItem.type === "project") {
      dispatch({
        type: ActionTypes.PROJECT_CHALLENGES,
        projectChallenges: statusItem,
      });
      navigate("/my-learnings/project-challenge");
    } else {
      console.log("in complete", statusItem);
      dispatch(stageStatusUpdate(statusItem, dataStages));
      dispatch(courseStages(idCourse));
    }
  };

  useEffect(()=>{
    dispatch(courseStages(idCourse));
  },[])
  useEffect(() => {
    const StagesLength = dataStages.courseStage.stages.length;
    if (StagesLength - 1 === activeList) {
      setLastText("Complete Course");
    } else {
      setLastText("Move to next stage");
    }
  }, [activeList, dataStages.courseStage.stages]);

  const handleNextStage = () => {
    //  console.log("clicked")
    const StagesLength = dataStages.courseStage.stages.length;
    
    const percentage = dataStages.courseStage.stages[activeList].percentage
    console.log(dataStages.courseStage.stages[activeList],'StagesLengthStagesLength');
    const dataCheck = dataStages.courseStage.stages[activeList].learnings.every(
      (item) => item.status === 1
    );
    if (percentage > 95) {
      if (StagesLength - 1 === activeList) {
        completeCourse();
      } else {
        setActiveList((prevActiveList) => prevActiveList + 1);
      }
    } else {
      console.log("not complete", dataCheck, StagesLength);
      notify("info","Atleast you have to complete "+ dataStages.courseStage.stages[activeList].name +' stage above 95%')
    }
  };

  console.log('-->', stageData)
  const completeCourse = () => {
    console.log("on complete last");
  };
 
  return (
    <>
      <Layout
        header={"My Learnings"}
        list={dataStages.courseStage}
        activeStage={activeStage}>
        <div className="my_learnings">
          <Breadcrumbs
            paths={[
              { label: "My Courses", url: "/my-learnings" },
              // { label: 'Web Development', url: '/category' },
              // { label: 'Subcategory', url: '/category/subcategory' },
              { label: "Web Development", url: "/my-learnings/course-detail" },
            ]}
          />
          <NoteMSG>
            Note: At least four videos should be completed in a day (Goals vary
            based on the video duration or challenges)
          </NoteMSG>
          <div className="grid_colum">
            {stageData &&
              stageData.map((item, index) =>{
                const previousStageId = item.tech_stage_id - 1;
                const previousStage = courseStage.find(
                  (stage) => stage.id === previousStageId
                );
          
                // Check if the current tech_stage_id is not the first stage and the previous stage percentage is greater than 90
                const isNotFirstStage = item.tech_stage_id !== courseFirstStageId;

                // Only check percentage if the current stage is not the first stage
                const isPercentageValid = !isNotFirstStage || (previousStage?.percentage > 90);  

                return ((index && index === 0) || stageData[index - 1]?.status !== 0) ?( isPercentageValid ? (
                  <div key={index} className="my_learnings_card">
                    <div className="my_learnings_card_img">
                      {item.type === "project" ?
                      <img className="project_img" src={fileImage} alt="" />:
                      <ReactPlayer key={item.id} controls={true}  className="videoPlayer"  url={item['link-1']} />
                      }

                    </div>
                    <SubTitele>{item.name}</SubTitele>
                    <div className="">
                      <Button functions={() => handleComplete(item)} color={item.status ? 'green' : 'blue'}>
                        {item.type === "project"
                          ? item.status
                            ? "Completed"
                            : "View"
                          : item.status
                          ? "Completed"
                          : "Mark as Complete"}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div key={index} className="my_learnings_card courseLockIcon">
                    <div className="my_learnings_lock">
                      <div className="my_learnings_lock_icon">
                        <img src={lockFillIcon} alt="" />
                      </div>
                      <p>Complete Previous Video to Unlock</p>
                    </div>
                  </div>
                )
                ) : (
                  <div key={index} className="my_learnings_card courseLockIcon">
                    <div className="my_learnings_lock">
                      <div className="my_learnings_lock_icon">
                        <img src={lockFillIcon} alt="" />
                      </div>
                      <p>Complete Previous Video to Unlock</p>
                    </div>
                  </div>
                )
              }
              )}
          </div>
          <Button functions={() => handleNextStage()}>{lastText}</Button>
        </div>
      </Layout>
    </>
  );
}
