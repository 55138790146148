import React, { useEffect, useState } from "react";
import "./Layout.scss";
import SideBar from "../side-bar/SideBar";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ProfileMenu from "../ProfileMenu/ProfileMenu";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { profile } from "../../redux/actions/profileAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Logo } from "../../utils/images";
import StageLearn from "../stagLearn/StageLearn";

function Layout(props) {
  const dispatch = useDispatch();

  const [active, SetActive] = useState(false);
  const { header, list, activeStage } = props;
  const myProfile = header === "My Profile";


  const Active = () => {
    SetActive(!active);
    console.log(active);
  };
  useEffect(() => {
    dispatch(profile());
  }, []);

  return (
    <div className="main-section">
      <SideBar
        className="sid-basssr"
        active={active}
        profile={myProfile || list ? true : false}
      />
      <div
        className={`right-section ${myProfile || list ? "profilepage" : ""}`}>
        <Header header={header} active={Active} />
        {myProfile ? (
          <div className="inner-section profile-list">
            <ProfileMenu />
            <div className="profile-list-inner-section"> {props.children}</div>
          </div>
        ) : list ? (
          <div className="inner-section profile-list">
            <StageLearn list={list.stages} activeStage={activeStage} />
            <div className="profile-list-inner-section"> {props.children}</div>
          </div>
        ) : (
          <div className="inner-section">{props.children}</div>
        )}

        <Footer />
      </div>
    </div>
  );
}
function ToastProvider({ children }) {
  return (
    <>
      <ToastContainer />
      {children}
    </>
  );
}
function notify(type, message) {
  // {position: "bottom-center"}

  switch (type) {
    case "success":
      toast.success(message);
      break;
    case "info":
      toast.info(message);
      break;
    case "error":
      toast.error(message);
      break;
    default:
      toast(message);
  }
}

function Loader() {
  const isLoading = useSelector((state) => state.loginAuth.isLoader);
  // console.log(isLoading, "loader");

  return (
    <>
      {isLoading ? (
        <div className="loader">
          <div className="loader-img">
            <img src={Logo} alt="" />
          </div>
        </div>
      ) : null}
    </>
  );
}


function TabText({ classActive, text, activeFunction, states }) {
  // console.log(classActive, "class");

  return (
    <>
      <p
        className={`tabText ${states == classActive ? "active" : ""} cursor`}
        onClick={() => activeFunction(classActive)}
      >
        {text}
      </p>
    </>
  );
}
export { Layout, ToastProvider, notify ,Loader,TabText };

