import apiURL from "../../apis/apiURL";
import { notify } from "../../components/Layout/Layout";
import { ActionTypes } from "../constants/action-types";
import { loader } from "./loginAuthActions";

export const InterviewList = () => async (dispatch) => {
    dispatch(loader(true))
  try {
    const response = await apiURL.get("/interview");
    const responseData = response.data;
    console.log(
      "in action",
      responseData.requestInterviews,
      "achieved",
      responseData.data.archivedInterviews
    );
    if (responseData.status) {
      dispatch({
        type: ActionTypes.REQUESTED_INTERVIEW_DATA,
        payload: responseData.data.requestInterviews,
      });
      dispatch({
        type: ActionTypes.ACHIEVED_INTERVIEW_DATA,
        payload: responseData.data.archivedInterviews,
      });
      
      //   notify("successful", "Education has been deleted");
    } else {
      // Handle unsuccessful profile fetch
    }
      dispatch(loader(false))
  } catch (error) {
    console.error("Error in projectdetails:", error);
      dispatch(loader(false))
  }
};

export const InterviewCreate = (data) => async (dispatch) => {
  dispatch(loader(true));
  console.log(data, "data");
  try {
    const response = await apiURL.post(`/interview-request`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Accept": "application/json"
      },
    });
    const responseData = response.data;
    if (responseData.status) {
      console.log("in action", responseData);
      dispatch(InterviewList());
      //   notify("success", "Education has been Updated Successfully");
    } else {
      //   notify("error", "Unsuccessful");
      console.log(responseData, "Unsuccessful");
    }
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
    // notify("error", "Unsuccessful");
    console.log(error, "error");
  }
};
export const interviewCancel = (data) => async (dispatch) => {
  dispatch(loader(true));
  console.log(data, "data");
  try {
    const response = await apiURL.post(`interview-cancel/${data}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status) {
      dispatch(InterviewList());
      notify("success", "Interview cancelled Successfully");
    } else {
      notify("error", "Unsuccessful");
      // console.log(responseData, "Unsuccessful");
    }
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
    console.log(error, "error");
  }
};

// export const TimeSheetListDelete = (data) => async (dispatch) => {
//     dispatch(loader(true))

//   try {
//     const response = await apiURL.delete(`/timesheet/${data}`);
//     const responseData = response.data;
//     if (responseData.status) {
//       // dispatch({
//       //   type: ActionTypes.TIMESHEET_DATA,
//       //   payload: responseData.data,
//       // });
//       dispatch(TimeSheetList());
//       notify("error", "Education has been deleted");
//     } else {
//       //   notify("error", "Unsuccessful");
//     }
//       dispatch(loader(false))
//   } catch (error) {
//     // notify("error", "Unsuccessful");
//       dispatch(loader(false))
//   }
// };
