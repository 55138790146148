import apiURL from "../../apis/apiURL";
import { ActionTypes } from "../constants/action-types";
import { loader } from "./loginAuthActions";

export const rewardsActionList = () => async (dispatch) => {
  dispatch(loader(true));
  try {
    const response = await apiURL.get("/rewards");
    const responseData = response.data;
    if (responseData.status) {
    //   console.log("in ac", responseData.data);

      dispatch({
        type: ActionTypes.REWARD_DATA_CERTIFICATE,
        payload: responseData.data.certificates,
      });
       dispatch({
         type: ActionTypes.REWARD_DATA_REWARD,
         payload: responseData.data.rewards,
       });
    } else {
      // Handle unsuccessful profile fetch
    }
    dispatch(loader(false));
  } catch (error) {
    console.error("Error in projectdetails:", error);
    dispatch(loader(false));
  }
};
