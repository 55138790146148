
import apiURL from "../../apis/apiURL";
import { ActionTypes } from '../constants/action-types';
import { notify } from "../../components/Layout/Layout";

export const UseLogin  = (data, onSuccessCallback) => async (dispatch) => {
  dispatch(loader(true))
  try {
    const response = await apiURL.post("/login", data);
    const responseData = response.data;

    console.log(responseData, "api");

    if (responseData.status) {
  

      notify("success","successful login")
      dispatch({ type: ActionTypes.LOGIN_DATA, payload: responseData });
   

      // Store user token in session storage
      localStorage.setItem('token', responseData.token);
      localStorage.setItem('user', JSON.stringify(responseData));
      onSuccessCallback();
    } else {
      // Handle unsuccessful login
      // localStorage.removeItem('token');
      localStorage.removeItem('user');  
    }
    dispatch(loader(false))
  } catch (error) {
    dispatch(loader(false))


    console.error('Error in useLogin:', error);
    notify("error",error.response.data.message )
    localStorage.removeItem('token');
    // localStorage.removeItem('user');
  }
};







export const forgetPassword = (url,data, resetForm=null ) => async (dispatch) => {
  dispatch(loader(true))
  console.log(data ,"forgetPassword");
    try {
      const response = await apiURL.post(url, data);
      let responseData = response.data;
  
      console.log(responseData, "apiefffeffe");
      
      if (responseData) {
        console.log(responseData);
        notify("success",responseData.message)
        // localStorage.removeItem('token');
        // Call the onSuccessCallback function to trigger navigation
        // onSuccessCallback();
      }
      dispatch({ type: ActionTypes.LOADER, payload:false });
      resetForm();
    } catch (error) {
      console.error(error);
      let errorMessage = "An unexpected error occurred";
      if (error.response) {
          const { data } = error.response;
          // if (data && data.message) {
          //     errorMessage = data.message;
          //     notify("error", errorMessage);
          // }
          if (data.errors.email && data.errors.email.length > 0) {
            notify("error", data.errors.email[0]);
            console.log(data.errors.email);
        }
        if (data.errors.password && data.errors.password.length > 0) {
          notify("error", data.errors.password[0]);
          console.log(data.errors.password);
      }
            if (data && data.errors && data.errors.token && data.errors.token.length > 0) {
              notify("error", data.errors.token[0]);
              console.log(data.errors.token);
          }
      } else {
          notify("error", errorMessage);
      }
      dispatch({ type: ActionTypes.LOADER, payload: false });
  }
  
  };


  export const logOut = () => async () => {
    try {
      const response = await apiURL.post("/logout");
      let responseData = response.data;
  
      console.log(responseData, "apiefffeffe");
  
      if (responseData.status) {
        console.log(responseData , "logoutScreen");
        localStorage.removeItem('token');
      }
    } catch (error) {
      console.error(error,"logoutScreen");
      localStorage.removeItem('token');
    }
  };




  export const Register = (data, onSuccessfulRegistration) => async (dispatch) => {
    dispatch(loader(true)); // Show loader

    const formData = new FormData();
    Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
    });

    try {
        const response = await apiURL.post("/register", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        const responseData = response.data;

        if (responseData.status) {
            notify("success", "Registration successful");
            notify("info", "Please check your email for your login credentials.");
            onSuccessfulRegistration();
        }
    } catch (error) {
        const errorMessage = error.response?.data?.errors?.email?.[0] || 
                             error.response?.data?.errors?.linkedIn_id?.[0] || 
                             "An error occurred";
                             
        if (errorMessage === "The email has already been taken.") {
            notify("error", "The email has already been taken. Please use a different email.");
        } else {
            notify("error", errorMessage);
        }
    } finally {
        dispatch(loader(false)); // Hide loader after request completion
    }
};






  export const TechnologiesList  = () => async (dispatch) => {

    try {
      const response = await apiURL.get("/technologies");
      const responseData = response.data;
  
  
      if (responseData.status) {
        dispatch({ type: ActionTypes.TECHNOLOGIES_LIST, payload: responseData });
  console.log(responseData ,"TechnologiesList inside");
      } else {

      }
      // loader(false)
    } catch (error) {
      console.error('Error in useLogin:', error);
    }
  };

export const loader=(data)=>{
return (dispatch)=>{
  dispatch({ type: ActionTypes.LOADER, payload:data });
}
}

