import apiURL from "../../apis/apiURL";
import { ActionTypes } from "../constants/action-types";
import { loader } from "./loginAuthActions";

export const skillsList = () => async (dispatch) => {
    dispatch(loader(true))
  try {
    const response = await apiURL.get("/skills");
    const responseData = response.data;
    console.log("pending");
    if (responseData.status) {
      console.log("pending status");
      dispatch({ type: ActionTypes.SKILLS_DATA, payload: responseData.data });
    } else {
      // Handle unsuccessful profile fetch
    }
      dispatch(loader(false))
  } catch (error) {
    console.error("Error in skills:", error);
      dispatch(loader(false))
  }
};

export const skillsUpdate = (data) => async (dispatch) => {
    dispatch(loader(true))
  try {
    // Dispatch a loading action if needed
    // dispatch({ type: ActionTypes.SKILLS_LOADING });
    const dates = {
      skills:data
    }


    const response = await apiURL.post("/skills", dates);
    const responseData = response.data;

    if (responseData.status) {
      console.log(responseData, "skillsUpdate");
      // // Dispatch an action to update the skills in the state
      // dispatch({ type: ActionTypes.SKILLS_UPDATE_SUCCESS, payload: responseData.data });
    } else {
      // Handle other conditions based on the response
      // For example, dispatch an action for a specific error status
      // dispatch({ type: ActionTypes.SKILLS_UPDATE_ERROR, error: responseData.error });
    }
      dispatch(loader(false))
  } catch (error) {
    // Handle network errors or unexpected issues
    console.error("Error in skills:", error);
    console.error("Error:", error);
    console.error("Response data:", error.response.data);
    console.error("Response status:", error.response.status);
      dispatch(loader(false))

    // Dispatch an action for a generic error
    // dispatch({
    //   type: ActionTypes.SKILLS_UPDATE_ERROR,
    //   error: "An error occurred",
    // });
  }
};
