import React, { useEffect , useState} from 'react'
import "./Alert.scss"
import { SeeAll, SubTitele, TitltLayout } from '../subTitele/SubTitele'
import { AlertCards } from '../cards/Cards'
import { useDispatch, useSelector } from "react-redux";
import { courseList } from "../../redux/actions/courseAction";
import { projectsList } from "../../redux/actions/projectAction";
import { AlertIcon, ArrowRight, GoalIcon ,InterviewIconAl,InterviewsIcon,RankingIcon,RewardIcon,RewardsIcon,TimesheetIcon} from '../../utils/images'
import Button from "../../components/button/Button";
import { useNavigate  } from 'react-router-dom';
import { fileImage } from "../../utils/images";


const Alert = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const courses = useSelector((state) => state.courseList?.courses);
  const projects = useSelector((state) => state.projectsList?.projects);
  useEffect(() => {
    dispatch(courseList());
    dispatch(projectsList());
  }, []);
  console.log(projects,'projectsListprojectsListprojectsList');
  return (
    <div className={`alert `}>
    {
      (courses?.[1]?.percentage <= 0 || courses.length < 2) && (
        <>
          <SubTitele>Welcome to Learning</SubTitele>
          <div className='startLearning d-flex'>
            <Button type={"button"} functions={() => navigate('/my-learnings')}>
              Start The Learning
            </Button>
          </div>
        </>
      )
    }
      {/* <SubTitele>Alert</SubTitele> */}
      {/* <div className="card"> <AlertCards type={"GoalIcon"} img={GoalIcon} title={"Goal Incompletion"} stage={"One step to complete your goal"} color={"red"} button={"Click here"} /></div> */}
      <SubTitele>Reminder</SubTitele>
      <div className="cards-section">
        {/* <AlertCards type={"TimesheetIcon"} img={TimesheetIcon} title={"Timesheet Update"} stage={"Last Update on 23/03/23"} color={"red"} arrow={ArrowRight} />
        <AlertCards type={"InterviewsIcon"} img={InterviewIconAl} title={"Interview"} stage={"Last Update on 23/03/23"} color={"red"} arrow={ArrowRight} /> */}
        <p className='alert_p'>Kindly update your timesheet once you start learning.</p>
      </div>
     
      <TitltLayout>Recent Projects</TitltLayout>
      {projects && projects.length > 0 
      ?
      projects.slice(0, 5).map((project) => (
        <AlertCards  
          key={project.id} 
          type={"recentProject"}
          img={fileImage}
          title={project.project_info.name} 
          stage={project.hoursAgo}
          arrow={ArrowRight}
          link={project.project_url}
          recentProject={true} 
        />
      ))
      :
      <div className="cards-section">
        <p className='alert_p'>Build your first project to achieve your goal.</p>
      </div>
      }
      
      {/* <AlertCards type={"recentProject"} img={""} title={"Netflix Landing page"} stage={"2 hrs Ago"} arrow={ArrowRight}  recentProject={true} /> */}
     
      <TitltLayout>Recent rewards</TitltLayout>
      <div className="cards-section">
        {/* <AlertCards type={"RewardsIcon"} img={RewardIcon} title={"Reward for Attendance"}  color={"red"}  />
        <AlertCards type={"RankingIcon"} img={RankingIcon} title={"Rank 1 on Hackathon"}  color={"red"} /> */}
        <p className='alert_p'>Get a reward on your performance.</p>
      </div>



    </div>
  )
}

export default Alert
