import apiURL from "../../apis/apiURL";
import { ActionTypes } from '../constants/action-types';
import { loader } from "./loginAuthActions";

export const profile = () => async (dispatch) => {
  dispatch(loader(true))
    try {
      const response = await apiURL.get("/profile");
      const responseData = response.data;
  
      console.log(responseData, "profile");
  
      if (responseData.status) {
        dispatch({ type: ActionTypes.PROFILE_DATA, payload: responseData.data });
      } else {
        // Handle unsuccessful profile fetch
      }
      dispatch(loader(false))
    } catch (error) {
      console.error('Error in getProfile:', error);
      dispatch(loader(false))
    }
  };

  export const profileUpdate = (data) => async (dispatch) => {
    console.log(data,"dispatch");
    dispatch(loader(true))
    try {
      const response = await apiURL.post("/profile" ,data,
       {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
      );
      const responseData = response.data;
  
      console.log(responseData, "profileUpdate");
  
      if (responseData.status) {
       dispatch(profile())
        console.log("action" );
      } else {
        // Handle unsuccessful profile fetch
      }
      dispatch(loader(false))
    } catch (error) {
      console.error('Error in profileUpdate:', error);
      dispatch(loader(false))
    }
  }; 
  