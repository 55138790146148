import React from 'react'
import {Layout } from '../../components/Layout/Layout'
import { Link, useLocation } from 'react-router-dom'


export default function MyProfile() {
    const pathname = useLocation().pathname
  
  return (
    <Layout header={"My Profile"}>



    </Layout>
  )
}
