import { ActionTypes } from "../constants/action-types";


const initialState={
    educations:[],
    loading:false,
    error:null
    
};


export const    educationsReducer = (state = initialState, {type, payload,error}) =>{
    switch (type) {
        case ActionTypes.EDUCATION_PENDING:
            return {
                ...state, 
                loading:true,
                error:null
            };

            case ActionTypes.EDUCATION_DATA:
                return {
                    ...state, 
                    loading:false,
                    educations:payload
                };
                
            case ActionTypes.EDUCATION_PENDING:
                return {
                    ...state, 
                    loading:false,
                    error:error,
                    educations:[]
                };
        
        default:
            return state;
    }   

}; 



