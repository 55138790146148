import React, { useId, useRef, useState } from "react";
import "./FormInput.scss";
import { CloseEye, OpenEye, PasteIcon, calendar, clock } from "../../utils/images";

export default function FormInput(props) {

  const {
    label,
    placeholder,
    errorMSG,
    value,
    required,
    type,
    textarea,
    disabledInput,
    clipboard,
    accept,
    select,
    radioLabel,
    options,
    className,
    dateIcon,
    timeIcon,
    disabled,
    max,
    onClick,
  } = props;

  // { options, register, defaultValue }
  const [checkbox, setCheckBox] = useState(value || false);
  const id = useId();
  const textRef = useRef(null);
  const [icon, setIcon] = useState(true); 
  const shouldCapitalize = !placeholder?.toLowerCase().includes("email");
  // Clipboard text
  const handleCopyClick = () => {
    if (textRef.current) {
      textRef.current.select();
      document.execCommand("copy");
    }
  };
  return (
    <>
      {
        type === "checkbox" ? (
          <div className="form-checkbox">
           <input
            id={id}
            {...(required && required)}
        type="checkbox"
        checked={checkbox === "1"}
        onChange={(e) =>
          setCheckBox(e.target.checked ? "1" : "0")
        }
      />
      <label htmlFor={id}>{label}</label>
            {/* <label
              className={`checkbox `}
              htmlFor={id}>
              <input
                type={type}
                id={id}
                value={value}
                {...(required && required)}
                className={errorMSG && "error"}
              />
              {label}
            </label> */}
            {errorMSG && <span className="errorMSG">{errorMSG}</span>}
          </div>
        ) :
      type === "radio" ? (
        <div className="form-section">
          <label className={disabledInput && "disabled"}>{radioLabel}</label>
          <div className="radio">
      {options.map((option, index) => (
        <div key={index} className="radioButton">
          <input  disabled={disabledInput}
            type="radio"
            id={option.value}
            onClick ={() => onClick && onClick(option.value)}
            value={option.value}
            defaultChecked={option.value === value}
            {...(required && required)}
          />
          <label className={disabledInput && "disabled"} htmlFor={option.value}>{option.label}</label>
        </div>
))}
    </div>
          {errorMSG && <span className="errorMSG">{errorMSG}</span>}
        </div>
      ) : (
        <div className="form-section">
          <label className={disabledInput && "disabled"} htmlFor={id}>
            {label}
          </label>

          {select ? (
            <>
              <select
                value={value}
                {...(required && required)}
                className={`${errorMSG && "error"} ${className ? className : ""}`}
                disabled={disabledInput}>
                {options &&
                  options?.map((item,index) => (
                    <option value={item.id === '' ? item.id : '' || item.id ||item.toLowerCase()} 
                    key={index}>
                      {item.name || item}
                    </option>
                  ))}
              </select>

              {errorMSG && <span className="errorMSG">{errorMSG}</span>}
            </>
          ) : textarea ? (
            <>
              <textarea
                placeholder={placeholder ? placeholder : ""}
                value={value}
                {...(required && required)}
                className={errorMSG && "error"}
                disabled={disabledInput}></textarea>
              {errorMSG && <span className="errorMSG">{errorMSG}</span>}
            </>
          ) : (
            <>
              {/* ref={clipboard?textRef:null} */}
              {clipboard ? (
                <input
                  disabled={disabledInput}
                  ref={textRef}
                  type={"text"}
                  name=""
                  id={id}
                  placeholder={placeholder ? placeholder : ""}
                  value={value}
                  accept={accept}
                  {...(required && required)}
                  className={`${errorMSG && "error"} ${className ? className : ""} clipboard`}
                />
              ) : (
                <input
                  disabled={disabledInput}
                  type={
                    type === "password" ? (icon ? "password" : "text") : type
                  }
                  name=""
                  id={id}
                  placeholder={placeholder ? placeholder : ""}
                  value={value}
                  accept={accept}
                  {...(required && required)}
                  className={`${errorMSG && "error"} ${shouldCapitalize && type != "password" ? "capitalize" : ""} ${className ? className : ""}`}
                  max={type === "date" &&  max ? max : undefined}
                />
              )}

              {errorMSG && <span className="errorMSG">{errorMSG}</span>}
              {type && type === "password" ? (
                <span onClick={() => setIcon(!icon)} className="eye-icon">
                  <img src={icon ? CloseEye : OpenEye} alt="eye icon" />
                </span>
              ) : null}
              {clipboard && (
                <span onClick={handleCopyClick} className="eye-icon">
                  <img src={PasteIcon} alt="eye icon" />
                </span>
              )}
              {dateIcon && (
                <span className="calendar-icon">
                  <img src={calendar} alt="calendar-icon" />
                </span>
              )}
              {timeIcon && (
                <span className="clock-icon">
                  <img src={clock} alt="clock icon" />
                </span>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}

