import React, { Children } from 'react'
import "./NoteMSG.scss"
import { noteIcon } from '../../utils/images'

export default function NoteMSG({children}) {
  return (
    <div className='noteMsg'>
        <img src={noteIcon} alt="" />
        <p>{children}</p>
    </div>
  )
}
