import axios from "axios";
import axiosRetry from "axios-retry";

const apiURL = axios.create({
  baseURL: 'https://admin.aspiraskillhub.aspirasys.com/api/v1/student',
});

axiosRetry(apiURL, {
  retries: 5,
  retryCondition: (error) => {
    return error.response && error.response.status === 429;
  },
  retryDelay: (retryCount, error) => {
    const retryAfter = error.response.headers['retry-after'];
    if (retryAfter) {
      
      const delay = retryAfter * 1000;
      console.log(`Retry attempt: ${retryCount}, retrying in ${retryAfter} seconds`);
      return delay;
    }
    
    const delay = Math.pow(2, retryCount) * 1000;
    console.log(`Retry attempt: ${retryCount}, retrying in ${delay / 1000} seconds`);
    return delay;
  }
});

apiURL.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiURL.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default apiURL;
