import React, { useEffect, useState } from "react";
import { Layout } from "../../components/Layout/Layout";
import "./Dashboard.scss";
import TimeBasedGreeting from "../../components/TimeBasedGreeting/TimeBasedGreeting";
import Courses from "../../components/courses/Courses";
import { AlertIcon, CoursesIcon, RewardsIcon,EducationIcon } from "../../utils/images";
import { Cards } from "../../components/cards/Cards";
import { SeeAll, SubTitele } from "../../components/subTitele/SubTitele";
import YourChartComponent from "../../components/chat";
import Alert from "../../components/alert/Alert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { courseList } from "../../redux/actions/courseAction";
import { rewardsActionList } from "../../redux/actions/rewardActions";
import { timesheetChartAction } from "../../redux/actions/timesheetChartAction";
import { timesheetPieChartAction } from "../../redux/actions/timesheetPieChartAction";
import { Link } from "react-router-dom";
import Charts from "./Charts";
import BasicBars from "./Barchart";

export default function Dashboard() {
  const styles ={
    width:"100%",
    borderRadius: "6px",
    background: "#FFF",
    boxShadow: "2px 2px 2px 0px rgba(6, 40, 61, 0.05), -2px -2px 2px 0px rgba(6, 40, 61, 0.05)",
    padding: "10px",
}

useEffect(() => {
  dispatch(courseList());
  dispatch(rewardsActionList());
  dispatch(timesheetChartAction());
  dispatch(timesheetPieChartAction());
}, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const courses = useSelector((state) => state.courseList?.courses);
  const timesheetChartData = useSelector((state) => state.timeSheetList?.timeSheetListChart);
  const timesheetPieData = useSelector((state)=> state.timeSheetList?.timeSheetListChartPie);

  const [timesheetChart,setTimesheetChart] = useState([]);
  const [timesheetPie,setTimesheetPie] = useState([]);

  useEffect(() => {
    if (timesheetChartData) {
      setTimesheetChart(timesheetChartData);
    }
    if (timesheetPieData) {
      setTimesheetPie(timesheetPieData);
    }
  }, [timesheetChartData, timesheetPieData]);

  console.log('timesheetPie-',timesheetPie)
  console.log('timesheetChart-',timesheetChart)
  
  const handleSuccessfulLogin = () => {
    navigate("/my-learnings/course-detail");
  };


  const rewardData = useSelector(({ rewardLists }) => rewardLists?.rewards);
  console.log(rewardData);

  const [alertIcon, setAlertIcon] = useState(false);

  return (
    <>
      <Layout header={"Dashboard"}>
        <div className="dashboard-inner">
          <div className="dashboard-left">
            <TimeBasedGreeting />
            <div className="course-info">
              <Link to={"/my-learnings"}>
                <Courses
                  img={CoursesIcon}
                  count={courses.filter(course => course.id !== "").length}
                  color={"LightningYellow"}
                  name={"My Courses"}
                />
              </Link>
              <Link to={"/my-learnings"}>
                <Courses
                  img={CoursesIcon}
                  count={courses.filter(courses => courses.status).length}
                  color={"LawnParty"}
                  name={"Completed Courses"}
                />
              </Link>
              <Link to={"/rewards"}>
                <Courses
                  img={RewardsIcon}
                  count={(rewardData.length == 0 || rewardData.length == 1 ) ? 0 : rewardData.length}
                  color={"Pear"}
                  name={"Certification & Rewards"}
                />
              </Link>
            </div>
            <div className="header-section">
              <SubTitele>My Courses</SubTitele>
              {/* <SeeAll /> */}
            </div>
            <div className="course-info myCoures">
              {courses.length === 1 || (courses.length > 0 && courses[0].id ==='' && courses.length === 1) ? (
                <div className='emty-Layout rewards dashboard-empty-layout'>
                <img src={EducationIcon} alt="bg-image" />
                <p className='emty-Layout-content'>Yet the admin has not scheduled the courses.</p>
                </div>
                // <p style={{ textAlign: 'center' , width:'100%' }}>"Yet the admin has not scheduled the courses"</p>
              ) : (
                courses.filter(course => course.id !== '').map((course, index) => (
                    <Cards
                      key={index} 
                      img={course.image}
                      stage={course.name}
                      title={"Beginner"}
                      percent={course.percentage}
                    />
                ))
              )}
            </div>

            <div className="course-info">
              
            {timesheetPie && timesheetPie.length > 0 && 
            (timesheetPie[0][0]['value'] !== 0 || timesheetPie[0][1]['value'] !== 0 || timesheetPie[0][2]['value'] !== 0) && (
              <>
               <div style={styles}>
                <SubTitele>{"Productivity Overview"}</SubTitele>
                <Charts 
                data = {timesheetPie}
                />
              </div>
              <div style={styles}>
                <SubTitele>{"Timesheet Overview"}</SubTitele>
                <BasicBars 
                data = {timesheetChart}/>
              </div>
              </>
            )}


              {/* <YourChartComponent
                chartId={"doughnut"}
                chartType={"doughnut"}
                header={"Productivity Overview"}
                data={timesheetChart}
              />
              <YourChartComponent
                chartId={"bar"}
                chartType={"bar"}
                header={"Timesheet Overview"}
                data={timesheetChart}
              /> */}
            </div>
          </div>
          <div className={`dashboard-right ${alertIcon ? "active" : ""}`}>
            <Alert />
          </div>
          <div className="alert-icon" onClick={() => setAlertIcon(!alertIcon)}>
            <img src={AlertIcon} alt="" />
          </div>
        </div>
      </Layout>
    </>
  );
}
