import React from 'react'
import "./StageLearn.scss"

export default function StageLearn({list, activeStage}) {
  // console.log('stage', list)
  // console.log('stage', list,activeStage)
  return (
    <div className='Profile-menu'>{
      list.map((value , index )=>{
        return (
          <div className="stage" key={index} onClick={() => activeStage(index)}>
            <p className="stage_text cursor">{value.name}</p>
            <p className="stage_percentage">{value.percentage}%</p>
          </div>
        );
      })
    }
    </div>
  )
}
