import { ActionTypes } from "../constants/action-types";

const initialState = {
  certificates: [],
  loading: false,
  error: null,
};

export const certificatesReducer = (
  state = initialState,
  { type, payload, error }
) => {
  switch (type) {
    case ActionTypes.CERTIFICATES_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ActionTypes.CERTIFICATES_DATA:
      return {
        ...state,
        loading: false,
        certificates: payload,
      };

    case ActionTypes.CERTIFICATES_ERROR:
      return {
        ...state,
        loading: false,
        error: error,
        certificates: [],
      };

    default:
      return state;
  }
};
