import { ActionTypes } from "../constants/action-types";

const initialState = {
  courses: [],
  projectChallenges: [],
  loading: false,
  error: null,
};

export const courseReducer = (
  state = initialState,
  // { type, payload, error }
  { type, payload, error, projectChallenges }
) => {
  switch (type) {
    case ActionTypes.COURSE_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ActionTypes.COURSE_DATA:
      return {
        ...state,
        loading: false,
        courses: payload,
      };

    case ActionTypes.COURSE_ERROR:
      return {
        ...state,
        loading: false,
        error: error,
        projects: [],
      };
    case ActionTypes.PROJECT_CHALLENGES:
      return{
        ...state,
        loading: true,
        projectChallenges: projectChallenges
      }  

    default:
      return state;
  }
};
