import apiURL from "../../apis/apiURL";
// import { notify } from "../../components/Layout/Layout";
import { ActionTypes } from "../constants/action-types";
import { loader } from "./loginAuthActions";

export const ProductiveRateList = () => async (dispatch) => {
    dispatch(loader(true))
  try {
    const response = await apiURL.get("/productive-rate");
    const responseData = response.data;
    if (responseData.status) {
      dispatch({
        type: ActionTypes.PRODUCTIVE_RATE_DATA,
        payload: responseData.data,
      });
      //   notify("successful", "Education has been deleted");
    } else {
      // Handle unsuccessful profile fetch
    }
      dispatch(loader(false))
  } catch (error) {
    console.error("Error in productive rate details:", error);
      dispatch(loader(false))
  }
};
