import React, { useEffect } from "react";
import { Layout } from "../../components/Layout/Layout";
import "./Rewards.scss";
import { SubTitele } from "../../components/subTitele/SubTitele";
import SimpleSlider from "../../components/slider/SliderComponent";
import SCard from "../../components/smallCard/SCard";
import { useDispatch, useSelector } from "react-redux";
import { rewardsActionList } from "../../redux/actions/rewardActions";
import { saveAs } from "file-saver";
import { LinkedinShareButton } from "react-share";
import {EducationIcon,} from "../../utils/images";
export default function Rewards() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(rewardsActionList());
  }, []);

  const certificateData = useSelector(
    ({ rewardLists }) => rewardLists?.certificates
  );
  const rewardData = useSelector(({ rewardLists }) => rewardLists?.rewards);
  console.log(rewardData);

  const handleShare = (item) => {
    return (
      <LinkedinShareButton url={item.certificateImage}></LinkedinShareButton>
    );
  };

  const handleDownload = (item) => {
    const url = `${item.certificateLink}`;
    console.log("items of reward", item.certificateLink);
    saveAs(url, item.certificateName);
  };

  return (
    <>
      <Layout header={"Rewards"}>
      <div className="reward_column">
    <SubTitele>Certificates</SubTitele>
    {
        certificateData && certificateData.length > 1 && certificateData[0] == "No Certificates has been Issued" ? (
            <SimpleSlider className="inner_reward">
                {certificateData.map((item) => (
                    <SCard
                        key={item.certificateId} // Ensure to use a unique key when rendering in a loop
                        name={item.certificateName}
                        image={item.certificateImage}
                        issuedDate={item.issue_date}
                        className="cardOuter"
                        imageCont="imgCont"
                        handleLeftBtn={() => handleShare(item)}
                        handleRightBtn={() => handleDownload(item)}
                    />
                ))}
            </SimpleSlider>
        ) : (
            <div className='emty-Layout rewards'>
            <img src={EducationIcon} alt="bg-image" />
            <p className='emty-Layout-content'>There is no Certificates has been issued</p>
            </div>
        )
    }
</div>
        <div>
          <SubTitele>Rewards</SubTitele>
          {
          rewardData && rewardData.length > 1 && rewardData[0] == "There is no rewards has been found" ?(
          <SimpleSlider >
            {rewardData.map((item) => (
              <div className="rewardsCont" key={item.rewardId}>
                <div className="leftCont">
                  <img src={item.rewardImage} alt={item.rewardName} />
                </div>
                <div className="rightCont">
                  <h6>{item.rewardName}</h6>
                  <p>{item.issue_date}</p>
                </div>
              </div>
            ))}
          </SimpleSlider>
          ):(
            <div className='emty-Layout rewards'>
            <img src={EducationIcon} alt="bg-image" />
            <p className='emty-Layout-content'>There is no Rewards has been found</p>
            </div>
          )
}
        </div>
      </Layout>
    </>
  );
}
