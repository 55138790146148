import apiURL from "../../apis/apiURL";
import { notify } from "../../components/Layout/Layout";
import { ActionTypes } from '../constants/action-types';
import { loader } from "./loginAuthActions";

export const languagesList = () => async (dispatch) => {
  dispatch(loader(true))
    try {
      const response = await apiURL.get("/languages");
      const responseData = response.data;
      if (responseData.status) {
        dispatch({ type: ActionTypes.LANGUAGES_DATA, payload: responseData.data });
      } else {
        // Handle unsuccessful profile fetch
      }
      dispatch(loader(false))

    } catch (error) {
      console.error('Error in certificates:', error);
      dispatch(loader(false))

    }
  };


  export const languagesListEdit = (data) => async (dispatch) => {
    const id = data.id;
  
  
      dispatch(loader(true))
    try {
      const response = await apiURL.post(`/languages/${id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
       const responseData = response.data;
      if (responseData.status) {
        console.log(responseData.status);
        dispatch(languagesList());
        notify("success", "languages has been Updated Successfully");
      } else {
        notify("error", "Unsuccessful");
        dispatch({ type: ActionTypes.LANGUAGES_ERROR });
      }
        dispatch(loader(false))
    } catch (error) {
      console.error("Error in languages:", error);
        dispatch(loader(false))
      notify("error", "Unsuccessful");
    }
  };
  
  export const languagesListDelete = (data) => async (dispatch) => {
      dispatch(loader(true))
  
    try {
      const response = await apiURL.delete(`/languages/${data}`);
      const responseData = response.data;
      if (responseData.status) {
        dispatch(languagesList());
        notify("error", "languages has been deleted");
      } else {
        notify("error", "Unsuccessful");
      }
        dispatch(loader(false))
    } catch (error) {
      notify("error", "Unsuccessful");
        dispatch(loader(false))
    }
  };
  
  export const languagesListCreate = (data) => async (dispatch) => {
      dispatch(loader(true))
  
    console.log(data, "data");
  
    try {
      const response = await apiURL.post(`/languages`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
       const responseData = response.data;
      if (responseData.status) {
        console.log(responseData.status);
        dispatch(languagesList());
        notify("success", "languages has been Updated Successfully");
      } else {
        notify("error", "Unsuccessful");
        console.log(responseData , "Unsuccessful");
      }
        dispatch(loader(false))
    } catch (error) {
        dispatch(loader(false))
      notify("error", "Unsuccessful");
      console.log(error, "error");
    }
  };