import React, { useEffect } from "react";
import FormInput from "../../components/input-form/FormInput";
import Button from "../../components/button/Button";
import AuthenticationLayout from "./AuthenticationLayout/AuthenticationLayout";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { forgetPassword } from "../../redux/actions/loginAuthActions";
import { useDispatch } from "react-redux";

export default function ResetPasswordpage() {
  const dispatch = useDispatch();
  const { token } = useParams();

  useEffect(() => {
    setValue("token", token);
  }, []);

  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
      password_confirmation: "",
    },
  });
  const { register, control, handleSubmit, formState, watch, setValue } = form;
  const { errors } = formState;
  const passwordValue = watch("password", "");
  const navigate = useNavigate();
  const resetForm = () => {
    form.reset();
    setTimeout(() => {
      navigate("/login");
    }, 3000);
  };

  const onSubmit = (data) => {
    console.log("form in submite", data, errors.username?.message);

    dispatch(forgetPassword("/resetpassword", data, resetForm));

    // navigate('/login')
  };
  return (
    <AuthenticationLayout>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <h2>Reset Password</h2>
        <FormInput
          label={"Email"}
          placeholder={"Email"}
          errorMSG={errors.email?.message}
          required={{
            ...register("email", {
              required: "Email is required",
              validate: {
                matchPattern: (v) =>
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                  "Email address must be a valid address",
              },
            }),
          }}
        />
        <FormInput
          type="password"
          label={"Password"}
          placeholder={"Password"}
          errorMSG={errors.password?.message}
          required={{
            ...register("password", {
              required: "Password is required",
              minLength: {
                value: 4,
                message: "The password should have at least 4 characters",
              },
              maxLength: {
                value: 12,
                message: "The password should have at most 12 characters",
              },
            }),
          }}
          passwordMatch={true}
        />
        <FormInput
          type="password"
          label={"Confirmation password"}
          placeholder={"Password"}
          errorMSG={errors.password_confirmation?.message}
          required={{
            ...register("password_confirmation", {
              required: "Confirmation password is required",
              validate: (value) =>
                value === passwordValue || "Passwords do not match",
            }),
          }}
          passwordMatch={true}
        />
        <Button type={"submit"} fill={true}>
          {" "}
          Submit
        </Button>
      </form>
    </AuthenticationLayout>
  );
}
