import React, { useEffect, useState } from "react";
import SideBar from "../../components/side-bar/SideBar";
import { Layout } from "../../components/Layout/Layout";
import "./MyLearnings.scss";
import SimpleSlider from "../../components/slider/SliderComponent";
import { SubTitele } from "../../components/subTitele/SubTitele";
import Progress from "../../components/progressBar/Progress";
import Button from "../../components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { courseList, courseStages } from "../../redux/actions/courseAction";
import { ActionTypes } from "../../redux/constants/action-types";
import { useNavigate, Link } from "react-router-dom";
import { EducationIcon } from "../../utils/images";

export default function MyLearnings() {
  const card = [
    {
      title: "Web Development",
      value: "20%",
      course: false,
      img: "",
    },
    {
      title: "PHP Developer",
      value: "50%",
      course: true,
      img: "",
    },
    {
      title: "Java Developer",
      value: "50%",
      course: false,
      img: "",
    },
    {
      title: "Web Development",
      value: "0%",
      course: false,
      img: "",
    },
    {
      title: "Android Developer",
      value: "80%",
      course: false,
      img: "",
    },
    {
      title: "UI Developer",
      value: "40%",
      course: true,
      img: "",
    },
    {
      title: "Dotnet Developer",
      value: "50%",
      course: true,
      img: "",
    },
    {
      title: "Web Development",
      value: "20%",
      course: false,
      img: "",
    },
    {
      title: "Web Development",
      value: "50%",
      course: false,
      img: "",
    },
  ];
  const cards = [
    {
      title: "Web Development",
      value: "20",
      course: false,
      img: "",
    },
    {
      title: "PHP Developer",
      value: "50",
      course: true,
      img: "",
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [courseStatus, setCourseStatus] = useState([]);
  const courseLists = useSelector((state) => state.courseList);

  useEffect(() => {
    dispatch(courseList());
  }, []);

  useEffect(() => {
    setCourseStatus(courseLists.courses?.map((course) => course.status));
  }, [courseLists.courses]);

  const handleSuccessfulLogin = () => {
    navigate("/my-learnings/course-detail");
  };
  // console.log("on course", courseLists.courses);

  const handleCourse = (courseId) => {
    // alert(courseId)
    // dispatch(courseStages(courseId));
    // dispatch({
    //   type: ActionTypes.COURSE_STAGES_ID,
    //   courseId: courseId,
    // });
    dispatch(courseStages(courseId, handleSuccessfulLogin));
    // navigate("/my-learnings/course-detail");
  };

  // const handleDisable = (percent) => {
  //   //  return percent < 90
  // };

  return (
    <>
      <Layout header={"My Learnings"}>
        <div className="my_learnings">
          <SubTitele>My Courses</SubTitele>
          {courseLists.courses.length === 1 || (courseLists.courses[0] && courseLists.courses[0].id === '' && courseLists.courses.length === 1) ? (
             <div className='emty-Layout rewards dashboard-empty-layout'>
             <img src={EducationIcon} alt="bg-image" />
             <p className='emty-Layout-content'>There is no courses has assigned by admin yet. Kindly contact the admin.</p>
             </div>
              ) : (
                <SimpleSlider>
            {courseLists.courses?.filter(item => item.id !== '').map((item, index) => {
              return (
                <>
                  <div key={index} className="my_learnings_card">
                    <div className="my_learnings_card_img">
                      <img src={item.image} alt="" />
                    </div>
                    <SubTitele>{item.name}</SubTitele>

                    <Progress
                      name={
                        item.percentage === 100
                          ? "Completed"
                          : "Course Progress"
                      }
                      value={item.percentage}
                    />
                    <div className="">
                      {" "}
                      <Button
                        fill
                        disabledButton={
                          index !== 0 && courseStatus[index - 1] !== 1
                        }
                        // disabledButton={() => handleDisable(item.percentage)}
                        functions={() => handleCourse(item.id)}>
                        {" "}
                        {item.percentage === 0
                          ? "Start Course"
                          : item.percentage === 100
                          ? "View Course"
                          : "Resume Course"}
                      </Button>
                    </div>
                  </div>
                </>
              );
            })}
          </SimpleSlider>
              )}
          

          {/* <SubTitele>Additional Learnings</SubTitele>
          <SimpleSlider>
            {cards?.map((item, index) => {
              return (
                <>
                  <div key={index} className="my_learnings_card">
                    <div className="my_learnings_card_img">
                      <img src={item.image} alt="" />
                    </div>
                    <SubTitele>{item.title}</SubTitele>

                    <Progress name={"value"} value={item.value} />
                    <div className="">
                      {" "}
                      <Button fill>Resume Course</Button>
                    </div>
                  </div>
                </>
              );
            })}
          </SimpleSlider> */}
        </div>
      </Layout>
    </>
  );
}

// aspirasysdb
