import logo from "./logo.svg";
import "./App.scss";
import Navigate from "./Navigate/Navigate";
import { Loader, ToastProvider } from "./components/Layout/Layout";

function App() {
  // useEffect(() => {
  //   const handleTabClose = event => {
  //     event.preventDefault();

  //     console.log('beforeunload event triggered');
  //     localStorage.removeItem('token');

  //     return (event.returnValue =
  //       'Are you sure you want to exit?');
  //   };

  //   window.addEventListener('beforeunload', handleTabClose);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleTabClose);
  //   };
  // }, []);
  // window.addEventListener('beforeunload', alert("close"));

  return (
    <div className="App">
      <ToastProvider>
        <Loader />

        <Navigate />
      </ToastProvider>
    </div>
  );
}

export default App;
