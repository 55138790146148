import React, { useEffect } from "react";
import { Layout } from "../../components/Layout/Layout";
import { useState } from "react";
import FormInput from "../../components/input-form/FormInput";
import { useForm } from "react-hook-form";
import "./MyProfile.scss";
import { EditIcon } from "../../utils/images";
import Button from "../../components/button/Button";
import FileUpload from "../../components/FileUpload";
import FileUploads from "../../components/input-form/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { profileUpdate } from "../../redux/actions/profileAction";

export default function Document() {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profile.profileData);
  console.log(profileData);
  const [edit, setEdit] = useState(true);

  const form = useForm({ mode: "all" });
  useEffect(() => {
    form.reset({
      address: profileData?.address,
      experience: profileData?.experience,
      agreement_signed: profileData?.agreement_signed,
    });
  }, [profileData, form]);
  const { register, control, handleSubmit, formState, setValue, watch } = form;
  const { errors } = formState;

  const onSubmit = (data) => {
    const reSetData = {
      address: data?.address,
      experience: data?.experience,
      agreement_signed: data?.agreement_signed,
      first_name: profileData?.first_name,
      designation: profileData?.designation,
      description: profileData?.description,
      last_name: profileData?.last_name,
      ...(data.aadharcard_doc !== null && {
        aadharcard_doc: data.aadharcard_doc,
      }),
      ...(data.pancard_doc !== null && { pancard_doc: data.pancard_doc }),
      ...(data.sslc_doc !== null && { sslc_doc: data.sslc_doc }),
      ...(data.hsc_doc !== null && { hsc_doc: data.hsc_doc }),
      ...(data.graduation_doc !== null && {
        graduation_doc: data.graduation_doc,
      }),
      ...(data.consolidated_doc !== null && {
        consolidated_doc: data.consolidated_doc,
      }),
    };
    console.log(reSetData, "onSubmit onSubmit");
    setEdit(true);

    dispatch(profileUpdate(reSetData));
  };

  const onEditClick = () => {
    setEdit(false);
  };
  const radioOptions = [
    { value: "0", label: "Yes" },
    { value: "1", label: "No" },
  ];
  //   const defaultRadioValue = 'option2'; // Set the default value here
  const durationOptions = [
    { id: 1, name: "1 Months" },
    { id: 2, name: "2 Months" },
    { id: 3, name: "3 Months" },
    { id: 4, name: "4 Months" },
    { id: 5, name: "5 Months" },
    { id: 6, name: "6 Months" },
    { id: 7, name: "7 Months" },
    { id: 8, name: "8 Months" },
    { id: 9, name: "9 Months" },
    { id: 10, name: "10 Months" },
    { id: 11, name: "11 Months" },
    { id: 12, name: "12 Months" },
  ];

  return (
    <>
      <Layout header={"My Profile"}>
        <div className="profile-section">
          <form className="formsection" onSubmit={handleSubmit(onSubmit)}>
            <div className="formsection-inner">
              <FileUploads
              key="aadharcard_doc"
                disabledInput={edit}
                label={"Aadhar card"}
                name="aadharcard_doc"
                control={control}
                setValue={setValue}
                // activeClass={profileData.aadharcard_doc !== "" ?"activeClass":"inuput"}
              />
              <FileUploads
               key="pancard_doc"
                disabledInput={edit}
                label={"PAN card"}
                name="pancard_doc"
                control={control}
                setValue={setValue}
                // activeClass={profileData.aadharcard_doc !== "" ?"activeClass":"inuput"}

              />
              <FileUploads
               key="sslc_doc"
                disabledInput={edit}
                label={"SSLC"}
                name="sslc_doc"
                control={control}
                setValue={setValue}
                activeClass={profileData.aadharcard_doc !== "" ?"activeClass":"inuput"}

              />
              <FileUploads
               key="hsc_doc"
                disabledInput={edit}
                label={"HSC"}
                name="hsc_doc"
                control={control}
                setValue={setValue}
                activeClass={profileData.aadharcard_doc !== "" ?"activeClass":"inuput"}

              />
              <FileUploads
               key="graduation_doc"
                disabledInput={edit}
                label={"Graduation Certificate"}
                name="graduation_doc"
                control={control}
                setValue={setValue}
                activeClass={profileData.aadharcard_doc !== "" ?"activeClass":"inuput"}

              />
              <FileUploads
               key="consolidated_doc"
                disabledInput={edit}
                label={"Consolidated Marksheet"}
                name="consolidated_doc"
                control={control}
                setValue={setValue}
                activeClass={profileData.aadharcard_doc !== "" ?"activeClass":"inuput"}

              />
            </div>
            <FormInput
              disabledInput={edit}
              label={"Address"}
              placeholder={"Address"}
              errorMSG={errors.address?.message}
              textarea={true}
              required={{
                ...register("address", {
                  required: "Address is required",
                }),
              }}
            />
            <FormInput
              label={"Experience (in months)"}
              select={true}
              disabledInput={edit}
              options={durationOptions}
              type={"select"}
              errorMSG={errors.experience?.message}
              required={{
                ...register("experience", {
                  required: "Date of Relieving is required",
                }),
              }}
            />
            <FormInput
              radioLabel={"Agreement Signed"}
              type={"radio"}
              disabledInput={edit}
              options={radioOptions}
              required={{
                ...register("agreement_signed"),
              }}
            />
            <div className="button-section">
              {edit ? (
                <Button functions={onEditClick}>
                  <img src={EditIcon} alt="EditIcon" /> Edit
                </Button>
              ) : (
                <>
                  <Button functions={() => setEdit(true)}> Cancel</Button>
                  <Button type={"submit"}> save</Button>
                </>
              )}
            </div>
          </form>
        </div>
      </Layout>
    </>
  );
}
