import React, { useEffect, useState } from "react";
import { Layout } from "../../components/Layout/Layout";
import ProfileLayout from "../../components/Profilelayout/ProfileLayout";
import Button from "../../components/button/Button";
import { SubTitele } from "../../components/subTitele/SubTitele";
import { EducationIcon } from "../../utils/images";
import { skillsList, skillsUpdate } from "../../redux/actions/skillsAction";
import { useDispatch, useSelector } from "react-redux";

const Skills = () => {
  const dispatch = useDispatch();
  const skillsLis = useSelector((state) => state.skillsList);
  const [skill, setSkills] = useState([]);
  const [create, setCreate] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
  };

  useEffect(() => {
    dispatch(skillsList());
  }, []);

  useEffect(() => {
    console.log("skillsLis :", skillsLis);
    setSkills(skillsLis.skills || []);
  }, [skillsLis]);

  const addSkill = () => {
    if (inputValue.trim() !== "") {
      const updatedSkills = [...skill, inputValue];
      setSkills(updatedSkills);
      setInputValue("");
      dispatch(skillsUpdate(updatedSkills));
    }
  };

  const RemoveValue = (index) => {
    const updatedSkills = skill.filter((_, i) => i !== index);
    setSkills(updatedSkills);
    dispatch(skillsUpdate(updatedSkills));
  };

  function createSkill() {
    setCreate(true);
  }

  return (
    <>
      <Layout header={"My Profile"}>
        <div className="profile-section">
          {skill?.length > 0 || create ? (
            <>
              <div className="tabel-header">
                <SubTitele>{"Skills"}</SubTitele>
                <div className="form-section">
                  <input
                    type="text"
                    value={inputValue}
                    onChange={handleChange}
                    placeholder="Enter your skills"
                  />
                </div>
                <Button functions={addSkill}>+ Add Skills</Button>
              </div>
              <SubTitele>Your Skills {skill?.length}</SubTitele>
              <div className="skils-section">
                {skill?.map((item, index) => (
                  <p key={index}>
                    {item}{" "}
                    <span className="skillcursor" onClick={() => RemoveValue(index)}>&times;</span>
                  </p>
                ))}
              </div>
            </>
          ) : (
            <ProfileLayout
              content={"No Educational records found"}
              img={EducationIcon}
              buttonContent={"+ Add Skills"}
              openPopup={createSkill}
            />
          )}
        </div>
      </Layout>
    </>
  );
};

export default Skills;
