import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const chartSetting = {
  width: 600,
  height: 280,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-20px, 0)',
    },
    [`.${axisClasses.bottom} .${axisClasses.line}`]: {
      strokeDasharray: '3 3',
    },
  },
};

const valueFormatter = (value) => `${value}hours`;

export default function BarsDataset(data) {
  const theme = useTheme();

  return (
    <Stack
      className="bar-chart"
      direction={{ xs: 'column', md: 'row' }}
      spacing={{ xs: 0, md: 4 }}
      sx={{ width: '100%' }}
    >
      <Box sx={{ flexGrow: 1 }}>
      <BarChart className ={'timesheetChart'}
  dataset={data.data}
  xAxis={[{ scaleType: 'band', dataKey: 'name' }]}
  series={[
    { dataKey: 'productive', label: 'Productive', valueFormatter },
    { dataKey: 'technical', label: 'Technical', valueFormatter },
    { dataKey: 'leave', label: 'Leave', valueFormatter },
  ]}
  colors={["#3282C4", "#6AAA43", "#FF6636"]}
  {...chartSetting}
  sx={{
    '.MuiXAxis-root .MuiAxis-line': {
      display: 'block',
    },
    '.MuiYAxis-root .MuiAxis-line': {
      strokeDasharray: '3 3',
    },
  }}
/>

      </Box>

      <Stack
        className="main-chart-desc"
        direction="column"
        sx={{ width: { xs: '100%', md: '40%' } }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            paddingLeft: 2,
            marginTop: 2
          }}
        >
          <div className="chart-desc">
            <li className="rate_li">Productivity rate</li>
            <li className="issue_li">Technical issue</li>
            <li className="leave_li">Leave</li>
          </div>
        </Box>
      </Stack>
    </Stack>
  );
}
