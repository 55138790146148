import { useNavigate } from "react-router-dom";
import apiURL from "../../apis/apiURL";
import { ActionTypes } from "../constants/action-types";
import { loader } from "./loginAuthActions";
import {useSelector} from 'react-redux'

// import { notify } from "../../components/Layout/Layout";

export const courseList = () => async (dispatch) => {
    dispatch(loader(true))
  try {
    const response = await apiURL.get("/mycourses");
    // console.log('data in course', response)
    const responseData = response.data;
    if (responseData.status) {
      dispatch({
        type: ActionTypes.COURSE_DATA,
        payload: responseData.data.technology,
      });
    } else {
      // Handle unsuccessful profile fetch
    }
      dispatch(loader(false))
  } catch (error) {
    // console.error("Error in projectdetails:", error);
      dispatch(loader(false))
  }
};

export const courseStages = (id, handleSuccessfulLogin) => async (dispatch) => {
  // const ups = useSelector((state) => (state))
    dispatch(loader(true))
  try {
    const response = await apiURL.get(`/mycourses/${id}`);
    const responseData = response.data;
    if (responseData.status) {
          console.log("in actions", responseData.data, id);

      dispatch({
        type: ActionTypes.COURSE_STAGES_ID,
        courseId: id,
      });
       dispatch({
         type: ActionTypes.COURSE_STAGES_DATA,
         payload: responseData.data,
       });

      handleSuccessfulLogin()
      // navigate("/my-learnings/course-detail");
    } else {
      // Handle unsuccessful profile fetch
    }
      dispatch(loader(false))
  } catch (error) {
    // console.error("Error in projectdetails:", error);
      dispatch(loader(false))
  }
};

export const stageStatusUpdate = (data, courseDatas) => async (dispatch, getState) => {
  const { id } = data;
  const editData = {
    status: "1"

  };

  const state = getState();
  const courseId = state.courseId;
    dispatch(loader(true))
  try {
    const response = await apiURL.post(`/material/status/${id}`, editData);
    const responseData = response.data;
    if (responseData.status) {
           const state = getState();
           const currentCourseStage = state.courseStages.courseStage;
           const updatedStage = {
            ...currentCourseStage,
            stages: currentCourseStage.stages.map((tech) => {
              tech.learnings = tech.learnings.map((items) => {
                if(items.id === id){
                  return { ...items, ...responseData.data}
                }
                return items
              })
              return tech;
            })
           }


          //  // Update the courseStage data
          //  const updatedCourseStage = {
          //    ...currentCourseStage,
          //    stages: currentCourseStage.stages.map((tech) => {
          //      tech.learnings = tech.learnings.map((items) => {
          //        if (items.id === id) {
          //          return { ...items, ...responseData.data };
          //        }
          //        return items;
          //      });
          //      return tech;
          //    }),
          //  };
            // updatedCourseDatas.courseStage.stages.forEach((tech) => {
            //   tech.learnings = tech.learnings.map((items) => {
            //     if(items.id === id){
            //       return {...items, ...responseData.data}
            //     }
            //     return items;
            //   })
            // })
            // const technologyIdValue = updatedCourseDatas.courseStage["technology-id"];
           console.log(
             "hhhh",
             //  updatedCourseDatas.courseStage,
             currentCourseStage,
             updatedStage
             //  updatedCourseDatas,
             //  technologyIdValue
           );
            dispatch({
              type: ActionTypes.COURSE_STAGES_DATA,
              payload: updatedStage,
            });
          
      //   dispatch(courseStages());
      //   notify("success", "Successfully Completed");
    } else {
      //   notify("error", "Unsuccessful");
      //   dispatch({ type: ActionTypes.EDUCATION_ERROR });
    }
      dispatch(loader(false))
  } catch (error) {
    console.error("Error in status update:", error);
    //   dispatch(loader(false))
    // notify("error", "Unsuccessful");
  }
};

